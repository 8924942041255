import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const UserSectionBase: React.FC<Props> = ({ children }) => (
  <div className="flex flex-shrink-0 items-center space-x-4">{children}</div>
);

export default UserSectionBase;
