import { KnownCookie } from 'common-types';
import Cookies from 'js-cookie';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { GAPageType } from '~/services/analytics/AnalyticsInterfaces';
import fireAnalytics from '~/services/analytics/fireAnalytics';
import fireGAEvent from '~/services/analytics/fireGAEvent';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';
import { isFirstVisit } from '~/services/analytics/identify';
import { BaseFacet, RailsFacetGroup } from '~/typings/services/rails/facets';
import { RailsThumbnailItem } from '~/typings/services/rails/item';
import { RailsPageView } from '~/typings/services/rails/tile';

export function fireVisitedResults({
  facetFiltering: {
    category,
    filterCount,
    filterState,
    lander,
    facets,
    paging,
    similarToItem,
  },
  items,
  pageView,
}: {
  facetFiltering: ReturnType<typeof useFacetFiltering>;
  items: RailsThumbnailItem[];
  pageView?: RailsPageView;
}) {
  let params: any = {};

  params.fast_shipping = Cookies.get(
    'fast_shipping_filter_enabled' as KnownCookie,
  )
    ? '1'
    : '0';

  params.filters = filterCount;

  if (category) {
    const [primarySlug, secondarySlug, thirdSlug] = category.slug.split('/');

    params.category = category.slug;

    if (primarySlug) {
      params.category_1 = primarySlug;
    }

    if (secondarySlug) {
      params.category_2 = secondarySlug;
    }

    if (thirdSlug) {
      params.category_3 = thirdSlug;
    }
  }

  if (paging) {
    params.results = paging.total_count;
    params.page = paging.page;
  }

  params.url = window.location.pathname + window.location.search;

  // Add condition and brand
  if (filterState) {
    if (filterState.brand) {
      // If there's only 1, send just the brand.
      // If there's more than 1, send the count (as a string)
      if (filterState.brand.length === 1) {
        params.brand = filterState.brand[0];
      } else {
        params.brand = String(filterState.brand.length);
      }
    }

    if (filterState.condition) {
      params.condition = filterState.condition.sort().join(',');
    }

    if (filterState.q) {
      params.q = filterState.q;
    }

    if (filterState.sort) {
      params.sort = filterState.sort;
    }

    if (filterState.recently_sold) {
      params.state_sold = 1;
    }

    if (filterState.price_drop) {
      params.price_drop = 1;
    }

    // 1 or 0 for saved search
    params.saved_search = +!!filterState.saved_search_id;
  }

  if (lander) {
    params.lander_slug = lander.slug;
  }

  if (lander) {
    params.depth = lander.path?.split('/').length ?? 0;
  }

  if (lander) {
    if (lander.model) {
      params = {
        ...params,
        mode: 'model',
        model_name: lander.model?.name,
      };
    } else if (similarToItem) {
      params = {
        ...params,
        price: similarToItem.list_price,
        item_id: similarToItem.id,
        name: similarToItem.name,
        mode: 'pla',
      };
    } else {
      params.mode = 'shop';
    }
  } else {
    params.mode = 'search';
  }

  fireAnalytics('Visited Results', null, params);

  fireGAEvent('send', 'event', params.category, 'Visited Results', {
    nonInteraction: true,
  });

  const landerQueryNoSort = { ...lander?.query };
  delete landerQueryNoSort.sort;
  delete landerQueryNoSort.state;

  let gaPageType: GAPageType | undefined = undefined;
  if (lander) {
    if (lander.model) {
      gaPageType = 'Model Lander';

      if (Object.keys(landerQueryNoSort).length > 3) {
        gaPageType = 'Model + Detail Lander';
      }
    } else if (landerQueryNoSort.category) {
      gaPageType = 'Category Lander';

      if (Object.keys(landerQueryNoSort).length > 1) {
        gaPageType = 'Detail + Category Lander';
      }
    } else {
      gaPageType = 'Navigation Lander';
    }
  }

  if (params.mode == 'pla') {
    gaPageType = 'PLA';
  }

  fireGtagEvent('event', 'view_item_list', {
    page_type: gaPageType,
    first_visit: isFirstVisit(),
    lander_id: lander?.id,
    model_id: lander?.model?.id,
    mode: params.mode,
    items: items.map(item => ({
      item_id: item.id,
      item_name: item.name,
      item_category: item.category_1,
      item_category2: item.category_2,
      item_brand: lander?.model?.brand_name,
      list_item_id: item.id,
      list_item_name: item.name,
      currency: 'USD',
      price: item.price,
    })),
  });
}

interface FireAppliedFilterParams {
  detail: string;
  detail_name: string;
  detail_value_name?: string;
}

// Private
function baseFireAppliedFilter({
  detail,
  detail_name,
  detail_value_name,
}: FireAppliedFilterParams) {
  fireAnalytics<FireAppliedFilterParams>('Applied Filter', null, {
    detail,
    detail_name,
    detail_value_name,
  });
}

export function fireAppliedFilter(
  facet: RailsFacetGroup | string,
  value: BaseFacet | string[] | number[] | string | number | null,
) {
  let facetSlug: string;

  if (typeof facet === 'string') {
    facetSlug = facet;
  } else {
    facetSlug = facet.slug;
  }

  baseFireAppliedFilter({
    detail: facetSlug,
    detail_name: typeof facet === 'object' ? facet.name : facetSlug,
    detail_value_name:
      value && typeof value === 'object' && 'name' in value ? value.name : '',
  });
}

export function fireAppliedFastShipping(zip: string) {
  baseFireAppliedFilter({
    detail: 'fast_shipping',
    detail_name: 'Delivery',
    detail_value_name: zip,
  });
}

export function fireAppliedCanadianSeller(enabled: boolean) {
  baseFireAppliedFilter({
    detail: 'canadian_seller_only',
    detail_name: 'Delivery',
    detail_value_name: enabled ? 'true' : 'false',
  });
}

export function fireViewedFilters() {
  fireAnalytics('Visited Filters');
}
