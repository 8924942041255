import React, { useContext } from 'react';
import { RailsSavedSearch } from '~/typings/services/rails/savedSearch';

interface ISavedSearchContext {
  savedSearch?: RailsSavedSearch;
}

export const SavedSearchContext = React.createContext<ISavedSearchContext>({});

export function useSavedSearchContext() {
  return useContext(SavedSearchContext);
}
