import React, { useState } from 'react';

export default function useCarousel(length: number, defaultSlide = 0) {
  const [index, setIndex] = useState(defaultSlide);

  function goToPreviousIndex(e?: React.MouseEvent | KeyboardEvent) {
    e?.preventDefault();
    e?.stopPropagation();
    setIndex(index => (index === 0 ? length - 1 : index - 1));
  }

  function goToNextIndex(e?: React.MouseEvent | KeyboardEvent) {
    e?.preventDefault();
    e?.stopPropagation();
    setIndex(index => (index === length - 1 ? 0 : index + 1));
  }

  return {
    goToNextIndex,
    goToPreviousIndex,
    index,
    setIndex,
  };
}
