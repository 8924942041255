import { differenceInDays, differenceInHours } from 'date-fns';
import React from 'react';
import { pluralize } from 'utils';
import BaseBanner from '~/components/Chrome/Banners/BaseBanner';
import SettingsPageLink from '~/components/Links/SettingsPageLink';
import createSvg from '~/public/static/svg/createSvg';

const StarIcon = createSvg(props => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill="#02C874"
      fillRule="evenodd"
      d="M10 15.27L16.18 19 14.54 11.97 20 7.24 12.81 6.63 10 0 7.19 6.63 0 7.24 5.46 11.97 3.82 19z"
      transform="translate(2 2)"
    />
  </svg>
));

const GoodIcon = createSvg(props => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="pnmkrtg8la"
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zM8 13.17L3.83 9l-1.42 1.41L8 16 18 6l-1.5-1.5L8 13.17z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <mask id="2a8va7qn9b" fill="#fff">
        <use xlinkHref="#pnmkrtg8la" />
      </mask>
      <use fill="#FFF" xlinkHref="#pnmkrtg8la" />
      <g fill="#02C874" mask="url(#2a8va7qn9b)">
        <path d="M0 0H40V40H0z" transform="translate(-2 -2)" />
      </g>
    </g>
  </svg>
));

const WarningIcon = createSvg(props => (
  <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
    <defs>
      <path
        id="1daf0gtuqa"
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm1 15H9v-2h2v2zm0-4H9V5h2v6z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="66nxc7bf3b" fill="#fff">
        <use xlinkHref="#1daf0gtuqa" />
      </mask>
      <use fill="#FFF" xlinkHref="#1daf0gtuqa" />
      <g fill="#E84F4F" mask="url(#66nxc7bf3b)">
        <path d="M0 0H24V24H0z" transform="translate(-2 -2)" />
      </g>
    </g>
  </svg>
));

interface Props {
  lastImportTime?: null | string;
}

const CsvImportReminderBanner: React.FC<Props> = ({ lastImportTime }) => {
  if (!lastImportTime) {
    return (
      <BaseBanner className="border-grey-500 border-b">
        <StarIcon />
        <b className="mr-4">GET STARTED!</b>

        <div className="mr-4">Upload your items now to start selling.</div>

        <SettingsPageLink className="underline" page="csvImport">
          Start Now
        </SettingsPageLink>
      </BaseBanner>
    );
  }

  const diffHours = differenceInHours(new Date(), new Date(lastImportTime));

  if (diffHours <= 24) {
    return (
      <BaseBanner className="border-grey-500 border-b">
        <GoodIcon />
        <b className="mr-4">ALL GOOD</b>

        <div className="mr-4">
          Inventory last updated {diffHours === 0 ? 'less than an' : diffHours}{' '}
          {pluralize(Math.max(1, diffHours), 'hour', 'hours')} ago.
        </div>

        <SettingsPageLink className="underline" page="csvImport">
          Update Now
        </SettingsPageLink>
      </BaseBanner>
    );
  }

  const diffDays = differenceInDays(new Date(), new Date(lastImportTime));

  return (
    <BaseBanner className="border-grey-500 border-b">
      <WarningIcon />

      <b>WARNING</b>

      <div>
        Inventory last updated {diffDays} {pluralize(diffDays, 'day', 'days')}{' '}
        ago
      </div>

      <SettingsPageLink className="underline" page="csvImport">
        Update Now
      </SettingsPageLink>
    </BaseBanner>
  );
};

export default CsvImportReminderBanner;
