import {
  ArchiveBoxIcon,
  ChatBubbleLeftEllipsisIcon,
  CubeIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  HeartIcon,
  StarIcon,
  TruckIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { AuctionIcon } from '~/components/Icons/AuctionIcon';

interface Props {
  slug: string;
}

const SwapsInboxCountIcon: React.FC<Props> = ({ slug }) => {
  switch (slug) {
    case 'all':
      return <EnvelopeIcon className="h-5 w-5" />;

    case 'active_auctions':
      return <AuctionIcon className="h-5 w-5" />;

    case 'negotiations':
      return <ChatBubbleLeftEllipsisIcon className="h-5 w-5" />;

    case 'printed_label':
      return <ExclamationCircleIcon className="h-5 w-5" />;

    case 'shipped':
      return <TruckIcon className="h-5 w-5" />;

    case 'completed':
      return <CurrencyDollarIcon className="h-5 w-5" />;

    case 'disputes':
    case 'disputed':
      return <ExclamationTriangleIcon className="h-5 w-5" />;

    case 'archived':
      return <ArchiveBoxIcon className="h-5 w-5" />;

    case 'recent_favorites':
      return <HeartIcon className="h-5 w-5" />;

    case 'starred':
      return <StarIcon className="h-5 w-5" />;

    case 'active_orders':
    case 'needs_shipment':
    default:
      return <CubeIcon className="h-5 w-5" />;
  }
};

export default SwapsInboxCountIcon;
