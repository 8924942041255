'use client';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const WarningIcon: React.FC<any> = ({
  className,
  filled,
  height,
  width,
  ...props
}) => (
  <svg
    height={height || 22}
    width={width || 19}
    {...props}
    className={clsx(className, filled && 'svg-warning-icon-filled')}
    viewBox="0 0 22 19"
  >
    <path fill="#FFF" d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z" />
  </svg>
);

WarningIcon.propTypes = {
  filled: PropTypes.bool,
};

export default WarningIcon;
