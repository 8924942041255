import Baseball1 from '~/public/static/celebration/baseball-1.webm';
import Baseball2 from '~/public/static/celebration/baseball-2.webm';
import Baseball3 from '~/public/static/celebration/baseball-3.webm';
import Bikes1 from '~/public/static/celebration/bikes-1.webm';
import Bikes2 from '~/public/static/celebration/bikes-2.webm';
import Footwear1 from '~/public/static/celebration/footwear-1.webm';
import Footwear2 from '~/public/static/celebration/footwear-2.webm';
import Golf1 from '~/public/static/celebration/golf-1.webm';
import Golf2 from '~/public/static/celebration/golf-2.webm';
import Golf3 from '~/public/static/celebration/golf-3.webm';
import Golf4 from '~/public/static/celebration/golf-4.webm';
import Golf5 from '~/public/static/celebration/golf-5.webm';
import Hike1 from '~/public/static/celebration/hike-1.webm';
import Hockey1 from '~/public/static/celebration/hockey-1.webm';
import Hockey2 from '~/public/static/celebration/hockey-2.webm';
import Hockey3 from '~/public/static/celebration/hockey-3.webm';
import Lacrosse1 from '~/public/static/celebration/lacrosse-1.webm';
import Lacrosse2 from '~/public/static/celebration/lacrosse-2.webm';
import Lacrosse3 from '~/public/static/celebration/lacrosse-3.webm';
import Skiing1 from '~/public/static/celebration/skiing-1.webm';
import Skiing2 from '~/public/static/celebration/skiing-2.webm';
import Skiing3 from '~/public/static/celebration/skiing-3.webm';
import Snowboarding1 from '~/public/static/celebration/snowboarding-1.webm';
import Snowboarding2 from '~/public/static/celebration/snowboarding-2.webm';
import Snowboarding3 from '~/public/static/celebration/snowboarding-3.webm';
import Soccer1 from '~/public/static/celebration/soccer-1.webm';
import Soccer2 from '~/public/static/celebration/soccer-2.webm';
import Soccer3 from '~/public/static/celebration/soccer-3.webm';
import Softball1 from '~/public/static/celebration/softball-1.webm';
import Softball2 from '~/public/static/celebration/softball-2.webm';
import Softball3 from '~/public/static/celebration/softball-3.webm';
import Tennis1 from '~/public/static/celebration/tennis-1.webm';
import Tennis2 from '~/public/static/celebration/tennis-2.webm';
import Tennis3 from '~/public/static/celebration/tennis-3.webm';
import WomensLacrosse1 from '~/public/static/celebration/womens-lacrosse-1.webm';
import CheckoutLoadingVideo from '~/public/static/checkout-loading.mp4';
import IMG1 from '~/public/static/error-page/1.webm';
import IMG10 from '~/public/static/error-page/10.webm';
import IMG11 from '~/public/static/error-page/11.webm';
import IMG12 from '~/public/static/error-page/12.webm';
import IMG13 from '~/public/static/error-page/13.webm';
import IMG14 from '~/public/static/error-page/14.webm';
import IMG15 from '~/public/static/error-page/15.webm';
import IMG16 from '~/public/static/error-page/16.webm';
import IMG17 from '~/public/static/error-page/17.webm';
import IMG2 from '~/public/static/error-page/2.webm';
import IMG3 from '~/public/static/error-page/3.webm';
import IMG4 from '~/public/static/error-page/4.webm';
import IMG5 from '~/public/static/error-page/5.webm';
import IMG6 from '~/public/static/error-page/6.webm';
import IMG7 from '~/public/static/error-page/7.webm';
import IMG8 from '~/public/static/error-page/8.webm';
import IMG9 from '~/public/static/error-page/9.webm';
import randomNumber from '~/utils/randomNumber';

import SidelineProSlide1 from '~/assets/sideline-pro/slide-1.webm';
import SidelineProSlide2 from '~/assets/sideline-pro/slide-2.webm';
import SidelineProSlide3 from '~/assets/sideline-pro/slide-3.webm';
import SidelineProSlide4 from '~/assets/sideline-pro/slide-4.webm';

export const SIDELINE_PRO_SLIDES = [
  SidelineProSlide1,
  SidelineProSlide2,
  SidelineProSlide3,
  SidelineProSlide4,
];

export const CheckoutLoadingVideoFile = CheckoutLoadingVideo;

export const CELEBRATION_VIDEO_CATEGORIES = {
  baseball: [Baseball1, Baseball2, Baseball3],
  bikes: [Bikes1, Bikes2],
  footwear: [Footwear1, Footwear2],
  golf: [Golf1, Golf2, Golf3, Golf4, Golf5],
  hike: [Hike1],
  hockey: [Hockey1, Hockey2, Hockey3],
  lacrosse: [Lacrosse1, Lacrosse2, Lacrosse3],
  skiing: [Skiing1, Skiing2, Skiing3],
  snowboarding: [Snowboarding1, Snowboarding2, Snowboarding3],
  soccer: [Soccer1, Soccer2, Soccer3],
  softball: [Softball1, Softball2, Softball3],
  tennis: [Tennis1, Tennis2, Tennis3],
  'womens-lacrosse': [WomensLacrosse1],
} as const;

export const ERROR_IMAGES = [
  IMG1,
  IMG2,
  IMG3,
  IMG4,
  IMG5,
  IMG6,
  IMG7,
  IMG8,
  IMG9,
  IMG10,
  IMG11,
  IMG12,
  IMG13,
  IMG14,
  IMG15,
  IMG16,
  IMG17,
];

export function newRandomImage(): number {
  return randomNumber(1, ERROR_IMAGES.length - 1);
}
