import { fetcher } from 'fetcher-session';
import { IAMRegistrationResponse } from '~/typings/services/iam/responses';
import Cookies from 'js-cookie';
import { KnownCookie } from 'common-types';
import {
  IAMRegisterRequest,
  IAMSocialAuthFacebookLinkRequest,
  IAMSocialAuthFacebookRequest,
  IAMUser,
  TokenAuthorization,
} from '~/typings/services/iam/auth';
import { recaptchaHeader } from '~/api/auth';

export const attemptSocialAuthFacebook = (
  facebookToken: string,
  recaptchaToken: string,
) =>
  fetcher<TokenAuthorization>('/iam/v1/auth/token', {
    body: {
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      grant_type: 'facebook_token',
      referral_code: Cookies.get('referral_code' as KnownCookie),
      token: facebookToken,
    } as IAMSocialAuthFacebookRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    method: 'post',
  });

export const postLinkFacebookAccount = (
  facebookToken: string,
  recaptchaToken: string,
) =>
  fetcher<IAMUser>(({ uuid }) => `/iam/v1/auth/user/${uuid}/link`, {
    body: {
      account_type: 'FACEBOOK',
      access_token: facebookToken,
    } as IAMSocialAuthFacebookLinkRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    method: 'post',
  });

export const deleteUnlinkFacebookAccount = (accountId: string) =>
  fetcher<TokenAuthorization>(({ uuid }) => `/iam/v1/auth/user/${uuid}/link`, {
    method: 'delete',
    body: {
      account_id: accountId,
      account_type: 'FACEBOOK',
    },
  });

export const postRegisterNewUserFacebook = (
  email: string,
  username: string,
  facebookToken: string,
  recaptchaToken: string,
) =>
  fetcher<IAMRegistrationResponse>('/iam/v1/auth/user', {
    body: {
      email,
      username,
      agree_with_policy: true,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      link: {
        account_type: 'FACEBOOK',
        access_token: facebookToken,
      },
    } as IAMRegisterRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    dataOnly: false,
    method: 'post',
  });
