'use client';

import { clsx } from 'clsx';
import Head from 'next/head';
import React, { useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Button } from 'ui';
import { HeroImage } from '~/components/Homepage/Elements';
import HomepageEmpowerHero from '~/components/Homepage/HomepageEmpowerHero';
import Image, { generateFastlyImageUrl } from '~/components/Image';
import {
  CarouselNextButton,
  CarouselPreviousButton,
} from '~/components/ItemDetails/elements';
import A from '~/components/Links/A';
import GooglePlayStoreLink from '~/components/Links/GooglePlayStoreLink';
import IosAppStoreLink from '~/components/Links/IosAppStoreLink';
import RegisterLink from '~/components/Links/RegisterLink';
import EmailLoginButton from '~/components/LoginRegister/EmailLoginButton';
import FacebookLoginButton from '~/components/LoginRegister/FacebookLoginButton';
import { Indicator } from '~/components/Swipeable/Elements';
import useCarousel from '~/hooks/useCarousel';
import IOS_APP_STORE from '~/public/static/ios-app-store.png';
import PlayStoreIcon from '~/public/static/svg/PlayStoreIcon';
import Stars from '~/public/static/svg/Stars';
import {
  RailsBlock,
  RailsTile,
  RailsTileVersion,
} from '~/typings/services/rails/tile';

function sanitizeColor(color?: string | null) {
  if (!color) {
    return;
  }

  if (color.startsWith('#')) {
    return color;
  } else {
    return `#${color}`;
  }
}

const BaseHeroContent: React.FC<{
  androidLink: any;
  loggedIn: boolean;
  mobile: boolean;
  tile: RailsTile;
  version?: RailsTileVersion;
}> = ({ androidLink, mobile, loggedIn, tile, version }) => {
  if (version ? version.image_type === 'blank' : tile.image_type === 'blank') {
    return (
      <div className="container min-h-[300px] md:min-h-[500px]">
        <A
          href={tile.destination || '#'}
          className="absolute inset-0 bg-transparent"
        />
      </div>
    );
  }

  let buttons: React.ReactNode = null;

  if (loggedIn || version?.image_type === 'app_logo') {
    if (version?.button_text) {
      buttons = (
        <A
          href={tile.destination || '#'}
          className="relative mb-4 block md:mb-0 md:inline-block"
        >
          <Button
            className="w-full"
            style={{
              backgroundColor:
                sanitizeColor(version?.button_color) || '#02c874',
              borderColor: sanitizeColor(version?.button_color) || '#02c874',
            }}
          >
            {version?.button_text}
          </Button>
        </A>
      );
    }
  } else if (version?.image_type === 'join_prompt') {
    buttons = (
      <React.Fragment>
        <RegisterLink
          className="relative"
          modalProps={{ defaultView: 'registerForm' }}
        >
          <EmailLoginButton className="mb-3" text="JOIN WITH EMAIL" />
        </RegisterLink>

        <FacebookLoginButton text="JOIN WITH FACEBOOK" />
      </React.Fragment>
    );
  }

  return (
    <div
      className={clsx(
        'container flex px-12 py-8 md:px-4 md:py-12',
        version?.halignment === 'left'
          ? 'justify-start text-left'
          : version?.halignment === 'center'
            ? 'justify-center text-center'
            : version?.halignment === 'right'
              ? 'justify-end'
              : '',
        'min-h-[300px] md:min-h-[500px]',
      )}
    >
      <div
        className="flex flex-col justify-center"
        style={{
          zIndex: 1,
        }}
      >
        <A
          href={tile.destination || '#'}
          className="absolute inset-0 bg-transparent"
        />

        <h1
          className="mb-1 text-4xl font-bold"
          style={{
            color: sanitizeColor(version?.text_color) || '#fff',
            fontSize: mobile ? undefined : '2.75rem',
          }}
        >
          {tile.title}
        </h1>

        {tile.subtitle && (
          <h1
            className="mb-4 text-xl font-bold"
            style={{
              color: sanitizeColor(version?.text_color) || '#fff',
            }}
          >
            {tile.subtitle}
          </h1>
        )}

        <div
          className={clsx(
            version?.halignment === 'center' ? 'mx-auto' : '',
            'w-full md:w-[350px]',
          )}
        >
          {mobile ? buttons : <div className="mb-6">{buttons}</div>}
          <div className="mb-4 flex flex-col-reverse items-center justify-between md:flex-row">
            <div className="mt-2 flex items-center md:mr-8 md:mt-0">
              <IosAppStoreLink source="homepage" className="relative mr-4">
                <Image
                  className="inline-block md:mr-8"
                  height={32}
                  width={100}
                  src={IOS_APP_STORE}
                  alt="iOS App Store"
                />
              </IosAppStoreLink>

              {androidLink}
            </div>

            <div className="flex flex-col text-center">
              <Stars className="mb-1" />
              <div
                className="text-xs"
                style={{
                  color: sanitizeColor(version?.text_color) || '#fff',
                }}
              >
                18k+ Ratings
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  block: RailsBlock;
  loggedIn: boolean;
  mobile: boolean;
}

const HomepageCmsHero: React.FC<Props> = ({ block, loggedIn, mobile }) => {
  const { index, goToNextIndex, goToPreviousIndex, setIndex } = useCarousel(
    block.tiles?.length!,
  );

  const interval = useRef<any>(null);
  useEffect(() => {
    // Don't loop from end to start
    if (block.layout === 'carousel' && index < block.tiles?.length! - 1) {
      const int = setInterval(goToNextIndex, 8000);
      interval.current = int;
      return () => clearInterval(int);
    }
  }, [index]);

  const androidLink = (
    <GooglePlayStoreLink className="relative mx-0 inline-flex sm:mx-auto">
      <PlayStoreIcon height={32} width={106} />
    </GooglePlayStoreLink>
  );

  if (block.layout === 'carousel') {
    return (
      <>
        <section className="relative mb-4 md:mb-6">
          <SwipeableViews
            index={index}
            onChangeIndex={setIndex}
            animateHeight
            className="min-h-[300px] md:min-h-[500px]"
          >
            {block.tiles?.map(tile => {
              let version = tile.versions?.find(v => v.platform === 'web');
              if (mobile) {
                const mobileVersion = tile.versions?.find(
                  v => v.platform === 'mobile',
                );
                if (mobileVersion) {
                  version = mobileVersion;
                }
              }

              const backgroundImageUrl = generateFastlyImageUrl(
                version
                  ? version.background_image_url
                  : tile.background_image_url,
                {
                  height: 700,
                },
              );

              return (
                <React.Fragment key={tile.id}>
                  <Head>
                    <link rel="preload" as="image" href={backgroundImageUrl} />
                  </Head>
                  <div className="relative">
                    <HeroImage loading="eager" src={backgroundImageUrl} />

                    {version?.image_type === 'empower_athletes' ? (
                      <HomepageEmpowerHero
                        tile={tile}
                        loggedIn={loggedIn}
                        block={block}
                        version={version}
                      />
                    ) : (
                      <BaseHeroContent
                        androidLink={androidLink}
                        loggedIn={loggedIn}
                        mobile={mobile}
                        tile={tile}
                        version={version}
                      />
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </SwipeableViews>

          {block.tiles?.length! > 1 && (
            <>
              <CarouselPreviousButton onClick={goToPreviousIndex} />
              <CarouselNextButton onClick={goToNextIndex} />
            </>
          )}
        </section>

        {block.tiles?.length! > 1 && (
          <div className="my-4 flex justify-center space-x-2">
            {block.tiles?.map((t, i) => (
              <button key={t.id} onClick={() => setIndex(i)} className="block">
                <Indicator key={t.id} selected={i === index} />
              </button>
            ))}
          </div>
        )}
      </>
    );
  }

  const tile = block?.tiles?.[index]; // it won't always be 0
  let version: undefined | RailsTileVersion;

  if (mobile) {
    version = tile?.versions?.find(v => v.platform === 'mobile');
  } else {
    version = tile?.versions?.find(v => v.platform === 'web');
  }

  if (!tile || !version) {
    return null;
  }

  const backgroundImageUrl = generateFastlyImageUrl(
    version ? version.background_image_url : tile.background_image_url,
    {
      height: 700,
    },
  );

  return (
    <section className="relative mb-4 md:mb-6">
      <Head>
        <link rel="preload" as="image" href={backgroundImageUrl} />
      </Head>

      <HeroImage src={backgroundImageUrl} />

      {/*justify-around px-6 pt-6 pb-4 md:w-3/5 lg:w-2/5 xl:px-10 md:py-10*/}
      <BaseHeroContent
        androidLink={androidLink}
        loggedIn={loggedIn}
        mobile={mobile}
        tile={tile}
        version={version}
      />
    </section>
  );
};

export default HomepageCmsHero;
