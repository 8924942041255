'use client';

const EmailIcon = ({ fill = '#4a4a4a' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
    >
      <path
        fill={fill}
        d="M23.961 14.955c0 .525-.148 1.01-.384 1.439L16.01 7.94l7.484-6.539c.29.462.466 1.004.466 1.59v11.963zm-11.98-5.48L22.394.377A2.961 2.961 0 0 0 20.966 0H2.996a2.95 2.95 0 0 0-1.428.377l10.413 9.098zm2.903-.55l-2.41 2.107a.748.748 0 0 1-.986 0L9.077 8.925l-7.661 8.561c.459.288.997.46 1.58.46h17.97c.582 0 1.12-.172 1.58-.46l-7.662-8.561zM.467 1.402A2.967 2.967 0 0 0 0 2.992v11.963c0 .525.147 1.011.385 1.439l7.564-8.455L.467 1.402z"
      />
    </svg>
  );
};

export default EmailIcon;
