interface IdType {
  id?: number | string;
  children?: IdType[];
}

export function getDetailById<T extends IdType>(
  details: T[] | undefined | null,
  id?: number | string,
): undefined | null | T {
  let match: null | T = null;

  if (!details || !Array.isArray(details)) {
    return match;
  }

  details.some(val => {
    if (val.id == id) {
      match = val;
      return true;
    }

    if (val.children) {
      const recursiveResult = getDetailById<T>(val.children as T[], id);
      if (recursiveResult) {
        match = recursiveResult;
        return true;
      }
    }
  });

  return match;
}
