import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { qsParse } from 'utils';
import { ParsedUrlQuery } from 'querystring';

/**
 * Gets the router's asPath query into a typed object.
 */
export default function useBrowserRouteQuery<Q extends ParsedUrlQuery>() {
  const router = useRouter();
  return useMemo(
    () => qsParse<Q>(router.asPath?.split('?')[1]),
    [router.asPath],
  );
}
