import {
  RailsBlock,
  RailsTile,
  RailsTileVersion,
} from '~/typings/services/rails/tile';
import { useMobile } from 'common-nextjs';
import { useMemo } from 'react';

export default function useTileVersions(block: RailsBlock) {
  const mobile = useMobile();

  return useMemo(() => {
    return block.tiles?.map<RailsTile & Partial<RailsTileVersion>>(tile => {
      const blockLayoutVersion = tile.versions?.find(
        v => v.block_layout === block.layout,
      );

      if (blockLayoutVersion) {
        return {
          ...tile,
          ...blockLayoutVersion,
        };
      }

      const platformVersion = tile.versions?.find(
        v => v.platform === (mobile ? 'mobile' : 'web'),
      );

      return {
        ...tile,
        ...platformVersion,
      };
    });
  }, [block.layout, block.tiles, mobile]);
}
