import { useEffect } from 'react';

export default function useWindowRefocused(
  onWindowRefocused: () => void,
  onWindowBlur?: () => void,
) {
  const onVisibilityChange = () => {
    if (document.hidden) {
      onWindowBlur?.();
    } else {
      onWindowRefocused();
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      window.removeEventListener('visibilitychange', onVisibilityChange);
  });
}
