import { fetcher, makeFetcher } from 'fetcher-session';
import { Cart } from '~/typings/services/rails/cart';

export const fetchCart = makeFetcher<Cart>(
  () =>
    ({ uuid }) =>
      `/v1/users/${uuid}/cart`,
);

export const addToCart = makeFetcher<
  Cart,
  [itemId: number, selectedVariationId?: string | number | undefined]
>((itemId, selectedVariationId) => [
  ({ uuid }) => `/v1/users/${uuid}/cart/cart_items`,
  {
    body: {
      cart: {
        cart_item: {
          item_id: itemId,
          item_variation_id: selectedVariationId,
        },
      },
    },
    method: 'post',
  },
]);

export const deleteFromCart = (cartItemId: number) =>
  fetcher<Cart>(
    ({ uuid }) => `/v1/users/${uuid}/cart/cart_items/${cartItemId}`,
    {
      method: 'delete',
    },
  );

export const postBatchAddToCart = (itemIds: number[]) =>
  fetcher<never>(({ uuid }) => `/v1/users/${uuid}/cart/batch_add`, {
    method: 'post',
    body: {
      cart: {
        item_ids: itemIds,
      },
    },
  });

export const postCartToCheckout = () =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/cart/checkout`, {
    method: 'post',
  });
