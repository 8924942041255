'use client';

import { getEnv } from 'common-nextjs';
import { getErrorMessage } from 'fetcher-session';
import { useEffect, useState } from 'react';
import useScript from '~/hooks/useScript';
import waitUntil from '~/utils/waitUntil';

class RecaptchaExecutionFailure extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RecaptchaExecutionFailure';
  }
}

export type RecaptchaActionType =
  | 'checkout'
  | 'guest_register'
  | 'login'
  | 'payment_method'
  | 'register'
  | 'bumps'
  | 'client_token'
  | 'social_auth';

export default function useRecaptcha() {
  const [scriptLoaded, scriptError] = useScript(
    `https://www.google.com/recaptcha/api.js?render=${getEnv(
      'RECAPTCHA_V3_KEY',
    )}`,
  );

  function getRecaptchaToken(action: RecaptchaActionType) {
    return new Promise<string>(async (resolve, reject) => {
      await waitUntil(() => window.grecaptcha != null);

      grecaptcha.ready(() => {
        grecaptcha
          .execute(getEnv('RECAPTCHA_V3_KEY')!, {
            action,
          })
          .then(
            token => {
              resolve(token);
            },
            err => {
              reject(new RecaptchaExecutionFailure(getErrorMessage(err)));
            },
          );
      });
    });
  }

  return {
    getRecaptchaToken,
    scriptLoaded,
  };
}

export function useAutoRecaptcha(action: RecaptchaActionType) {
  const [error, setError] = useState<string | null>();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { getRecaptchaToken, scriptLoaded } = useRecaptcha();

  function refreshRecaptcha() {
    getRecaptchaToken(action)
      .then(token => {
        setRecaptchaToken(token);
      })
      .catch(err => setError(getErrorMessage(err)));
  }

  useEffect(() => {
    if (scriptLoaded) {
      refreshRecaptcha();
    }
  }, [scriptLoaded, action]);

  return { error, recaptchaToken, refreshRecaptcha };
}
