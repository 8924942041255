'use client';

import React from 'react';
import Expander from '~/components/Expander/Expander';

function defaultTrigger(label: React.ReactNode) {
  return (handleOpen, open, handleClose) => (
    <button
      className="my-2 flex w-full items-center justify-between py-2 text-base font-semibold"
      onClick={open ? handleClose : handleOpen}
      css={`
        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: initial !important;
        }
      `}
    >
      {React.isValidElement(label) ? (
        label
      ) : (
        <div className="text-base font-semibold">{label}</div>
      )}

      <div
        css={`
          line-height: 1;
          font-size: 1.5rem;
        `}
      >
        {open ? '−' : '+'}
      </div>
    </button>
  );
}

interface Props {
  className?: string;
  defaultExpanded?: boolean;
  label: React.ReactNode;
  onChange?: (expanded: boolean) => void;
}

const ExpanderWithLabel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  defaultExpanded = false,
  label,
  onChange,
}) => (
  <Expander
    className={className}
    trigger={defaultTrigger(label)}
    defaultExpanded={defaultExpanded}
    onChange={onChange}
  >
    {children}
  </Expander>
);

export default ExpanderWithLabel;
