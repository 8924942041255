import { useMobile } from 'common-nextjs';
import React, { useEffect } from 'react';
import SwipeableViewsWithIndicators from '~/components/Swipeable/SwipeableViewsWithIndicators';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import useCarousel from '~/hooks/useCarousel';
import useTileVersions from '~/hooks/useTileVersions';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const ShortHeroBlock: React.FC<Props> = ({ block }) => {
  const tiles = useTileVersions(block);
  const mobile = useMobile();
  const { index, setIndex, goToNextIndex } = useCarousel(tiles?.length || 0);
  const height = mobile ? 140 : 200;

  useEffect(() => {
    const timeout = setTimeout(goToNextIndex, 5000);
    return () => clearTimeout(timeout);
  }, [goToNextIndex, index]);

  return (
    <SwipeableViewsWithIndicators index={index} onChangeIndex={setIndex}>
      {tiles?.map(tile => (
        <a
          key={tile.id}
          href={tile.destination}
          className="relative block"
          style={{
            backgroundColor: tile.background_color || undefined,
            height,
          }}
          title={tile.title}
        >
          <TileImageOrVideo
            alt={tile.title}
            src={tile.background_image_url}
            className="absolute block h-full w-full object-contain"
            useFastly={{
              height: 300,
            }}
          />
        </a>
      ))}
    </SwipeableViewsWithIndicators>
  );
};

export default ShortHeroBlock;
