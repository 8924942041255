'use client';

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React, {
  CSSProperties,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

interface Props {
  children?: React.ReactNode;
  className?: string;
  scrollButton?: boolean;
  style?: CSSProperties;
}

const FeatheredOverflowContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, scrollButton, style }, ref) => {
    const [feathers, setFeathers] = useState({ left: false, right: true });
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!containerRef.current) {
        return;
      }

      const node = containerRef.current;

      function scrollHandler() {
        if (!containerRef.current) {
          return;
        }

        const newFeathers = { ...feathers };

        newFeathers.right =
          node.scrollLeft + node.clientWidth < node.scrollWidth - 12;

        newFeathers.left = node.scrollLeft > 12;

        if (
          newFeathers.left !== feathers.left ||
          newFeathers.right !== feathers.right
        ) {
          setFeathers(newFeathers);
        }
      }

      node.addEventListener('scroll', scrollHandler);

      return () => node?.removeEventListener('scroll', scrollHandler);
    }, [feathers]);

    useImperativeHandle(ref, () => containerRef.current!, []);

    const featherCx =
      'absolute top-0 z-10 w-4 h-full pointer-events-none from-white';

    return (
      <div className="relative">
        {feathers.left && (
          <div className={`${featherCx} left-0 bg-gradient-to-r`} />
        )}

        <div
          className={`flex overflow-x-auto px-px pr-2 ${className}`}
          ref={containerRef}
          style={style}
        >
          {children}
        </div>

        {feathers.right && (
          <>
            <div className={`${featherCx} right-0 bg-gradient-to-l`} />
            {scrollButton && (
              <button
                className="bg-turf-green-500 absolute -right-2 top-1/2 -translate-y-1/2 transform rounded-full p-2 shadow"
                onClick={() => {
                  containerRef?.current?.scrollBy({
                    left: 600,
                    behavior: 'smooth',
                  });
                }}
                style={{
                  zIndex: 11,
                }}
              >
                <ArrowRightIcon className="h-6 w-6 text-white" />
              </button>
            )}
          </>
        )}
      </div>
    );
  },
);

export default FeatheredOverflowContainer;
