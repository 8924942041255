import React from 'react';
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views';
import { Indicator } from '~/components/Swipeable/Elements';

type Props = Omit<SwipeableViewsProps, 'ref' | 'index'> & {
  index: number;
};

const SwipeableViewsWithIndicators: React.FC<Props> = ({
  children,
  className,
  index,
  onChangeIndex,
  ...props
}) => (
  <div className={className}>
    <SwipeableViews
      className="mb-2"
      {...props}
      index={index}
      onChangeIndex={onChangeIndex}
    >
      {children}
    </SwipeableViews>

    <div className="mt-4 flex justify-center space-x-2">
      {React.Children.map(children, (_, i) => (
        <button onClick={() => onChangeIndex?.(i, i)}>
          <Indicator
            key={i}
            selected={Math.abs(index) % React.Children.count(children) === i}
          />
        </button>
      ))}
    </div>
  </div>
);

export default SwipeableViewsWithIndicators;
