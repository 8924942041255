import { isEqual } from 'date-fns';
import { useRouter } from 'next/router';
import React from 'react';
import PageView from '~/components/legos/PageView';
import useKnownCookie from '~/hooks/useKnownCookie';
import usePageView from '~/hooks/usePageView';
import { microBannerPageViewId } from '../../../constants/hardcodedPageViewIds';

interface Props {}

const MicroBanner: React.FC<Props> = () => {
  const { data: pageView } = usePageView(microBannerPageViewId);
  const [time] = useKnownCookie('sls_dmicro_b');
  const router = useRouter();

  if (
    !pageView ||
    ![
      '/',
      '/shop/[...lander]',
      '/gear/[...item]',
      '/sell-now',
      '/locker/[username]',
      '/locker/[username]/[itemState]',
      '/locker/[username]/about',
      '/locker/[username]/favorites',
      '/locker/[username]/feedback',
      '/locker/[username]/followers',
      '/locker/[username]/following',
    ].includes(router.route)
  ) {
    return null;
  }

  if (time && isEqual(new Date(+time), new Date(pageView.updated_at))) {
    return null;
  }

  return <PageView pageView={pageView} allowBlockLayouts={['micro-banner']} />;
};

export default MicroBanner;
