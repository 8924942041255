import React from 'react';
import useTileVersions from '~/hooks/useTileVersions';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const LanderCollectionsLightBlock: React.FC<Props> = ({ block }) => {
  const tileVersions = useTileVersions(block);

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
      {tileVersions?.map(tile => (
        <a
          key={tile.id}
          href={tile.destination}
          className="bg-grey-100 flex h-24 items-center justify-center rounded border px-4 text-center text-lg font-semibold"
        >
          {tile.title}
        </a>
      ))}
    </div>
  );
};

export default LanderCollectionsLightBlock;
