'use client';

import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fill?: string;
};

const CloseIcon: React.FC<Props> = ({
  fill = '#4a4a4a',
  height,
  width,
  ...props
}) => (
  <svg height={height || 14} width={width || 14} {...props} viewBox="0 0 14 14">
    <path
      fill={fill}
      d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7"
    />
  </svg>
);

export default CloseIcon;
