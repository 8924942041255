import { useRouter } from 'next/router';
import { qsStringify } from 'utils';
import { ParsedUrlQuery } from 'querystring';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';

interface RouteOptions {
  replaceRoute?: boolean;
  scroll?: boolean;
  shallow?: boolean;
}

export default function useRouteQuery<TQuery extends ParsedUrlQuery>() {
  const router = useRouter();
  const query = useBrowserRouteQuery<TQuery>();

  async function replaceQuery(
    newQuery: Partial<TQuery>,
    options?: RouteOptions,
  ) {
    const newString = qsStringify(newQuery);
    const fn = options?.replaceRoute ? router.replace : router.push;

    return fn(
      router.route + newString,
      router.asPath?.split('?')[0] + newString,
      {
        scroll: options?.scroll,
        shallow: options?.shallow,
      },
    );
  }

  async function updateQuery(
    newQuery: Partial<TQuery>,
    options?: RouteOptions,
  ) {
    const newString = qsStringify({ ...query, ...newQuery });
    const fn = options?.replaceRoute ? router.replace : router.push;

    return fn(
      router.route + newString,
      router.asPath?.split('?')[0] + newString,
      {
        scroll: options?.scroll,
        shallow: options?.shallow,
      },
    );
  }

  function getUpdatedRoute(
    newQuery: Partial<TQuery>,
  ): readonly [route: string, asPath: string] {
    const newString = qsStringify({ ...query, ...newQuery });
    return [
      router.route + newString,
      router.asPath?.split('?')[0] + newString,
    ] as const;
  }

  return {
    getUpdatedRoute,
    query,
    replaceQuery,
    updateQuery,
  };
}
