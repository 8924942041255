import { useRouter } from 'next/router';
import React from 'react';
import { getDetailById, getDetailPathById } from 'utils';
import GenericBreadcrumbs, {
  Breadcrumb,
} from '~/components/GenericBreadcrumbs';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';

interface Props {}

const SearchBreadcrumbs: React.FC<Props> = () => {
  const router = useRouter();
  const { category, categories, filterState, facets, lander } =
    useFacetFiltering();

  const categoryPath = getDetailPathById(
    categories!,
    category?.id ||
      lander?.model?.category_id ||
      (lander?.models_category || lander?.category)?.id!,
  );

  const {
    category: _,
    brand: __,
    sort: ___,
    state: ____,
    model: _____,
    ...landerQueryDetails
  } = lander?.query || {};

  // If there is only one detail in the query, we can show the detail name in the breadcrumbs
  const landerQuerySingleDetail = getDetailById(
    facets,
    Object.values(landerQueryDetails)?.[0]?.[0],
  );

  const path: Breadcrumb[] = [
    ...(categoryPath
      ? categoryPath.map(category => ({
          label: category.name,
          link: category.url || `/shop/${category.slug}`,
        }))
      : []),
    ...(lander?.model?.url
      ? [
          {
            label: `${lander.model.brand_name} ${lander.model.name}`,
            link: lander.model.url,
          },
        ]
      : []),
    ...(landerQuerySingleDetail != null &&
    Object.keys(landerQueryDetails).length == 1
      ? [
          {
            label: landerQuerySingleDetail?.name || '',
            link: router.asPath,
          },
        ]
      : []),
  ];

  if (path.length === 0) {
    return null;
  }

  return <GenericBreadcrumbs breadcrumbs={path} />;
};

export default SearchBreadcrumbs;
