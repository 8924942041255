import React from 'react';
import PredictiveResultLink from '~/components/Links/PredictiveResultLink';
import { RailsPredictiveSearchResult } from '~/typings/services/rails/predictiveSearch';

interface Props {
  className?: any;
  css?: any;
  results: RailsPredictiveSearchResult[];
}

const PredictiveResultsList: React.FC<Props> = ({ results, ...props }) => (
  <ul {...props}>
    {results.map(result => (
      <li key={result.id}>
        <PredictiveResultLink
          className="hover:bg-grey-200 block px-3 py-2 hover:text-black"
          result={result}
        >
          {result.name}{' '}
          {result.category_name && (
            <>
              in <b>{result.category_name}</b>
            </>
          )}
        </PredictiveResultLink>
      </li>
    ))}
  </ul>
);

export default PredictiveResultsList;
