'use client';

import { clsx } from 'clsx';
import React, { useState } from 'react';
import { ScaleFade } from './Transitions';

export type CustomIconButtonProps = {
  color?: 'danger' | 'green' | 'white';
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export type IconButtonProps = (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
) &
  CustomIconButtonProps;

function InternalIconButton({
  as: Element = 'button',
  children,
  className,
  disabled,
  color,
  size = 'medium',
  ...props
}: IconButtonProps & { as: any }) {
  const [hover, setHover] = useState(false);

  return (
    <Element
      type="button"
      {...props}
      disabled={disabled}
      className={clsx('relative flex-shrink-0 rounded-full p-1', className)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <span
        className={clsx('flex items-center justify-center', {
          'h-4 w-4': size === 'small',
          'h-6 w-6': size === 'medium',
          'h-8 w-8': size === 'large',
          'text-grey-200 cursor-not-allowed': disabled,
        })}
      >
        {children}
      </span>

      {!disabled && (
        <ScaleFade
          show={hover}
          className={clsx('absolute -inset-1 rounded-full', {
            'bg-true-black/5': !color,
            'bg-red-500/10': color === 'danger',
            'bg-green-500/10': color === 'green',
            'bg-white/10': color === 'white',
          })}
        />
      )}
    </Element>
  );
}

export function IconButton(props: IconButtonProps) {
  return <InternalIconButton {...props} as="button" />;
}

export function IconButtonAnchor(
  props: CustomIconButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>,
) {
  return <InternalIconButton {...props} as="a" />;
}
