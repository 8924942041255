import React from 'react';
import { clsx } from 'clsx';

interface Props {
  className?: string;
  css?: never;
  loading?: boolean | null;
  size?: 'small';
  margin?: false;
}

export const Loader: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  size,
  margin = true,
  loading,
}) => {
  if (loading) {
    return (
      <div
        className={clsx(
          'mx-auto block animate-spin rounded-full border-current border-t-transparent text-green-500',
          {
            'my-14': margin,
            'h-6 w-6 border-2': size === 'small',
            'h-12 w-12 border-4': !size,
          },
        )}
        role="status"
        aria-label="loading"
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return children;
};
