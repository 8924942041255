import { clsx } from 'clsx';
import React, { ComponentProps } from 'react';
import { Button } from './Button/Button';

type Props = Omit<ComponentProps<typeof Button>, 'variant'> & {
  selected?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
};

export const Tab = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, className, selected, ...props }, ref) => (
    <Button
      className={clsx(
        selected
          ? 'border-primary-500 whitespace-no-wrap border-b-4'
          : 'hover:border-grey-500 whitespace-no-wrap border-b-4 border-transparent',
        className,
        'rounded-none',
      )}
      color="secondary"
      {...props}
      ref={ref}
      variant="text"
    >
      {children}
    </Button>
  ),
);
