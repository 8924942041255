import { clsx } from 'clsx';
import React from 'react';
import ArrowRightIcon from '~/public/static/svg/ArrowRightIcon';

interface Props {
  className?: any;
  onClick?: React.MouseEventHandler;
  title: string;
}

// This is pretty much the exact same as UnlimitedTileBase.tsx, but should never have an image.

const UnlimitedTitleTile: React.FC<Props> = ({ className, onClick, title }) => (
  <button
    className={clsx(
      'border-grey-200 flex w-full items-center rounded border px-4 py-2 shadow shadow-md transition-shadow duration-300',
      className,
    )}
    onClick={onClick}
  >
    <div className="block text-left text-sm font-semibold">{title}</div>

    <div className="mr-auto" />

    <ArrowRightIcon className="block" />
  </button>
);

export default UnlimitedTitleTile;
