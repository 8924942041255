import { clsx } from 'clsx';
import { getEnv } from 'common-nextjs';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import GetTheAppFooter from '~/components/Chrome/GetTheAppFooter';
import ManagedModal from '~/components/Modal/ManagedModal';
import NewModalCloseActionButton from '~/components/NewModal/NewModalCloseActionButton';
import { useConfigurationProperty } from '~/contexts/ConfigurationContext';
import useScript from '~/hooks/useScript';
import { Freestar } from '~/typings/window';

const getFreestar = () => {
  return new Promise<Freestar>((resolve, reject) => {
    const maxTries = 100;
    let retryCount = 0;
    const waitForFreestarReady = setInterval(() => {
      if (
        typeof window.freestar?.newAdSlots === 'function' &&
        window.freestar?.fsdata?.placements?.length > 0
      ) {
        clearInterval(waitForFreestarReady);
        resolve(window.freestar);
      } else if (retryCount >= maxTries) {
        clearInterval(waitForFreestarReady);
        reject(`freestar NOT ready after ${maxTries} tries`);
      } else {
        retryCount++;
      }
    }, 100);
  });
};

type Props = {
  adSlot: string;
  channel?: string;
  className?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
  id?: string;
  style?: CSSProperties;
  responsive?: boolean;
};

const FreestarAd: React.FC<Props> = ({
  adSlot,
  containerStyle,
  containerClassName,
  channel = 'custom_channel',
  className,
  id,
  responsive = true,
  style,
}) => {
  const slotId = id || adSlot;

  const adsEnabled = useConfigurationProperty('ads');
  const [containsAd, setContainsAd] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const intersectionRef = React.useRef(null);
  const adEl = useRef<null | HTMLDivElement>(null);
  const observer = useRef<null | MutationObserver>(null);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '300px',
    threshold: 0.1,
  });

  useEffect(() => {
    if (intersection?.isIntersecting && !shouldLoad) {
      setShouldLoad(true);
    }
  }, [intersection?.isIntersecting, shouldLoad]);

  const [loadedFreestarScripts] = useScript(
    shouldLoad &&
      getEnv() === 'production' &&
      `https://a.pub.network/sidelineswap-com/pubfig.min.js`,
  );

  async function setupFreestarAd() {
    try {
      const freestar = await getFreestar();
      if (freestar?.fsdata?.placements?.find(p => p.name === adSlot)) {
        if (getEnv() !== 'production') {
          console.log(`Loading ad slot "${adSlot}"`);
        }

        freestar.newAdSlots([{ placementName: adSlot, slotId }]);
      } else {
        console.warn(
          `Freestar Ad Slot "${adSlot}" not found. ad slots:`,
          freestar?.fsdata?.placements?.map(p => p.name),
        );
      }
    } catch (e) {}
  }

  async function destroyFreestarAd() {
    try {
      if (adEl.current) {
        const freestar = await getFreestar();
        freestar?.deleteAdSlots?.(adSlot);
      }
    } catch (e) {}
  }

  useEffect(() => {
    if (!containsAd && typeof MutationObserver === 'undefined') {
      setContainsAd(true);
      return;
    }

    if (containsAd) {
      // observer.current?.disconnect();
    } else if (adEl.current) {
      observer.current = new MutationObserver(mutations => {
        if (adEl.current?.offsetHeight! > 0 && adEl.current?.offsetWidth! > 0) {
          setContainsAd(true);
        } else {
          setContainsAd(false);
        }
      });
      observer.current.observe(adEl.current, {
        childList: true,
        attributes: true,
      });

      return () => {
        observer.current?.disconnect();
        observer.current = null;
      };
    }
  }, [adEl.current, containsAd]);

  useEffect(() => {
    try {
      if (getEnv() === 'production' && loadedFreestarScripts) {
        setupFreestarAd();

        return () => {
          observer.current?.disconnect();
          observer.current = null;
          destroyFreestarAd();
        };
      }
    } catch (e) {}
  }, [loadedFreestarScripts]);

  // Always keep ads "enabled" in staging/dev even though they don't display any actual ads.
  // This is so we're constantly aware of _where_ the ads go.
  if (getEnv() === 'production' && !adsEnabled) {
    return null;
  }

  return (
    <span
      className={clsx(containerClassName, 'adspace block')}
      aria-hidden
      style={containerStyle}
      ref={intersectionRef}
      css={`
        @media (max-width: 767px) {
          min-height: 50px;
          width: 320px;
          margin-left: auto;
          margin-right: auto;
        }

        @media (min-width: 767px) {
          width: 728px;
          min-height: 90px;
          margin-left: auto;
          margin-right: auto;
        }

        .adsplacement {
          overflow: hidden;
          text-align: center;
          display: flex;
          justify-content: center;
        }

        .adsplacement.contains-ad {
          margin: 16px auto 4px;
        }

        .adspace {
          margin: 0 0 8px 0;
        }

        .adsplacement.not-contains-ad {
          position: absolute;
          left: -20000px;
        }

        .adsplacement {
          @media (max-width: 767px) {
            min-height: 50px;
            width: 320px;
          }

          @media (min-width: 767px) {
            width: 728px;
            min-height: 90px;
          }
        }

        .adsplacement.staging {
          display: flex !important;
          text-decoration: none !important;

          @media (max-width: 767px) {
            background-color: teal;
          }

          @media (min-width: 767px) {
            background-color: purple;
          }
        }
      `}
    >
      <div
        className={clsx(
          'adsplacement',
          !responsive && 'not-responsive',
          getEnv() === 'production' && !containsAd && 'not-contains-ad',
          getEnv() !== 'production' &&
            'staging flex items-center justify-center text-white',
          className,
        )}
        style={{
          display: 'block',
          ...style,
        }}
        id={slotId}
        ref={adEl}
      >
        {getEnv() !== 'production' && (
          <div className="p-2 text-center">
            <div className="text-2xl font-semibold">Freestar Ad</div>
            <div className="text-sm">
              Ad Slot: {adSlot}, {!responsive && 'Not '} Responsive
            </div>
          </div>
        )}
      </div>

      {containsAd && (
        <div className="text-center">
          <ManagedModal
            trigger={handleOpen => (
              <button
                onClick={handleOpen}
                className="text-grey-500 hover:text-grey-600 text-xs"
              >
                Why is SidelineSwap showing ads?
              </button>
            )}
            title="Why Ads?"
          >
            <div className="text-sm leading-relaxed">
              Simply put: we&apos;re reinvesting in things that are important to
              our community. Advertising revenue allows SidelineSwap to offer
              low seller fees and an improved iOS/Android experience.{' '}
            </div>

            <GetTheAppFooter
              appText={
                <div className="mb-4 text-sm italic">
                  For an ad free experience, download the app
                </div>
              }
            />
            <NewModalCloseActionButton fluid className="mt-4">
              OK
            </NewModalCloseActionButton>
          </ManagedModal>
        </div>
      )}
    </span>
  );
};

export default FreestarAd;
