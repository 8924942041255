import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import Head from 'next/head';
import React from 'react';
import { CSSProp } from 'styled-components';
import MicroBanner from '~/components/Chrome/Banners/MicroBanner';
import Footer from '~/components/Chrome/Footer';
import DefaultCanonical from '~/components/Layout/DefaultCanonical';
import PageMessage from '~/components/Layout/PageMessage';
import SimpleFooter from '~/components/Layout/SimpleFooter';
import useChromelessContext from '~/contexts/ChromelessContext';
import generatePageTitle from '~/utils/generatePageTitle';
import Header from '../Chrome/Header';
import ErrorBoundary from '../ErrorBoundary';
import ErrorPageImage from '../ErrorPageImage';

const description =
  'SidelineSwap is where athletes buy and sell their gear. Find great deals on new and used equipment, or list your gear for free in seconds.';

export interface PageMetaProps {
  canonical?: string | false;
  description?: string;
}

export interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  container?: boolean;
  css?: CSSProp;
  excludeAppCta?: boolean;
  excludeBanners?: boolean;
  excludeMessage?: boolean;
  excludeMobileSearch?: boolean;
  excludeNavigation?: boolean;
  meta?: PageMetaProps;
  simpleFooter?: boolean;
  title?: string;

  /**
   * Defaults to " | Buy and Sell on SidelineSwap".
   * Can be overwritten by passing an empty string
   */
  titleTemplate?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  meta,
  container = true,
  excludeAppCta,
  excludeBanners,
  excludeMessage,
  excludeMobileSearch,
  excludeNavigation,
  simpleFooter,
  title,
  titleTemplate,
}) => {
  const chromeless = useChromelessContext();
  const mobile = useMobile();

  let footer: React.ReactNode = null;

  if (!chromeless.isChromeless) {
    if (simpleFooter) {
      footer = <SimpleFooter />;
    } else {
      footer = <Footer excludeAppCta={excludeAppCta} mobile={mobile} />;
    }
  }

  return (
    <>
      <Head>
        <title>{generatePageTitle(title, titleTemplate)}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=5, user-scalable=yes"
        />

        <meta name="description" content={meta?.description || description} />
        <meta
          name="og:description"
          content={meta?.description || description}
        />
      </Head>
      <DefaultCanonical canonical={meta?.canonical} />

      {!chromeless.isChromeless && (
        <Header
          excludeNavigation={excludeNavigation}
          excludeBanners={excludeBanners}
          excludeMobileSearch={excludeMobileSearch}
        />
      )}

      <ErrorBoundary
        fallback={
          <>
            <h1 className="my-8 text-center">Something went wrong...</h1>
            <ErrorPageImage />
          </>
        }
      >
        {!chromeless.isChromeless && <MicroBanner />}
        <main className={clsx(className, { container })}>
          {!excludeMessage && <PageMessage />}
          {children}
        </main>
      </ErrorBoundary>

      {footer}
    </>
  );
};

export default Layout;
