import { useMobile } from 'common-nextjs';
import React from 'react';
import { displayPrice, thousandsSeparator } from 'utils';
import Image from '~/components/Image';
import ShopLink from '~/components/Links/ShopLink';
import MarkdownContent from '~/components/MarkdownContent';
import SeeMoreVerticalTextExpander from '~/components/SeeMoreVerticalTextExpander';
import { RailsItemModel } from '~/typings/services/rails/item';

interface Props {
  model: RailsItemModel;
}

const SimpleModelTile: React.FC<Props> = ({ model }) => {
  const mobile = useMobile();
  const description = model.description && (
    <SeeMoreVerticalTextExpander clampClassName="line-clamp-3">
      <MarkdownContent source={model.description} />
    </SeeMoreVerticalTextExpander>
  );
  return (
    <article className="w-full rounded border px-4 py-3 shadow md:px-6 md:py-5">
      <div className="flex space-x-3 md:space-x-6">
        <ShopLink path={model.url} className="relative">
          <Image
            alt={`Image of ${model.name}`}
            src={model.primary_image?.thumb_url}
            className="h-20 w-20 rounded object-cover lg:h-40 lg:w-40"
          />

          {model.position && (
            <div className="bg-darkgrey-900 absolute left-0 top-0 rounded-br rounded-tl px-2 py-1 text-sm font-semibold text-white md:px-3 md:py-2">
              #{model.position}
            </div>
          )}
        </ShopLink>

        <div className="flex-1">
          <div className="mb-2">
            <ShopLink path={model.url} className="text-lg md:text-2xl">
              {model.display_name}
            </ShopLink>
          </div>

          <div className="divide-grey-500 mb-4 flex divide-x">
            {model.price_statistics && (
              <div className="pr-2">
                From:{' '}
                <span className="font-semibold">
                  {displayPrice(
                    model.price_statistics.used_p30 ||
                      model.price_statistics.new_p30 ||
                      model.price_min!,
                    false,
                  )}
                  {' - '}
                  {displayPrice(
                    model.price_statistics.used_p70 ||
                      model.price_statistics.new_p70 ||
                      model.price_max!,
                    false,
                  )}
                </span>
              </div>
            )}

            {!!model.available_count && (
              <div className="px-2">
                {thousandsSeparator(model.available_count)} available
              </div>
            )}

            {!!model.position && (
              <div className="px-2">
                #{model.position} in {model.category_name}
              </div>
            )}
          </div>

          {!mobile && description}
        </div>
      </div>

      {mobile && <div className="mb-4 mt-2">{description}</div>}
    </article>
  );
};

export default SimpleModelTile;
