import { useCallback, useRef } from 'react';

/**
 * When calling the returned function, `fn` will be called after `timeoutMs`
 * @param fn
 * @param timeoutMs
 */
export function useDelayFn<TArgs extends any[]>(
  fn: (...args: TArgs) => void,
  timeoutMs: number,
) {
  const timeoutRef = useRef<any>(null);
  return [
    useCallback(
      (...args: TArgs) => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(fn, timeoutMs, ...args);
      },
      [fn, timeoutMs],
    ),
    useCallback(() => clearTimeout(timeoutRef.current), [timeoutRef]),
  ] as const;
}
