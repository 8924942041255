import React, { useRef } from 'react';
import { ERROR_IMAGES, newRandomImage } from '~/constants/imagesAndVideos';

interface Props {
  initialImage?: number;
}

const ErrorPageImage: React.FC<Props> = ({ initialImage }) => {
  const image = useRef(initialImage || newRandomImage());
  return (
    <video
      autoPlay
      muted
      loop
      className="mx-auto mt-8"
      src={ERROR_IMAGES[image.current]}
    />
  );
};

export default ErrorPageImage;
