import React from 'react';
import { LegacyButtonLink } from 'ui';
import Image from '~/components/Image';
import useTileVersions from '~/hooks/useTileVersions';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const BrandCollectionBlock: React.FC<Props> = ({ block }) => {
  const tileVersions = useTileVersions(block);

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {tileVersions?.map(tile => (
          <a
            key={tile.id}
            href={tile.destination}
            className="flex h-24 items-center justify-center rounded border px-4"
          >
            {tile.background_image_url ? (
              <Image
                src={tile.background_image_url}
                alt={tile.title}
                className="object-contain"
              />
            ) : (
              <span className="text-lg font-semibold">{tile.title}</span>
            )}
          </a>
        ))}
      </div>

      <div className="mt-6 flex justify-center">
        <LegacyButtonLink
          as={<a className="w-full md:w-auto" href={block.destination} />}
          variant="outlined"
          className="w-full md:w-auto"
        >
          {block.cta || 'See More Brands'}
        </LegacyButtonLink>
      </div>
    </>
  );
};

export default BrandCollectionBlock;
