'use client';

import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fill?: string;
};

// TODO: This chevron is out of date and utilized incorrectly.
const ChevronRight: React.FC<Props> = ({
  className,
  fill,
  height,
  width,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 ${className}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke={fill || 'currentColor'}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  </svg>
);

export default ChevronRight;
