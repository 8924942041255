import { useQuery } from '@tanstack/react-query';
import { fetchCategories } from '~/api/categories';

export default function useCategoriesContext() {
  const { data } = useQuery(['categories'], fetchCategories, {
    staleTime: 600000,
    cacheTime: 600000,
  });
  return data;
}
