import { useQuery } from '@tanstack/react-query';
import { LegacyButtonLink } from 'ui';
import { pluralize, thousandsSeparator } from 'utils';
import { fetchUserFeed } from '~/api/tiles';
import { Avatar } from '~/components/Avatar';
import FeatheredOverflowContainer from '~/components/FeatheredOverflowContainer';
import LockerLink from '~/components/Links/LockerLink';
import FollowButton from '~/components/NewLockers/FollowButton';
import BlockSection from '~/components/legos/BlockSection';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { RailsBlock } from '~/typings/services/rails/tile';

export default function UserFeedBlock({
  block,
  headerType,
}: {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}) {
  const { category } = useFacetFiltering();
  const categoryId = block.category?.id || category?.id;
  const { data: users } = useQuery(['user-feed', block.id], () =>
    fetchUserFeed(categoryId),
  );

  return (
    <BlockSection block={block} headerType={headerType}>
      <FeatheredOverflowContainer scrollButton className="flex gap-4">
        {users?.map(user => (
          <div
            key={user.id}
            className="flex w-[316px] flex-shrink-0 gap-4 rounded px-4 py-2.5 shadow-md"
          >
            <Avatar
              avatarUrl={user.avatar_url}
              alt={user.username}
              className="h-20 w-20"
            />

            <div className="flex-1">
              <div className="flex">
                <LockerLink
                  username={user.username}
                  className="flex-1 text-lg font-semibold"
                >
                  {user.username}
                </LockerLink>
                <FollowButton following={user.following} userId={user.id}>
                  {(handleFollow, following) => (
                    <button type="button" onClick={handleFollow}>
                      <FavoriteIcon active={following} />
                    </button>
                  )}
                </FollowButton>
              </div>

              <div className="mb-2 text-sm">
                {user.feedback.count > 0 ? (
                  <>
                    <b>{Math.round(user.feedback.score || 0)}</b>% Positive
                    Feedback ({thousandsSeparator(user.feedback.count)})
                  </>
                ) : (
                  'No Feedback'
                )}
              </div>

              <LegacyButtonLink
                as={<LockerLink username={user.username} />}
                variant="outlined"
                size="small"
              >
                Shop {thousandsSeparator(user.available_count)}{' '}
                {pluralize(user.available_count, 'Item', 'Items')}
              </LegacyButtonLink>
            </div>
          </div>
        ))}
      </FeatheredOverflowContainer>
    </BlockSection>
  );
}
