import deepmerge from 'deepmerge';

const combineMerge = (target: any, source: any, options: any) => {
  const destination = target.slice();

  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      destination[index] = item;
    } else if (options.isMergeableObject(item)) {
      destination[index] = deepmerge(target[index], item, options);
    } else if (target.indexOf(item) === -1) {
      destination.push(item);
    }
  });
  return destination;
};

export default function deepmergeWithArrays(
  objects: (undefined | Record<string, unknown>)[],
  options?: deepmerge.Options,
) {
  return deepmerge.all(
    objects.filter(o => o != null) as Record<string, unknown>[],
    { ...options, arrayMerge: combineMerge },
  );
}
