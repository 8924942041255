import { clsx } from 'clsx';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
  className?: string;
  source: string;
}

const MarkdownContent: React.FC<Props> = ({ className, source }) => (
  <ReactMarkdown
    renderers={{
      table: ({ children }) => (
        <div className="table-container">
          <table>{children}</table>
        </div>
      ),
    }}
    className={clsx('markdown-container', className)}
    linkTarget="_blank"
    source={source}
    escapeHtml={false}
  />
);

export default MarkdownContent;
