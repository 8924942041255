import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { fetchBadgeCount } from '~/api/swaps';
import CartButton from '~/components/Chrome/Header/CartButton';
import UserSectionBase from '~/components/Chrome/Header/UserSectionBase';
import LoginLink from '~/components/Links/LoginLink';
import MyFeedLink from '~/components/Links/MyFeedLink';
import RegisterLink from '~/components/Links/RegisterLink';
import SellNowLink from '~/components/Links/SellNowLink';
import SwapsInboxLink from '~/components/Links/SwapsInboxLink';
import SwapsInboxPopout from '~/components/SwapPopout/SwapsInboxPopout';
import { useSession } from '~/contexts/SessionContext';
import { useSwapPopout } from '~/contexts/SwapPopoutContext';
import useHasFeature from '~/hooks/useHasFeature';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { SwapsIcon } from '~/public/static/svg/ItemActionIcons';
import AvatarSection from './AvatarSection';

function MySwapsLink() {
  const { loggedIn } = useSession();
  const { data } = useQuery(['badge-count'], fetchBadgeCount, {
    enabled: loggedIn,
  });
  const [mySwapsPopoutOpen, setMySwapsPopoutOpen] = useState(false);

  const hasQuickInboxFeature = useHasFeature('quick_inbox');
  const { enabled } = useSwapPopout();

  const cx =
    'flex flex-col items-center transition-colors duration-200 hover:text-green-500';

  const children = (
    <>
      <span className="relative block">
        <SwapsIcon fill="currentColor" />
        {data?.badge! > 0 && (
          <div
            className="bg-primary-500 absolute rounded px-1 text-xs text-white"
            style={{ top: -4, right: -8 }}
          >
            {data?.badge}
          </div>
        )}
      </span>
      <span className="block text-center text-xs">Inbox</span>
    </>
  );

  const MY_SWAPS_POPOUT_ENABLED = hasQuickInboxFeature && enabled;

  let container: React.ReactNode;
  if (MY_SWAPS_POPOUT_ENABLED) {
    container = (
      <button
        type="button"
        className={cx}
        onClick={() => setMySwapsPopoutOpen(true)}
      >
        {children}
      </button>
    );
  } else {
    container = <SwapsInboxLink className={cx}>{children}</SwapsInboxLink>;
  }

  return (
    <>
      {container}

      <SwapsInboxPopout
        open={mySwapsPopoutOpen}
        onClose={() => setMySwapsPopoutOpen(false)}
      />
    </>
  );
}

const UserSection: React.FC = () => {
  const { loggedIn } = useSession();

  const sell = (
    <SellNowLink className="border-turf-green-500 rounded border-2 px-4 py-1.5 font-semibold transition-colors duration-200 hover:border-green-500 hover:text-green-500">
      Sell
    </SellNowLink>
  );

  if (loggedIn) {
    // LOGGED IN
    return (
      <UserSectionBase>
        {sell}

        <MyFeedLink className="flex flex-col items-center transition-colors duration-200 hover:text-green-500">
          <FavoriteIcon fill="currentColor" />
          <div className="mt-1 text-center text-xs">Favorites</div>
        </MyFeedLink>

        <MySwapsLink />

        <CartButton showLabel />

        <AvatarSection />
      </UserSectionBase>
    );
  }

  return (
    <UserSectionBase>
      {sell}

      <CartButton showLabel />

      <LoginLink className="transition-colors duration-200 hover:text-green-500">
        Sign In
      </LoginLink>

      <RegisterLink className="transition-colors duration-200 hover:text-green-500">
        Join
      </RegisterLink>
    </UserSectionBase>
  );
};

export default UserSection;
