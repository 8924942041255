import { useQuery } from '@tanstack/react-query';
import { fetchFacetUserMeta } from '~/api/items';
import { RailsFlattenedFacetQuery } from '~/typings/services/rails/facets';
import { RailsThumbnailItem } from '~/typings/services/rails/item';
import { minutes5inMS } from '../constants/times';

export default function useItemGridMeta(
  items?: RailsThumbnailItem[],
  additionalParams?: RailsFlattenedFacetQuery,
  context: 'locker' | 'results' = 'results',
) {
  const ids = items?.map(i => i.id);
  useQuery(
    ['item-meta', ids, additionalParams, context],
    () => fetchFacetUserMeta(ids!, additionalParams, context),
    {
      enabled: !!ids,
      staleTime: minutes5inMS,
    },
  );
}
