import { useQuery } from '@tanstack/react-query';
import { useMobile } from 'common-nextjs';
import React from 'react';
import { fetchFacetItemsNoMeta } from '~/api/items';
import ItemThumbnailGrid from '~/components/ItemGrids/ItemThumbnailGrid';
import ItemThumbnail from '~/components/ItemThumbnail/ItemThumbnail';
import useItemGridMeta from '~/hooks/useItemGridMeta';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  rows: number;
}

const ItemGridRowBlock: React.FC<Props> = ({ block, rows }) => {
  const mobile = useMobile();
  const { data: items } = useQuery(
    ['item-block', block.id, rows],
    () =>
      fetchFacetItemsNoMeta({
        ...block.query,
        page_size: mobile ? rows * 4 : rows * 6,
      }),
    {
      refetchOnMount: false,
    },
  );
  useItemGridMeta(items);

  return (
    <div className="pb-0.5 pl-0.5">
      <ItemThumbnailGrid>
        {items?.map((item, i) => (
          <ItemThumbnail
            key={item.id}
            item={item}
            // When this block is one of the first few rendered, send position to load the image eagerly
            // to optimize the LCP web-vital
            position={block.id! < 3 ? i : undefined}
            preloadImage={i < 2}
          />
        ))}
      </ItemThumbnailGrid>
    </div>
  );
};

export default ItemGridRowBlock;
