import React from 'react';

interface NewModalContextValue {
  open: boolean;
  onClose: () => void;
}

export const NewModalContext = React.createContext<NewModalContextValue>(null!);

export const useNewModalContext = () => React.useContext(NewModalContext);
