import React from 'react';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2';
}

const TextTitleBlock: React.FC<Props> = ({
  block,
  headerType: Header = 'h2',
}) => (
  <div className="container my-6 text-center font-semibold md:my-8">
    <Header className="text-2xl">{block.title}</Header>

    {block.description && (
      <div className="mt-4 text-lg">{block.description}</div>
    )}
  </div>
);

export default TextTitleBlock;
