import React, { useEffect, useReducer } from 'react';
import {
  FacetFilteringContextProps,
  IFacetFilteringContext,
} from '~/contexts/facetFiltering/intf';
import { reducer } from '~/contexts/facetFiltering/reducer';
import flattenFilterState from '~/contexts/facetFiltering/flattenFilterState';

export const FacetFilteringContext =
  React.createContext<IFacetFilteringContext>({
    state: {
      facets: [],
      filterState: {},
      type: 'browse',
    },
    dispatch: action => {},
  });

export const FacetFilteringProvider: React.FC<
  React.PropsWithChildren<FacetFilteringContextProps>
> = ({
  children,
  facets,
  filterState,
  inModal,
  lander,
  notFoundMetadata,
  paging,
  similarToItem,
  sorts,
  type,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    facets,
    filterState: flattenFilterState(filterState),
    inModal,
    lander,
    notFoundMetadata,
    paging,
    similarToItem,
    sorts,
    type,
  });

  useEffect(() => {
    dispatch({
      type: 'EXTERNAL_DATA_CHANGE',
      payload: {
        facets,
        filterState: flattenFilterState(filterState),
        inModal,
        lander,
        notFoundMetadata,
        paging,
        similarToItem,
        sorts,
        type,
      },
    });
  }, [
    dispatch,
    lander,
    inModal,
    notFoundMetadata,
    facets,
    filterState,
    paging,
    type,
    similarToItem,
    sorts,
  ]);

  return (
    <FacetFilteringContext.Provider value={{ state, dispatch }}>
      {children}
    </FacetFilteringContext.Provider>
  );
};
