import { useMobile } from 'common-nextjs';
import {
  RailsBlock,
  RailsTile,
  RailsTileVersion,
} from '~/typings/services/rails/tile';

export function getTileVersion(
  block: RailsBlock,
  mobile: boolean,
  index = 0,
): RailsTile & Partial<RailsTileVersion> {
  const blockLayoutVersion = block.tiles?.[index]?.versions?.find(
    v => v.block_layout === block.layout,
  );

  if (blockLayoutVersion) {
    return {
      ...block.tiles?.[index]!,
      ...blockLayoutVersion,
    };
  }

  const platformVersion = block.tiles?.[index]?.versions?.find(
    v => v.platform === (mobile ? 'mobile' : 'web'),
  );

  return {
    ...block.tiles?.[index]!,
    ...platformVersion,
  };
}

export default function useTileVersion(
  block: RailsBlock,
  index = 0,
): RailsTile & Partial<RailsTileVersion> {
  const mobile = useMobile();

  return getTileVersion(block, mobile, index);
}
