'use client';

import { clsx } from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import VerticalFeather from '~/components/VerticalFeather';

interface Props {
  // Should be a Tailwind line clamp class name, like "line-clamp-3"
  clampClassName: string;
  children: (toggle: () => void, expanded: boolean) => React.ReactNode;
  defaultExpanded?: boolean;
  toggler?: (toggle: () => void, expanded: boolean) => React.ReactNode;
}

const FeatheredVerticalTextBasedExpander: React.FC<Props> = ({
  children,
  clampClassName,
  defaultExpanded,
  toggler,
}) => {
  const [expanded, setExpanded] = useState(!!defaultExpanded);
  const [shouldBeExpandable, setShouldBeExpandable] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      setShouldBeExpandable(
        containerRef.current.scrollHeight > containerRef.current.offsetHeight,
      );
    }
  }, []);

  return (
    <div className="relative">
      <div
        className={clsx(expanded && shouldBeExpandable ? '' : clampClassName)}
        ref={containerRef}
      >
        {children(() => setExpanded(!expanded), expanded)}

        {shouldBeExpandable && !expanded && <VerticalFeather />}
      </div>
      {shouldBeExpandable && toggler?.(() => setExpanded(!expanded), expanded)}
    </div>
  );
};

export default FeatheredVerticalTextBasedExpander;
