import React from 'react';

interface Props {}

const SimpleFooter: React.FC<Props> = () => (
  <footer className="mt-12 border-t py-16">
    <div className="text-slate-green-500 text-center">SidelineSwap</div>
  </footer>
);

export default SimpleFooter;
