import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props extends React.HTMLAttributes<HTMLElement> {
  cta?: React.ReactNode;
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
  showCta?: boolean;
}

const BlockSection: React.FC<Props> = ({
  cta,
  block,
  children,
  headerType: Header = 'h3',
  showCta = true,
  ...props
}) => (
  <section className="container" id={`${block.layout}-${block.id}`} {...props}>
    {block.title && (
      <div className="mb-2 flex items-baseline gap-4">
        <Header className="inline-flex text-lg">{block.title}</Header>
        {showCta &&
          (cta
            ? cta
            : block.destination && (
                <a
                  href={block.destination}
                  className="text-primary-500 flex items-center gap-2 text-sm font-semibold"
                >
                  {block.cta || 'See all'}{' '}
                  <ArrowRightIcon className="h-4 w-4" />
                </a>
              ))}
      </div>
    )}

    {children}
  </section>
);

export default BlockSection;
