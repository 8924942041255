import Link from 'next/link';
import React from 'react';
import { BreadcrumbList, WithContext } from 'schema-dts';

export type Breadcrumb =
  | {
      label: string;

      // For hard links
      href: string;
    }
  | {
      label: string;

      // For guaranteed internal links using the NextJS router
      link: string;
    };

export default function GenericBreadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const breadcrumbsSchema: WithContext<BreadcrumbList> | undefined = breadcrumbs
    ? {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((segment, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          name: segment.label,
          item: 'link' in segment ? segment.link : segment.href,
        })),
      }
    : undefined;

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(breadcrumbsSchema),
        }}
      />

      <ol className="text-slate-green-500 flex items-center space-x-2 text-sm">
        {breadcrumbs.map((breadcrumb, i) => {
          const Element = 'href' in breadcrumb ? 'a' : Link;
          const destination =
            'href' in breadcrumb ? breadcrumb.href : breadcrumb.link;
          const isLast = i === breadcrumbs.length - 1;

          return (
            <React.Fragment key={destination}>
              <li>
                <Element
                  className={isLast ? 'font-semibold text-black' : ''}
                  href={destination}
                >
                  {breadcrumb.label}
                </Element>
              </li>

              {i < breadcrumbs.length - 1 && <li aria-hidden>/</li>}
            </React.Fragment>
          );
        })}
      </ol>
    </>
  );
}
