import React from 'react';
import BlockSection from '~/components/legos/BlockSection';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}

const TextPillsBlock: React.FC<Props> = ({ block, headerType }) => {
  if (!block.tiles?.length) {
    return null;
  }

  return (
    <BlockSection block={block} headerType={headerType}>
      <div className="-mb-2 flex flex-wrap">
        {block.tiles.map(tile => {
          if (tile.destination) {
            return (
              <a
                className="bg-mint-green-300 mb-2 mr-2 rounded px-4 py-2 text-sm font-semibold"
                key={tile.id}
                // @ts-ignore
                href={tile.destination}
              >
                {tile.name}
              </a>
            );
          } else {
            return (
              <span
                key={tile.id}
                className="bg-mint-green-300 mb-2 mr-2 rounded px-4 py-2 text-sm font-semibold"
              >
                {tile.name}
              </span>
            );
          }
        })}
      </div>
    </BlockSection>
  );
};

export default TextPillsBlock;
