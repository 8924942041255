import React from 'react';

export default function BaseHeader({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="w-full bg-white shadow-lg print:hidden" id="header">
      {children}
    </div>
  );
}
