import { RailsBlock, RailsTile } from '~/typings/services/rails/tile';

export default function processBlockTiles(
  block: RailsBlock,
): RailsTile[] | undefined {
  // Defaulted to true, but the backend doesn't always pass it correctly
  if (block.sorted == false) {
    return block.tiles;
  } else {
    return block.tiles?.sort((a, b) => a.title.localeCompare(b.title));
  }
}
