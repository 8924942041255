import React from 'react';
import { Button } from 'ui';
import useTileVersion from '~/hooks/useTileVersion';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const BlockButton: React.FC<Props> = ({ block }) => {
  const tileVersion = useTileVersion(block);

  if (tileVersion.destination) {
    return (
      <a href={tileVersion.destination}>
        <Button
          className="mt-4"
          style={{
            backgroundColor: tileVersion?.button_color || '#02c874',
            borderColor: tileVersion?.button_color || '#02c874',
          }}
        >
          {tileVersion.button_text || tileVersion.title}
        </Button>
      </a>
    );
  }

  return null;
};

export default BlockButton;
