'use client';

import { clsx } from 'clsx';
import React from 'react';

type Props = {
  className?: string;
  height?: number;
  width?: number;
};

const ReferralDollar: React.FC<Props> = ({
  className,
  height,
  width,
  ...props
}) => (
  <svg
    width={width || 16}
    height={height || 16}
    {...props}
    viewBox="0 0 16 16"
    className={clsx('dollar', className)}
  >
    <g fill="none">
      <path
        fill="#FE9923"
        d="M8.031 0C3.634 0 0 3.572 0 7.969S3.634 16 8.031 16 16 12.366 16 7.969 12.428 0 8.031 0z"
      />
      <path
        fill="#FE9923"
        d="M16 7.969C16 12.366 12.428 16 8.031 16V0C12.428 0 16 3.572 16 7.969z"
      />
      <path
        fill="#FFF"
        d="M8.969 7.5H8.5V5.625h.469c.262 0 .469.206.469.469 0 .618.937.618.937 0 0-.778-.628-1.407-1.406-1.407H8.5V4.22c0-.31-.234-.469-.469-.469-.234 0-.469.16-.469.469v.468h-.53c-.779 0-1.407.629-1.407 1.407v.937c0 .778.628 1.407 1.406 1.407h.532v1.937H7.03c-.262 0-.468-.206-.468-.469 0-.618-.938-.618-.938 0 0 .778.628 1.406 1.406 1.406h.532v.47c0 .309.234.468.468.468.235 0 .469-.16.469-.469v-.469h.469c.778 0 1.406-.628 1.406-1.406v-1c0-.778-.628-1.406-1.406-1.406zm-1.406 0H7.03c-.262 0-.468-.206-.468-.469v-.937c0-.263.206-.469.468-.469h.532V7.5zm1.875 2.406c0 .263-.207.469-.47.469H8.5V8.437h.469c.262 0 .469.207.469.47v1z"
      />
    </g>
  </svg>
);

export default ReferralDollar;
