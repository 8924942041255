import Cookies from 'js-cookie';
import { qsUpdateString } from 'utils';

export default function getCookieDestinationUrl() {
  const destination = Cookies.get('destination');

  if (!destination) {
    return null;
  }

  const decoded = decodeURIComponent(destination);
  const split = decoded.split('?');

  const newQuery = qsUpdateString([split[1]]);

  return `${split[0]}${newQuery}`;
}
