import { clsx } from 'clsx';
import React from 'react';

type Props = React.HTMLProps<HTMLDivElement>;

const BaseBanner: React.FC<Props> = ({ children, className, ...props }) => (
  <div
    className={clsx(
      'flex h-12 items-center space-x-2 px-4 py-2 md:space-x-4',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

export default BaseBanner;
