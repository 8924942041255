import { fetcher, getBaseUrl, makeFetcher } from 'fetcher-session';
import { RailsFlattenedFacetQuery } from '~/typings/services/rails/facets';
import { RailsItem, RailsItemModel } from '~/typings/services/rails/item';
import { RailsFacetResponse } from '~/typings/services/rails/responses';

export const fetchPopularModels = makeFetcher<RailsItemModel[]>(
  (categoryId?: number, count = 6) => [
    '/v1/models',
    {
      params: {
        category_id: categoryId,
        context: 'shop',
        page_size: count,
        sort: 'popular_desc',
      },
    },
  ],
);

export const fetchModelsInFacets = makeFetcher<RailsFacetResponse>(
  (query: RailsFlattenedFacetQuery) => [
    '/v2/facet_items',
    {
      baseUrl: getBaseUrl('edge'),
      dataOnly: false,
      params: {
        ...query,
        include_data: 0,
        include_model: 1,
      },
    },
  ],
);

export const fetchRelatedModels = makeFetcher<RailsItemModel[], [RailsItem]>(
  item => [
    '/v1/models',
    {
      params: {
        category_id: item.categories[0].id,
        context: 'shop',
        page_size: 7,
        sort: 'popular_desc',
      },
    },
  ],
);

export const fetchModelById = (id: number | string) =>
  fetcher<RailsItemModel>(`/v1/models/${id}`);

/**
 * @param ids Comma concatenated model IDs
 */
export const fetchModelsByIds = (ids: string) =>
  fetcher<RailsItemModel[]>('/v1/models', {
    params: {
      context: 'shop',
      include_ids: ids,
    },
  });

export const fetchPopularModelsByCategoryAndBrand = (
  categoryId?: number | string,
  brandId?: number | string,
  pageSize?: number | string,
) =>
  fetcher<RailsItemModel[]>('/v1/models', {
    params: {
      brand_id: brandId || null,
      category_id: categoryId || null,
      context: 'shop',
      page_size: pageSize,
      sort: 'popular_desc',
    },
  });

export const fetchModelsByPosition = (
  categoryId?: number | string,
  brandId?: number | string,
  pageSize?: number | string,
) =>
  fetcher<RailsItemModel[]>('/v1/models', {
    params: {
      brand_id: brandId,
      category_id: categoryId,
      context: 'shop',
      page_size: pageSize,
      sort: 'position_asc',
    },
    baseUrl: getBaseUrl('edge'),
  });
