import { Reducer } from 'react';
import { FacetFilteringContextState } from '~/contexts/facetFiltering/intf';
import {
  BaseFacet,
  FilterState,
  FlattenedFilterState,
} from '~/typings/services/rails/facets';
import { RailsPaging } from '~/typings/services/rails/paging';
import flattenFilterState from '~/contexts/facetFiltering/flattenFilterState';

interface Action<T extends string, P extends any> {
  type: T;
  payload: P;
}

export type FacetFilteringActions =
  | Action<
      'EXTERNAL_DATA_CHANGE',
      FacetFilteringContextState & {
        filterState: FilterState | FlattenedFilterState;
      }
    >
  | Action<'UPDATED_FILTER_STATE', { filterState: FlattenedFilterState }>
  | Action<
      'UPDATED_FACETS',
      {
        facets: BaseFacet[];
        paging: RailsPaging;
      }
    >
  | Action<'CLEAR_ALL', undefined | { filterState: FlattenedFilterState }>;

export type FacetFilteringReducer = Reducer<
  FacetFilteringContextState,
  FacetFilteringActions
>;

export const reducer: FacetFilteringReducer = (state, action) => {
  switch (action.type) {
    case 'EXTERNAL_DATA_CHANGE':
      return {
        ...state,
        ...action.payload,
        filterState: flattenFilterState(action.payload.filterState),
      };

    case 'UPDATED_FILTER_STATE':
      return {
        ...state,
        filterState: flattenFilterState(action.payload.filterState),
      };

    case 'CLEAR_ALL':
      return {
        ...state,
        filterState: flattenFilterState(action.payload?.filterState),
      };

    case 'UPDATED_FACETS':
      return {
        ...state,
        facets: action.payload.facets,
        paging: action.payload.paging,
      };

    default:
      return state;
  }
};
