import { useMobile } from 'common-nextjs';
import React from 'react';
import CsvImportReminderBanner from '~/components/Chrome/Banners/CsvImportReminderBanner';
import RestrictedBanner from '~/components/Chrome/Banners/RestrictedBanner';
import SaleBanner from '~/components/Chrome/Banners/SaleBanner';
import SmartAppBanner from '~/components/Chrome/Banners/SmartAppBanner';
import VacationModeBanner from '~/components/Chrome/Banners/VacationModeBanner';
import { useSale } from '~/contexts/SaleContext';
import useActiveVacation from '~/hooks/useActiveVacation';
import useHasFeature from '~/hooks/useHasFeature';
import useUserConfiguration from '~/hooks/useUserConfiguration';
import { useV2Session } from '~/hooks/useV1Session';

const BannerCollection: React.FC = () => {
  const sales = useSale();
  const mobile = useMobile();

  const { data: activeVacation } = useActiveVacation();
  const { data: v2Session } = useV2Session();
  const { data: userConfiguration } = useUserConfiguration();
  const hasImportItems = useHasFeature('import_items');

  if (v2Session?.state === 'restricted') {
    return <RestrictedBanner />;
  }

  if (activeVacation) {
    return (
      <>
        {mobile && <SmartAppBanner />}
        <VacationModeBanner vacation={activeVacation} />
      </>
    );
  }

  if (hasImportItems) {
    return (
      <>
        {mobile && <SmartAppBanner />}
        <CsvImportReminderBanner
          lastImportTime={userConfiguration?.properties.last_imported_at}
        />
      </>
    );
  }

  if (sales.showBanner) {
    return (
      <>
        {mobile && <SmartAppBanner />}
        <SaleBanner />
      </>
    );
  }

  if (mobile) {
    return <SmartAppBanner />;
  }

  return null;
};

export default BannerCollection;
