import React, { useEffect, useRef, useState } from 'react';

/**
 * Optimization opportunity:
 * * Change children to a Node or a function to pass "expanded"
 *   and lazy load children. This could save some unnecessary
 *   round trips unless the section was expanded
 */

interface Props {
  className?: string;
  defaultExpanded?: boolean;
  id?: string;
  onChange?: (expanded: boolean) => void;
  trigger: (
    handleOpen: () => void,
    open: boolean,
    handleClose: () => void,
  ) => React.ReactNode | React.ReactNode[];
}

const Expander: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  defaultExpanded = false,
  id,
  onChange,
  trigger,
}) => {
  const childRef = useRef<HTMLDivElement>(null);
  const observer = useRef<MutationObserver>(null);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    setHeight(childRef.current?.scrollHeight!);
  }, [childRef.current, children]);

  useEffect(() => {
    if (childRef.current) {
      // @ts-ignore
      observer.current = new MutationObserver(() => {
        setHeight(childRef.current?.scrollHeight!);
      });

      observer.current.observe(childRef.current, {
        subtree: true,
        attributes: true,
        childList: true,
      });
    }
  }, []);

  return (
    <section aria-expanded={expanded}>
      {trigger(
        () => {
          setExpanded(true);
          onChange?.(true);
        },
        expanded,
        () => {
          setExpanded(false);
          onChange?.(false);
        },
      )}

      <div
        ref={childRef}
        id={id}
        aria-hidden={!expanded}
        className={expanded ? className : ''}
        style={{
          overflow: 'hidden',
          maxHeight: expanded ? height : 0,
          transition: 'max-height 0.3s',
        }}
        onTransitionEnd={() => setHeight(childRef.current?.scrollHeight!)}
      >
        {children}
      </div>
    </section>
  );
};

export default Expander;
