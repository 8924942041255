import { clsx } from 'clsx';
import React from 'react';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import ArrowRightIcon from '~/public/static/svg/ArrowRightIcon';

interface Props {
  className?: any;
  image?: string;
  onClick?: React.MouseEventHandler;
  title: string;
}

const UnlimitedTileBase: React.FC<Props> = ({
  className,
  image,
  onClick,
  title,
}) => (
  <button
    className={clsx(
      'border-grey-200 w-full rounded border p-2 shadow transition-shadow duration-300 hover:shadow-md md:flex md:items-center md:px-4',
      className,
    )}
    onClick={onClick}
  >
    {image ? (
      <TileImageOrVideo
        alt=""
        src={image}
        className="mx-auto block h-16 w-16 rounded-full object-cover md:ml-0 md:mr-4 md:h-6 md:w-6"
      />
    ) : (
      <div className="h-16 w-16 md:ml-0 md:mr-4 md:h-6 md:w-6" />
    )}

    <div className="mt-2 block text-center text-sm font-semibold md:mt-0 md:text-left">
      {title}
    </div>

    <div className="md:mr-auto" />

    <ArrowRightIcon className="hidden md:block" />
  </button>
);

export default UnlimitedTileBase;
