'use client';

import React from 'react';
import FeatheredVerticalTextBasedExpander from '~/components/FeatheredVerticalTextBasedExpander';

interface Props {
  clampClassName: string;
  seeMoreButtonClassName?: string;
}

const SeeMoreVerticalTextExpander: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  clampClassName,
  seeMoreButtonClassName,
}) => (
  <FeatheredVerticalTextBasedExpander
    clampClassName={clampClassName}
    toggler={(toggle, expanded) =>
      !expanded && (
        <div className="absolute -bottom-4 flex w-full justify-center">
          <button
            className={
              seeMoreButtonClassName ||
              '-mx-4 px-4 text-sm font-semibold text-blue-500'
            }
            onClick={toggle}
          >
            See More
          </button>
        </div>
      )
    }
  >
    {() => children}
  </FeatheredVerticalTextBasedExpander>
);

export default SeeMoreVerticalTextExpander;
