import { useMutation } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { getEnv } from 'common-nextjs';
import React, { useEffect, useState } from 'react';
import FacebookButton, {
  FacebookButtonProps,
} from '~/components/LoginRegister/FacebookButton';
import useScript from '~/hooks/useScript';
import useAuthService from '~/lib/session/useAuthService';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { initiatedSocialAuth } from '~/services/analytics/events/auth';
import { SocialAuthCallback } from '~/typings/services/iam/auth';

const SCOPES = 'email,public_profile';

function isConnected(response: fb.StatusResponse) {
  return !!(response.status === 'connected' && response.authResponse);
}

interface Props extends FacebookButtonProps {
  className?: string;
  mode?: 'link' | 'auth';
  onSocialAuthNewUser?: SocialAuthCallback;
  onSocialAuthExistingEmail?: SocialAuthCallback;
  onSuccessfulLink?: () => void;
  onSuccessfulLogin?: () => void;
  src?: AnalyticsSource;
  text?: string;
}

const FacebookLoginButton: React.FC<Props> = ({
  className,
  mode = 'auth',
  onSocialAuthNewUser,
  onSocialAuthExistingEmail,
  onSuccessfulLink,
  onSuccessfulLogin,
  src,
  text,
  ...props
}) => {
  const [scriptLoaded] = useScript(
    'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2',
  );
  const { trySocialAuth, linkSocialAccount } = useAuthService();
  const [fbInitialized, setFbInitialized] = useState(false);
  const [fbInitErrored, setFbInitErrored] = useState(false);

  const { isLoading, mutate: startSocialAuth } = useMutation(
    (facebookToken: string): any => {
      if (mode === 'auth') {
        return trySocialAuth('Facebook', {
          onSocialAuthNewUser,
          onSocialAuthExistingEmail,
          onSuccessfulLogin,
          token: facebookToken,
        });
      } else if (mode === 'link') {
        return linkSocialAccount('Facebook', {
          onSuccessfulLink,
          token: facebookToken,
        });
      }
    },
  );

  function initializeFacebookSDK() {
    if (!fbInitialized) {
      try {
        window.FB?.init({
          appId: getEnv('FB_SDK_KEY')!,
          // status: true, // Don't request status here
          xfbml: false,
          version: 'v3.2',
        });
        setFbInitErrored(false);
        setFbInitialized(true);
      } catch (e) {
        setFbInitErrored(true);
        setFbInitialized(false);
      }
    }
  }

  useEffect(() => {
    if (scriptLoaded) {
      initializeFacebookSDK();
    }
  }, [scriptLoaded]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();

    if (fbInitErrored) {
      initializeFacebookSDK();
    }

    initiatedSocialAuth('facebook', src);

    window.FB?.getLoginStatus(response => {
      if (isConnected(response)) {
        startSocialAuth(response.authResponse.accessToken);
      } else {
        window.FB.login(
          loginResponse => {
            if (isConnected(loginResponse)) {
              startSocialAuth(loginResponse.authResponse.accessToken);
            }
          },
          { scope: SCOPES },
        );
      }
    });
  };

  return (
    <FacebookButton
      className={clsx(className, 'justify-center')}
      disabled={isLoading || !scriptLoaded}
      text={text}
      onClick={handleClick}
      {...props}
    />
  );
};

export default FacebookLoginButton;
