import { fetcher } from 'fetcher-session';
import {
  IAMRegisterRequest,
  IAMSocialAuthGoogleLinkRequest,
  IAMSocialAuthGoogleRequest,
  IAMSocialAuthGoogleUnlinkRequest,
  IAMUser,
  TokenAuthorization,
} from '~/typings/services/iam/auth';
import Cookies from 'js-cookie';
import { KnownCookie } from 'common-types';
import { recaptchaHeader } from '~/api/auth';
import { IAMRegistrationResponse } from '~/typings/services/iam/responses';

export const attemptSocialAuthGoogle = (
  googleJwt: string,
  recaptchaToken: string,
) =>
  fetcher<TokenAuthorization>('/iam/v1/auth/token', {
    body: {
      token: googleJwt,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      grant_type: 'GOOGLE_TOKEN',
      referral_code: Cookies.get('referral_code' as KnownCookie),
    } as IAMSocialAuthGoogleRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    method: 'post',
  });

export const postRegisterNewUserGoogle = (
  email: string,
  username: string,
  googleToken: string,
  recaptchaToken: string,
) =>
  fetcher<IAMRegistrationResponse>('/iam/v1/auth/user', {
    body: {
      email,
      username,
      agree_with_policy: true,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      link: {
        account_type: 'GOOGLE',
        access_token: googleToken,
      },
    } as IAMRegisterRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    dataOnly: false,
    method: 'post',
  });

export const postLinkGoogleAccount = (
  googleJwt: string,
  recaptchaToken: string,
) =>
  fetcher<IAMUser>(({ uuid }) => `/iam/v1/auth/user/${uuid}/link`, {
    body: {
      account_type: 'GOOGLE',
      access_token: googleJwt,
    } as IAMSocialAuthGoogleLinkRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    method: 'post',
  });

export const deleteLinkGoogleAccount = (
  accountId: string,
  recaptchaToken: string,
) =>
  fetcher<IAMUser>(({ uuid }) => `/iam/v1/auth/user/${uuid}/link`, {
    method: 'delete',
    body: {
      account_id: accountId,
      account_type: 'GOOGLE',
    } as IAMSocialAuthGoogleUnlinkRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
  });
