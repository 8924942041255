import React from 'react';
import { useInterval } from 'react-use';
import useCarousel from '~/hooks/useCarousel';
import useKnownCookie from '~/hooks/useKnownCookie';
import useTileVersions from '~/hooks/useTileVersions';
import CloseIcon from '~/public/static/svg/CloseIcon';
import { RailsBlock, RailsPageView } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  pageView: RailsPageView;
}

const MicroBannerBlock: React.FC<Props> = ({ block, pageView }) => {
  const carousel = useCarousel(block.tiles?.length!);
  const versions = useTileVersions(block);
  const [time, setTime] = useKnownCookie('sls_dmicro_b');

  useInterval(carousel.goToNextIndex, 5000);
  const slide = versions?.[carousel.index];
  if (!slide || time) {
    return null;
  }

  return (
    <div
      className="bg-turf-green-500 relative flex h-14 items-center px-4 text-center text-sm text-white transition-colors duration-200"
      style={{
        color: slide.text_color || undefined,
        backgroundColor: slide.background_color || undefined,
      }}
    >
      <a
        href={slide.destination}
        className="absolute inset-y-0 left-0 right-12"
      />

      <span className="flex-1" />
      <span>{slide.title}</span>
      <span className="flex-1" />
      <button
        className="-m-4 p-4"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setTime(Number(new Date(pageView.updated_at)).toString(), {
            expires: 30,
          });
        }}
      >
        <CloseIcon fill="currentColor" />
      </button>
    </div>
  );
};

export default MicroBannerBlock;
