import { getButterPage } from '~/lib/buttercms/api';

export async function getButterFAQByLanderId(landerId: number) {
  try {
    const response = await getButterPage<{
      expert_recommendations?: string;
      faqbuying_guide_sections: {
        type: 'simple';
        fields: {
          title: string;
          content: string;
          action_label?: string;
          action_url?: string;
        };
      }[];
    }>(`lander-${landerId}`, 'category_landers_extras');
    return response || null;
  } catch (e) {
    return null;
  }
}
