import { useMobile } from 'common-nextjs';
import React from 'react';
import DesktopHeader from '~/components/Chrome/Header/Desktop/DesktopHeader';
import MobileHeader from '~/components/Chrome/Header/Mobile/MobileHeader';

interface Props {
  excludeBanners?: boolean;
  excludeMobileSearch?: boolean;
  excludeNavigation?: boolean;
}

const Header: React.FC<Props> = ({
  excludeBanners,
  excludeMobileSearch,
  excludeNavigation,
}) => {
  const isMobile = useMobile();

  return isMobile ? (
    <MobileHeader
      excludeNavigation={excludeNavigation}
      hideSearch={excludeMobileSearch}
      hideBanners={excludeBanners}
    />
  ) : (
    <DesktopHeader excludeNavigation={excludeNavigation} />
  );
};

export default Header;
