import { ArrowRightIcon } from '@heroicons/react/20/solid';
import React from 'react';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import { getTileVersion } from '~/hooks/useTileVersion';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
  mobile: boolean;
}

const HomeFeaturedCategoriesBlock: React.FC<Props> = ({
  block,
  headerType: Header = 'h2',
  mobile,
}) => {
  let content: React.ReactNode = null;

  if (block.tiles) {
    if (mobile) {
      content = (
        <div className="flex flex-col gap-4">
          {block.tiles.map((_, i) => {
            const tile = getTileVersion(block, mobile, i);

            return (
              <a
                href={tile.destination}
                key={tile.id}
                className="flex items-center space-x-4 rounded border bg-white px-4 py-2"
              >
                <TileImageOrVideo
                  src={tile.background_image_url}
                  className="h-12 w-12 rounded-full object-cover"
                  useFastly={{
                    width: 200,
                    height: 200,
                    crop: '1:1',
                  }}
                />
                <span className="text-base font-semibold">{tile.title}</span>
              </a>
            );
          })}
        </div>
      );
    } else {
      content = (
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 grid-cols-4 gap-2 lg:grid-cols-8">
            {block.tiles.slice(0, 8).map((_, i) => {
              const tile = getTileVersion(block, mobile, i);

              return (
                <a
                  href={tile.destination}
                  key={tile.id}
                  className="flex flex-col items-center justify-center"
                >
                  <TileImageOrVideo
                    src={tile.background_image_url}
                    className="mb-2 h-32 w-32 rounded-full object-cover shadow-lg"
                    useFastly={{
                      width: 200,
                      height: 200,
                      crop: '1:1',
                    }}
                  />
                  <span className="text-center text-base font-semibold">
                    {tile.title}
                  </span>
                </a>
              );
            })}
          </div>

          {block.destination && (
            <a
              href={block.destination}
              className="bg-turf-green-500 rounded-full p-2 text-white"
            >
              <ArrowRightIcon className="h-6 w-6" />
            </a>
          )}
        </div>
      );
    }
  }

  return (
    <section
      className="bg-mint-green-200 py-8 md:py-16"
      id={`${block.layout}-${block.id}`}
    >
      <div className="container mx-auto px-4">
        <Header className="mb-10 text-center text-2xl md:mb-12 md:text-4xl">
          {block.title}
        </Header>

        {content}
      </div>
    </section>
  );
};

export default HomeFeaturedCategoriesBlock;
