import produce from 'immer';

const arrayToNumberOrString = (arr: any[]) =>
  arr.map(v => {
    const num = Number(v);

    return Number.isInteger(num) ? num : v;
  });

const uniques = values => Array.from(new Set(values));

export default function addToState(
  state: Record<string, any>,
  name: string,
  value: string | number | number[] | string[],
) {
  return produce(state, draft => {
    const detail = draft[name];

    if (detail && Array.isArray(detail)) {
      draft[name] = uniques(arrayToNumberOrString(detail.concat(value)));
    } else if (Array.isArray(value)) {
      draft[name] = uniques(arrayToNumberOrString(value));
    } else {
      draft[name] = value;
    }
  });
}
