import { QueryFilters } from '@tanstack/react-query';
import { Session } from 'fetcher-session';
import { fetchSwapsCounts, fetchSwapsInbox } from '~/api/swapsInbox/api';
import { minutes5inMS } from '~/constants/times';

export function swapsInboxCountQuery(session?: Session, type?: string) {
  return {
    queryKey: ['swaps-inbox-counts', type],
    queryFn: () => fetchSwapsCounts(session, type),
    staleTime: minutes5inMS,
  };
}

type SearchParams = {
  type?: string;
  scope?: string;
  q?: string;
  filter?: string[] | string;
  page?: number;
};

export function swapsInboxQuery(session?: Session, query?: SearchParams) {
  const type = query?.type as string;
  const scope = query?.scope as string;
  const page = Number(query?.page) || 1;
  const keyword = query?.q as string;
  const filters = query?.filter
    ? Array.isArray(query.filter)
      ? query.filter
      : [query.filter]
    : [];

  return {
    queryKey: ['swaps-inbox', type, scope, { page, keyword, filters }],
    queryFn: () =>
      fetchSwapsInbox(session, { type, scope, keyword, page, filters }),
    staleTime: minutes5inMS,
    keepPreviousData: true,
  };
}

export const invalidateSwapsInboxQuery: QueryFilters = {
  predicate: query => {
    const firstQueryKeyElement = query.queryKey?.[0];

    if (typeof firstQueryKeyElement !== 'string') {
      return false;
    }

    return /^(swaps-inbox|badge)/g.test(firstQueryKeyElement);
  },
};
