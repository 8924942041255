import { FlattenedFilterState } from '~/typings/services/rails/facets';

const ITEM_TYPE_AUCTION = 6;

export default function shouldForceSearchMode(
  state: FlattenedFilterState,
): boolean {
  if (state.item_type?.includes?.(ITEM_TYPE_AUCTION)) {
    return true;
  }

  if (state.sale) {
    return true;
  }

  return false;
}
