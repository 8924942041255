import { useQuery } from '@tanstack/react-query';
import { generateButtonClassNames } from 'ui';
import ExpanderWithLabel from '~/components/Expander/ExpanderWithLabel';
import NewTabLink from '~/components/Links/NewTabLink';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { getButterFAQByLanderId } from '~/lib/buttercms/searchPages/faq';
import { RailsBlock } from '~/typings/services/rails/tile';

export default function CollapsibleButterContent({
  block,
  headerType: Header = 'h3',
}: {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}) {
  const { lander } = useFacetFiltering();
  const { data } = useQuery(
    ['collapsible-butter-content', lander?.id],
    () => getButterFAQByLanderId(lander?.id!),
    {
      enabled: !!lander?.id,
    },
  );

  if (!data) {
    return null;
  }

  const ExpanderHeaderElement = Header === 'h2' ? 'h3' : 'h4';

  return (
    <section className="container">
      <Header className="mb-4 text-lg">{block.title}</Header>
      {data?.fields?.faqbuying_guide_sections.map((section, index) => (
        <ExpanderWithLabel
          key={index}
          label={
            <ExpanderHeaderElement className="text-base">
              {section.fields.title}
            </ExpanderHeaderElement>
          }
        >
          <p
            dangerouslySetInnerHTML={{ __html: section.fields.content }}
            className="prose"
          />

          {section.fields.action_label && section.fields.action_url && (
            <div className="mb-2 mt-4">
              <NewTabLink
                href={section.fields.action_url}
                className={generateButtonClassNames({
                  variant: 'outlined',
                })}
              >
                {section.fields.action_label}
              </NewTabLink>
            </div>
          )}
        </ExpanderWithLabel>
      ))}
    </section>
  );
}
