import { clsx } from 'clsx';
import React, { useState } from 'react';
import { Button } from 'ui';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import useTileVersions from '~/hooks/useTileVersions';
import { RailsBlock } from '~/typings/services/rails/tile';

/**
 * Two rows of 6 on desktop, two rows of 3 on mobile
 *
 * 0  1  2  3  4  5
 * 6  7  8  9 10 11
 *
 * 0  1  2
 * 3  4  5
 */

interface Props {
  block: RailsBlock;
}

const ExploreCollectionBlock: React.FC<Props> = ({ block }) => {
  const [expanded, setExpanded] = useState(false);
  const tileVersions = useTileVersions(block);
  const length = tileVersions?.length || 0;

  return (
    <>
      <div className="grid grid-cols-3 gap-4 md:grid-cols-6">
        {tileVersions?.map((tile, i) => (
          <a
            key={tile.id}
            href={tile.destination}
            className={clsx({
              'hidden md:block': i >= 5 && i <= 11 && !expanded,
              hidden: i > 11 && !expanded,
            })}
          >
            <div className="aspect-w-1 aspect-h-1 rounded">
              <TileImageOrVideo
                src={tile.background_image_url}
                alt={tile.title}
                className="rounded object-cover"
              />
            </div>

            <div className="mt-2 text-center font-semibold">{tile.title}</div>
          </a>
        ))}
      </div>

      <div
        className={clsx('mt-6 flex justify-center', {
          hidden: expanded || length <= 12,
        })}
      >
        <Button
          variant="outlined"
          className="w-full md:w-auto"
          onClick={() => setExpanded(true)}
        >
          {block.cta || 'Show More'}
        </Button>
      </div>
    </>
  );
};

export default ExploreCollectionBlock;
