export default function generatePageTitle(
  title?: string,
  template = 'Buy and Sell on SidelineSwap',
) {
  if (title && template) {
    return `${title} | ${template}`;
  } else if (title) {
    return title;
  } else {
    return 'SidelineSwap';
  }
}
