import { useSession } from '~/contexts/SessionContext';

const LogoutOption = () => {
  const { logout } = useSession();

  return (
    <li>
      <a
        role="menuitem"
        href="#"
        className="text-sm"
        onClick={e => {
          e.preventDefault();
          logout();
        }}
      >
        Logout
      </a>
    </li>
  );
};

export default LogoutOption;
