'use client';

import { clsx } from 'clsx';
import styled from 'styled-components';
import Image, { CustomImageProps } from '~/components/Image';

export function HeroImage({ className, ...props }: CustomImageProps) {
  return (
    <Image
      {...props}
      className={clsx(
        'pointer-events-none absolute inset-0 z-0 h-full w-full object-cover',
        className,
      )}
    />
  );
}

export const DarkenedHeroImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 0;
  filter: brightness(0.4);
`;

export const HeroContent = styled.div`
  padding: 32px 0;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  position: relative;

  @media (max-width: 767px) {
    height: 200px;
  }
`;
