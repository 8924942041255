import { useQuery } from '@tanstack/react-query';
import { LogoTagline } from 'common-nextjs';
import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from 'ui';
import { displayPrice } from 'utils';
import { fetchSpendableBalance } from '~/api/sellerAccount';
import BannerCollection from '~/components/Chrome/Banners/BannerCollection';
import BaseHeader from '~/components/Chrome/Header/BaseHeader';
import CartButton from '~/components/Chrome/Header/CartButton';
import BaseNav from '~/components/Chrome/Header/Mobile/BaseNav';
import Hamburger from '~/components/Chrome/Header/Mobile/Hamburger';
import LeftNav from '~/components/Chrome/Header/Mobile/LeftNav';
import MobileHeaderSearch from '~/components/Chrome/Header/Mobile/MobileHeaderSearch';
import {
  MobileNav,
  MobileNavOverlay,
} from '~/components/Chrome/Header/Mobile/MobileNavElements';
import PagesDirLeftNavCmsNavigation from '~/components/Chrome/Header/Mobile/PagesDirLeftNavCmsNavigation';
import RightNav from '~/components/Chrome/Header/Mobile/RightNav';
import SignInSection from '~/components/Chrome/Header/Mobile/SignInSection';
import HomePageLink from '~/components/Links/HomePageLink';
import LoginLink from '~/components/Links/LoginLink';
import MyFeedLink from '~/components/Links/MyFeedLink';
import RegisterLink from '~/components/Links/RegisterLink';
import SellNowLink from '~/components/Links/SellNowLink';
import MobileMenuItem from '~/components/MobileMenuItem';
import { useSession } from '~/contexts/SessionContext';
import { useIsSidelineProAvailable } from '~/hooks/useIsSidelinePro';
import useKnownCookie from '~/hooks/useKnownCookie';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';

const disableScrolling = (which: 'left' | 'right' | 'search') => {
  document.body.classList.add('nav-open', which);
};

const enableScrolling = () => {
  document.body.classList.remove('nav-open', 'left', 'right');
};

const Sell = styled(Button).attrs((props: ButtonProps) => ({
  variant: 'outlined',
  color: 'primary',
}))`
  border-width: 2px;
  padding: 6px 16px;
`;

type Props = {
  excludeNavigation?: boolean;
  hideBanners?: boolean;
  hideSearch?: boolean;
};

const MobileHeader: React.FC<Props> = ({
  excludeNavigation,
  hideBanners,
  hideSearch,
}) => {
  const [open, setOpen] = useState<'left' | 'right' | null>(null);
  const { loggedIn, logout, user } = useSession();
  const [tab] = useKnownCookie('sls_swaps_inbox_tab');

  const { data: spendableBalance, status } = useQuery(
    ['spendable_balance'],
    fetchSpendableBalance,
    { enabled: open === 'right' && !!user, staleTime: 500000 },
  );
  const proAvailable = useIsSidelineProAvailable();

  function openLeft() {
    setOpen('left');
  }

  function openRight() {
    setOpen('right');
  }

  function close() {
    setOpen(null);
  }

  function toggleLeft() {
    if (open === 'left') {
      close();
    } else {
      openLeft();
    }
  }

  function toggleRight() {
    if (open === 'right') {
      close();
    } else {
      openRight();
    }
  }

  useEffect(() => {
    if (open == null) {
      enableScrolling();
    } else {
      disableScrolling(open);
      Router.events.on('routeChangeStart', close);
    }

    return () => {
      Router.events.off('routeChangeStart', close);
      enableScrolling();
    };
  }, [open]);

  return (
    <>
      <BaseHeader>
        {hideBanners ? <div /> : <BannerCollection />}
        <MobileNav className={hideSearch ? '' : '-ml-4'}>
          {hideSearch ? (
            <button
              onClick={toggleLeft}
              aria-label="Open navigation to search categories, terms, and more."
            >
              <Hamburger />
            </button>
          ) : (
            <div />
          )}

          <div className="flex-1">
            <HomePageLink>
              <LogoTagline className="inline" noTagline width={116} />
            </HomePageLink>
          </div>

          <SellNowLink>
            <Sell>Sell</Sell>
          </SellNowLink>

          {loggedIn && (
            <MyFeedLink>
              <FavoriteIcon fill="currentColor" />
            </MyFeedLink>
          )}

          <CartButton />

          <SignInSection onClick={toggleRight} />
        </MobileNav>

        <BaseNav
          style={{
            left: open === 'left' ? 0 : -270,
          }}
        >
          {!loggedIn && (
            <div className="mb-2 flex">
              <RegisterLink className="mr-2 block w-full">
                <Button className="w-full" color="green">
                  Join
                </Button>
              </RegisterLink>

              <LoginLink className="block w-full">
                <Button className="w-full" color="green">
                  Sign In
                </Button>
              </LoginLink>
            </div>
          )}

          <LeftNav
            excludeNavigation={excludeNavigation}
            cmsNavigation={<PagesDirLeftNavCmsNavigation />}
          />
        </BaseNav>

        <BaseNav
          style={{
            right: open === 'right' ? 0 : -270,
          }}
        >
          <RightNav
            logoutAction={
              <MobileMenuItem
                chevron={false}
                text="Logout"
                variant="dark"
                onClick={() => {
                  logout();
                  close();
                }}
              />
            }
            proAvailable={proAvailable}
            spendableBalance={
              <MobileMenuItem
                href="/selling"
                chevron={false}
                variant="dark"
                text={
                  <span>
                    Balance:{' '}
                    <strong>
                      {displayPrice(spendableBalance?.amount || 0)}
                    </strong>
                  </span>
                }
              />
            }
            username={user?.username}
            inboxTab={tab || undefined}
          />
        </BaseNav>
      </BaseHeader>

      {!hideSearch && <MobileHeaderSearch onClickHamburger={toggleLeft} />}

      {open && (
        <MobileNavOverlay
          style={{
            left: open === 'left' ? 270 : undefined,
            right: open === 'right' ? 270 : undefined,
          }}
          onClick={close}
        />
      )}
    </>
  );
};

export default MobileHeader;
