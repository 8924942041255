import React from 'react';
import FeatheredOverflowContainer from '~/components/FeatheredOverflowContainer';

interface Props {}

/**
 * Displays a horizontal scroll of items in mobile, but an item grid in desktop
 */
const HorizontalScrollItemGrid: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => (
  <FeatheredOverflowContainer
    className="items-stretch gap-2"
    style={{
      paddingLeft: '0.5px',
      paddingBottom: '0.5px',
    }}
  >
    {children}
  </FeatheredOverflowContainer>
);

export default HorizontalScrollItemGrid;
