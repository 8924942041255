import { useMobile } from 'common-nextjs';
import React from 'react';
import { Divider } from 'ui';
import BaseBanner from '~/components/Chrome/Banners/BaseBanner';
import LoginLink from '~/components/Links/LoginLink';
import ShopLink from '~/components/Links/ShopLink';
import { useModals } from '~/contexts/ModalsContext';
import { useSale } from '~/contexts/SaleContext';
import { useSession } from '~/contexts/SessionContext';
import ChevronRight from '~/public/static/svg/ChevronRight';
import CloseIcon from '~/public/static/svg/CloseIcon';
import TagIcon from '~/public/static/svg/TagIcon';

const MobileBanner = ({ isBuySide, onClose, sale, title }) => (
  <BaseBanner className="bg-primary-500 items-center text-white">
    <TagIcon />

    <div className="flex flex-col">
      <div className="flex items-center font-bold uppercase">
        <div className="mr-2">{title}</div>
        <ChevronRight fill="#fff" height={15} width={15} />
      </div>
      {/*<div className="leading-tight">{sale.subtitle}</div>*/}
    </div>

    <div className="w-full flex-1" />

    <Divider vertical />

    <button
      type="button"
      onClick={onClose}
      className="z-10 -m-2 p-4"
      title="Dismiss"
    >
      <CloseIcon fill="#fff" />
    </button>
  </BaseBanner>
);

const DesktopBanner = ({ isBuySide, onClose, sale, title }) => (
  <BaseBanner className="bg-primary-500 items-center text-white">
    <TagIcon />

    <div className="font-bold uppercase">{title}</div>

    <Divider vertical />

    <div>{sale.subtitle}</div>

    <div className="w-full flex-1" />

    <div className="flex items-center">
      <div className="mr-2 font-bold uppercase">
        {isBuySide ? 'Shop Sale' : 'Add to Sale'}
      </div>

      <ChevronRight fill="#fff" height={15} width={15} />
    </div>

    <Divider vertical />

    <button
      type="button"
      onClick={onClose}
      className="z-10 -m-2 p-4"
      title="Dismiss"
    >
      <CloseIcon fill="#fff" />
    </button>
  </BaseBanner>
);

const SaleBanner = () => {
  const { dismiss, isBuySide, sale } = useSale();
  const { loggedIn, user } = useSession();
  const modals = useModals();

  const mobile = useMobile();

  if (!sale) {
    return null;
  }

  const title = isBuySide
    ? `${sale.label} - ${sale.name}`
    : `${sale.name} starting soon`;

  const Banner = mobile ? MobileBanner : DesktopBanner;

  const props = {
    isBuySide,
    onClose: (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dismiss();
    },
    sale,
    title,
  };

  if (isBuySide) {
    return (
      <ShopLink path={sale.destination}>
        <GlobalStyleChange />
        <Banner {...props} />
      </ShopLink>
    );
  } else if (loggedIn && user) {
    return (
      <div
        className="w-full"
        onClick={() => modals.openModal('saleGuidance', {})}
      >
        <GlobalStyleChange />
        <Banner {...props} />
      </div>
    );
  } else {
    return (
      <LoginLink message="Sign into your SidelineSwap account to add your items to the sale">
        <GlobalStyleChange />
        <Banner {...props} />
      </LoginLink>
    );
  }
};

const GlobalStyleChange = () => (
  <style jsx global>
    {`
      #nprogress .bar {
        background: #4a5568;
      }

      #nprogress .peg {
        box-shadow:
          0 0 10px #4a5568,
          0 0 5px #4a5568;
      }
    `}
  </style>
);

export default SaleBanner;
