import Link from 'next/link';
import React from 'react';

import { CustomLinkProps } from 'common-nextjs';

export const settingsPages = {
  account: '/settings/account',
  address: '/settings/addresses',
  connectedAccounts: '/settings/integrations',
  notifications: '/settings/notifications',
  payment: '/settings/payment_methods',
  vacation: '/settings/vacation',
  csvImport: '/settings/csv-import',
};

export type SettingsPageLinkPages = keyof typeof settingsPages;

type Props = CustomLinkProps & {
  page?: SettingsPageLinkPages;
};

const SettingsPageLink: React.FC<Props> = ({
  children,
  page = 'account',
  ...props
}) => (
  <Link href={`${settingsPages[page]}`} {...props}>
    {children}
  </Link>
);

export default SettingsPageLink;
