import { useMobile } from 'common-nextjs';
import React from 'react';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import { RailsTile } from '~/typings/services/rails/tile';

interface BasicTileProps {
  onClick?: React.MouseEventHandler;
  tile: RailsTile;
}

export const BasicTile: React.FC<BasicTileProps> = ({ onClick, tile }) => {
  const mobile = useMobile();
  const image =
    (mobile
      ? tile.versions?.find(v => v.platform === 'mobile')?.background_image_url
      : tile.versions?.find(v => v.platform === 'web')?.background_image_url) ||
    tile.background_image_url;

  return (
    <a href={tile.destination} onClick={onClick}>
      {image ? (
        <TileImageOrVideo
          alt={tile.title}
          src={image}
          className="block h-auto w-full cursor-pointer rounded object-cover"
          useFastly={{
            width: 400,
          }}
        />
      ) : (
        <div />
      )}

      <div className="block pt-1 text-center text-sm font-semibold">
        {tile.title}
      </div>
    </a>
  );
};

export default BasicTile;
