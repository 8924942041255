import { clsx } from 'clsx';
import React from 'react';

/*const MobileNav = styled.nav`
  display: grid;
  grid-template-columns: auto 1fr auto auto auto auto;
  grid-gap: 16px;
  align-items: center;
  padding: 0 16px;
  height: 56px;
`;*/

interface MobileNavProps {
  className?: string;
}

export const MobileNav: React.FC<React.PropsWithChildren<MobileNavProps>> = ({
  children,
  className,
}) => {
  return (
    <nav className={clsx('flex h-14 items-center space-x-4 px-4', className)}>
      {children}
    </nav>
  );
};

/*const Overlay = styled.div`
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1000000000;
`;*/

interface MobileNavOverlayProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const MobileNavOverlay: React.FC<MobileNavOverlayProps> = ({
  style,
  onClick,
}) => {
  return (
    <div
      className="fixed inset-0 z-40 bg-transparent"
      style={{
        ...style,
      }}
      onClick={onClick}
    />
  );
};
