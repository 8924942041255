import { clsx } from 'clsx';
import React from 'react';
import TileImageOrVideo from '~/components/legos/TileImageOrVideo';
import ArrowRightIcon from '~/public/static/svg/ArrowRightIcon';

interface Props {
  className?: any;
  image?: string;
  onClick?: React.MouseEventHandler;
  title: string;
}

const HorizontalOnlyUnlimitedTile: React.FC<Props> = ({
  className,
  image,
  onClick,
  title,
}) => (
  <button
    className={clsx(
      'border-grey-200 flex w-full items-center rounded border bg-white p-2 px-4 shadow transition-shadow duration-300 hover:shadow-md',
      className,
    )}
    onClick={onClick}
  >
    {image ? (
      <span className="relative ml-0 mr-4 h-8 w-8">
        <TileImageOrVideo
          alt=""
          src={image}
          className="block h-8 w-8 rounded object-cover"
          useFastly={{
            height: 80,
            width: 80,
            optimize: 'medium',
            crop: '1:1',
          }}
        />
      </span>
    ) : (
      <div className="ml-0 mr-4 h-8 w-8" />
    )}

    <div className="mt-0 block text-left text-sm font-semibold">{title}</div>

    <div className="mr-auto" />

    <ArrowRightIcon className="block" />
  </button>
);

export default HorizontalOnlyUnlimitedTile;
