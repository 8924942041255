import React from 'react';
import MarkdownContent from '~/components/MarkdownContent';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const TextParagraphBlock: React.FC<Props> = ({ block }) => {
  if (!block.description) {
    return null;
  }

  return (
    <div className="container mb-4 mt-8 md:mb-8 md:mt-12">
      <MarkdownContent source={block.description} />
    </div>
  );
};

export default TextParagraphBlock;
