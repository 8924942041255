import React, { Suspense } from 'react';
import { Button } from 'ui';
import { generateButterBlogLink } from '~/components/Links/ButterBlogLink';
import { helpLinks } from '~/components/Links/HelpLink';
import SellNowLink from '~/components/Links/SellNowLink';
import { socialLinks } from '~/components/Links/SocialLink';
import MobileMenuItem from '~/components/MobileMenuItem';

type Props = {
  cmsNavigation?: React.ReactNode;
  excludeNavigation?: boolean;
};

const LeftNav: React.FC<Props> = ({ cmsNavigation, excludeNavigation }) => (
  <>
    <SellNowLink>
      <Button className="mb-2 w-full" color="green" variant="outlined">
        Sell
      </Button>
    </SellNowLink>

    <ul>
      <MobileMenuItem href="/" chevron={false} text="Home" variant="dark" />

      {!excludeNavigation && cmsNavigation && (
        <MobileMenuItem text="Browse by Category" variant="dark" expanded>
          <Suspense>{cmsNavigation}</Suspense>
        </MobileMenuItem>
      )}

      <MobileMenuItem text="Selling" variant="dark">
        <MobileMenuItem
          href="/sell-now"
          chevron={false}
          text="How to Sell"
          variant="dark"
        />

        <MobileMenuItem
          href="/nav/how-to-ship/l163448"
          chevron={false}
          text="How to Ship"
          variant="dark"
        />
      </MobileMenuItem>

      <MobileMenuItem
        href={generateButterBlogLink()}
        text="Guides & Reviews"
        variant="dark"
        chevron={false}
      />

      <MobileMenuItem
        href="/gift-cards"
        chevron={false}
        text="Gift Cards"
        variant="dark"
      />

      <MobileMenuItem
        href="/value-guide"
        chevron={false}
        text="Value Guide"
        variant="dark"
      />

      <MobileMenuItem
        href={helpLinks.default}
        chevron={false}
        text="Help Center / FAQ"
        variant="dark"
      />

      <MobileMenuItem text="Company" variant="dark">
        <MobileMenuItem
          href="/terms"
          chevron={false}
          text="Terms"
          variant="dark"
        />

        <MobileMenuItem
          href="/privacy"
          chevron={false}
          text="Privacy"
          variant="dark"
        />

        <MobileMenuItem
          href={helpLinks.refundPolicy}
          chevron={false}
          text="Refunds"
          variant="dark"
        />

        <MobileMenuItem
          href={helpLinks.press}
          chevron={false}
          text="Press"
          variant="dark"
        />

        <MobileMenuItem
          href="/sitemap/index"
          chevron={false}
          text="Sitemap"
          variant="dark"
        />
      </MobileMenuItem>

      <MobileMenuItem text="Community" variant="dark">
        <MobileMenuItem
          text="TikTok"
          variant="dark"
          chevron={false}
          href={socialLinks.tiktok}
        />

        <MobileMenuItem text="Instagram" variant="dark">
          <MobileMenuItem
            href={socialLinks.instagramLacrosse}
            chevron={false}
            text="Lacrosse"
            variant="dark"
          />

          <MobileMenuItem
            href={socialLinks.instagramHockey}
            chevron={false}
            text="Hockey"
            variant="dark"
          />
        </MobileMenuItem>

        <MobileMenuItem text="YouTube" variant="dark">
          <MobileMenuItem
            href={socialLinks.youtubeBaseball}
            chevron={false}
            text="Baseball"
            variant="dark"
          />

          <MobileMenuItem
            href={socialLinks.youtubeFootball}
            chevron={false}
            text="Football"
            variant="dark"
          />

          <MobileMenuItem
            href={socialLinks.youtubeHockey}
            chevron={false}
            text="Hockey"
            variant="dark"
          />

          <MobileMenuItem
            href={socialLinks.youtubeLacrosse}
            chevron={false}
            text="Lacrosse"
            variant="dark"
          />
        </MobileMenuItem>

        <MobileMenuItem
          href={socialLinks.facebook}
          chevron={false}
          text="Facebook"
          variant="dark"
        />

        <MobileMenuItem
          href={helpLinks.eliteSeller}
          chevron={false}
          text="Elite Seller"
          variant="dark"
        />

        <MobileMenuItem
          href={helpLinks.ambassadors}
          chevron={false}
          text="Ambassadors"
          variant="dark"
        />
      </MobileMenuItem>

      <MobileMenuItem href="/blog" chevron={false} text="Blog" variant="dark" />
    </ul>
  </>
);

export default LeftNav;
