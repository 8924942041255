import { clsx } from 'clsx';
import React from 'react';
import EmailIcon from '~/public/static/svg/EmailIcon';

type Props = React.HTMLProps<HTMLButtonElement> & {
  filled?: boolean;
  text?: string;
};

const EmailLoginButton: React.FC<Props> = ({
  className,
  filled,
  text,
  ...props
}) => (
  <button
    className={clsx(
      'bg-primary-500 flex h-10 w-full items-center justify-center rounded px-4 text-sm font-semibold text-white',
      className,
    )}
    {...props}
    type="button"
  >
    <EmailIcon fill="currentColor" />
    <div className="ml-4">{text || 'Continue with Email'}</div>
  </button>
);

export default EmailLoginButton;
