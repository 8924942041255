import React from 'react';
import UnlimitedTileBase from '~/components/legos/Tiles/UnlimitedTileBase';
import { RailsTile } from '~/typings/services/rails/tile';

interface UnlimitedTileProps {
  mobile?: boolean;
  onClick?: React.MouseEventHandler;
  tile: RailsTile;
}

export const UnlimitedTile: React.FC<UnlimitedTileProps> = ({
  mobile,
  onClick,
  tile,
}) => (
  <a href={tile.destination}>
    <UnlimitedTileBase
      image={
        (mobile
          ? tile.versions?.find(v => v.platform === 'mobile')
              ?.background_image_url
          : tile.versions?.find(v => v.platform === 'web')
              ?.background_image_url) || tile.background_image_url
      }
      onClick={onClick}
      title={tile.title}
    />
  </a>
);

export default UnlimitedTile;
