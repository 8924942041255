import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import React from 'react';
import { Button, CustomButtonProps, generateButtonClassNames } from 'ui';

type ButtonLinkProps = CustomButtonProps & LinkProps;

const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<ButtonLinkProps>
>(({ children, className, disabled, fluid, ...props }, ref) => {
  if (disabled) {
    return (
      // @ts-ignore
      <Button {...props} fluid={fluid} ref={ref} disabled={disabled}>
        {children}
      </Button>
    );
  } else {
    return (
      <Link
        {...props}
        className={generateButtonClassNames({
          ...props,
          fluid,
          className: clsx(className, 'inline-block text-center'),
        })}
        ref={ref}
      >
        {children}
      </Link>
    );
  }
});

export default ButtonLink;
