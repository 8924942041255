import { useQuery } from '@tanstack/react-query';
import { fetchActiveVacation } from '~/api/user';
import { useSession } from '~/contexts/SessionContext';

export default function useActiveVacation() {
  const { loggedIn } = useSession();
  return useQuery(['active-vacation'], fetchActiveVacation, {
    enabled: loggedIn,
    retry: false,
  });
}
