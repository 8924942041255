import { useSession } from '~/contexts/SessionContext';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UniversalCart } from '~/typings/services/rails/cart';
import { fetchCart } from '~/api/carts';
import { RailsItem, RailsThumbnailItem } from '~/typings/services/rails/item';
import { GuestCartItem } from '~/hooks/cart/useAddToCart';
import { LS_CART_KEY } from '../../constants/localStorageKeys';

export interface GuestCart {
  cart_items: GuestCartItem[];
}

export function useCart(): UseQueryResult<UniversalCart | undefined> {
  const { loggedIn } = useSession();
  return useQuery<UniversalCart | undefined>(
    ['cart'],
    () => {
      if (loggedIn) {
        return fetchCart();
      } else {
        return getLocalStorageCartItems();
      }
    },
    {
      staleTime: 30000,
    },
  );
}

export function getLocalStorageCartItems(): GuestCart {
  try {
    const existingCart = localStorage.getItem(LS_CART_KEY);
    if (existingCart) {
      return { cart_items: JSON.parse(existingCart) as GuestCartItem[] };
    }
  } catch (e) {}

  return { cart_items: [] };
}

/**
 * Add item to local storage's cart. Caution: this function will throw if something goes wrong
 * @param item
 */
export function addLocalStorageCartItem(
  item: RailsItem | RailsThumbnailItem,
): void {
  const existingCart = localStorage.getItem(LS_CART_KEY);
  const guestItem = {
    id: item.id,
    item,
  };

  if (existingCart) {
    localStorage.setItem(
      LS_CART_KEY,
      JSON.stringify([
        ...(JSON.parse(existingCart) as GuestCartItem[]),
        guestItem,
      ] as GuestCartItem[]),
    );
  } else {
    localStorage.setItem(
      LS_CART_KEY,
      JSON.stringify([guestItem] as GuestCartItem[]),
    );
  }
}

/**
 * Remove item from local storage cart. This function can throw
 * @param itemId
 */
export function removeLocalStorageCartItem(itemId: number): GuestCart {
  const lsCart = localStorage.getItem(LS_CART_KEY);

  if (lsCart) {
    const existingCart = JSON.parse(lsCart) as GuestCartItem[];

    for (let i = 0; i < existingCart.length; i++) {
      if (existingCart[i].item.id === itemId) {
        existingCart.splice(i, 1);
      }
    }

    localStorage.setItem(LS_CART_KEY, JSON.stringify(existingCart));

    return { cart_items: existingCart };
  }

  return { cart_items: [] };
}
