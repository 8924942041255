'use client';

import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  fill?: string;
};

const SearchIcon: React.FC<Props> = ({
  fill = '#fff',
  height = 18,
  width = 18,
  ...props
}) => (
  <svg height={height} width={width} {...props} viewBox="0 0 12 12">
    <path
      fill={fill}
      d="M8.333 7.333h-.526l-.187-.18a4.314 4.314 0 0 0 1.047-2.82 4.333 4.333 0 1 0-4.334 4.334c1.074 0 2.06-.394 2.82-1.047l.18.187v.526l3.334 3.327.993-.993-3.327-3.334zm-4 0c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
    />
  </svg>
);

export default SearchIcon;
