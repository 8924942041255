import React from 'react';
import { clsx } from 'clsx';

interface DividerProps {
  noMargin?: boolean;
  vertical?: boolean;
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({
  className,
  noMargin,
  vertical,
}) => (
  <div
    className={clsx(
      'bg-grey-200',
      !noMargin && (vertical ? 'mx-4' : 'my-4'),
      vertical ? 'h-full w-px' : 'h-px w-full',
      className,
    )}
  />
);
