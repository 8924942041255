import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import { useState } from 'react';
import HorizontalOnlyUnlimitedTile from '~/components/legos/Tiles/HorizontalOnlyUnlimitedTile';
import useCategoriesContext from '~/hooks/useCategoriesQuery';

const CategorySelections = () => {
  const mobile = useMobile();
  const categories = useCategoriesContext();
  const [expanded, setExpanded] = useState(false);

  if (!categories) {
    return null;
  }

  return (
    <>
      <div className={clsx('grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4')}>
        {categories.map((category, i) => (
          <a
            key={category.id}
            href={category.url}
            className={clsx([
              !expanded && mobile && i >= 8 && 'hidden',
              !expanded && !mobile && i >= 15 && 'hidden',
            ])}
          >
            <HorizontalOnlyUnlimitedTile
              image={category.icon_url!}
              title={category.name}
            />
          </a>
        ))}
      </div>

      <button
        className="border-grey-500 mt-4 w-full rounded border bg-white py-2 text-sm font-semibold"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? 'VIEW LESS' : 'VIEW ALL'}
      </button>
    </>
  );
};

export default CategorySelections;
