import React from 'react';
import ItemThumbnail from '~/components/ItemThumbnail/ItemThumbnail';
import BlockSection from '~/components/legos/BlockSection';
import HorizontalScrollItemGrid from '~/components/legos/HorizontalScrollItemGrid';
import useItemGridMeta from '~/hooks/useItemGridMeta';
import useRecentlyViewedItems from '~/hooks/useRecentlyViewedItems';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}

const RecentlyViewedItemBlock: React.FC<Props> = ({ block, headerType }) => {
  const { data } = useRecentlyViewedItems(20);
  useItemGridMeta(data);

  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <BlockSection block={block} headerType={headerType}>
      <HorizontalScrollItemGrid>
        {data.map(item => (
          <ItemThumbnail key={item.id} item={item} />
        ))}
      </HorizontalScrollItemGrid>
    </BlockSection>
  );
};

export default RecentlyViewedItemBlock;
