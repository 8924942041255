import {
  FilterState,
  FlattenedFilterState,
} from '~/typings/services/rails/facets';

export default function flattenFilterState(
  args?: FilterState | FlattenedFilterState,
): FlattenedFilterState {
  if (args) {
    const { facets, ...filterState } = args;

    return {
      ...filterState,
      ...facets,
    };
  }

  return {};
}
