import Image, { FastlyImageOptimizerOptions } from '~/components/Image';

export default function TileImageOrVideo({
  src,
  className,
  alt,
  useFastly,
}: {
  alt?: string;
  className?: string;
  src: string;
  useFastly?: FastlyImageOptimizerOptions | boolean;
}) {
  if (src?.endsWith('.webm')) {
    return (
      <video autoPlay loop muted playsInline className={className}>
        <source src={src} type="video/webm" />
      </video>
    );
  } else {
    return (
      <Image src={src} alt={alt} className={className} useFastly={useFastly} />
    );
  }
}
