import { Description, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import React from 'react';
import { useNewModalContext } from '~/contexts/NewModalContext';

export interface NewModalBodyProps {
  children?: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;

  variant?: 'paywall';
}

const NewModalBody: React.FC<NewModalBodyProps> = ({
  children,
  className,
  description,
  title,
  variant,
}) => {
  const modal = useNewModalContext();

  return (
    <>
      <div className="flex w-full items-start space-x-4 px-6">
        <div className="flex-1">
          {!!title && (
            <DialogTitle as="h3" className="text-lg font-semibold">
              {title}
            </DialogTitle>
          )}

          {!!description && (
            <Description className="text-slate-green-500 mt-2 text-sm">
              {description}
            </Description>
          )}
        </div>

        {/* Close button */}
        <button
          type="button"
          className={clsx(
            'text-slate-green-500 whitespace-nowrap rounded-md focus:ring print:hidden',
            {
              'hover:text-slate-green-600': !variant,
              'hover:text-white': variant === 'paywall',
            },
          )}
          onClick={modal.onClose}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className={clsx('mb-6 mt-4 px-6', className)}>{children}</div>
    </>
  );
};

export default NewModalBody;
