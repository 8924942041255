import React from 'react';
import { Avatar } from '~/components/Avatar';
import LoginLink from '~/components/Links/LoginLink';
import { useSession } from '~/contexts/SessionContext';
import { useV2Session } from '~/hooks/useV1Session';

interface Props {
  onClick: React.MouseEventHandler;
}

const SignInSection: React.FC<Props> = ({ onClick }) => {
  const { loggedIn, user } = useSession();
  const { data: v2Session } = useV2Session();

  if (!loggedIn || !user) {
    return <LoginLink>Sign In</LoginLink>;
  }

  return (
    <button onClick={onClick} aria-label="View account actions">
      <Avatar avatarUrl={v2Session?.avatar_url} />
    </button>
  );
};

export default SignInSection;
