import {
  RailsFacetGroup,
  RailsFacetModelReferenceFacet,
  TypedRailsFacetGroup,
} from '~/typings/services/rails/facets';
import { RailsItemModel } from '~/typings/services/rails/item';

function sortModel(a: RailsItemModel, b: RailsItemModel) {
  if (a.position != null && b.position != null) {
    return a.position - b.position;
  }

  return a.position || 0 || b.position || 0;
}

export default function getReferenceModelsFromFacets(
  facets: RailsFacetGroup[],
) {
  const modelFacet = (
    facets as TypedRailsFacetGroup<RailsFacetModelReferenceFacet>[]
  ).find(f => f.slug === 'model')!;

  return (
    modelFacet?.children
      ?.map(c => c.reference)
      ?.filter(Boolean) // Avoid cases where you didn't pass ?include_model=1 to facet_items, but you try to get the reference models
      .sort(sortModel) || null
  );
}
