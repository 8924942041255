import { postAttributions } from '~/api/auth';
import setDeveloperPortalCookies from '~/utils/setDeveloperPortalCookies';
import getCookieDestinationUrl from '~/utils/getCookieDestinationUrl';
import Cookies from 'js-cookie';
import { useSession } from '~/contexts/SessionContext';
import useRouteQuery from '~/hooks/useRouteQuery';
import { LoginPageParams } from '~/components/LoginRegister/LoginForm';
import useRecaptcha from '~/hooks/useRecaptcha';
import {
  IAMLoginMeta,
  IAMUser,
  SocialAuthCallback,
  SocialAuthErrorMeta,
  SocialAuthType,
  TokenAuthorization,
} from '~/typings/services/iam/auth';
import { useModals } from '~/contexts/ModalsContext';
import { KnownCookie } from 'common-types';
import { generateRegisterOnboardingLink } from '~/components/Links/RegisterLink';
import { useRouter } from 'next/router';
import {
  postChallengePin,
  postLogin,
  postRegister,
} from '~/api/iam/auth/sideline';
import {
  attemptSocialAuthFacebook,
  postLinkFacebookAccount,
} from '~/api/iam/auth/facebook';
import {
  attemptSocialAuthGoogle,
  deleteLinkGoogleAccount,
  postLinkGoogleAccount,
} from '~/api/iam/auth/google';
import { useContext } from 'react';
import { LoginModalContext } from '~/contexts/LoginModalContext';
import { fireJoinedEvent } from '~/services/analytics/events/auth';
import { ApiError } from 'fetcher-session';
import {
  attemptSocialAuthApple,
  postLinkAppleAccount,
} from '~/api/iam/auth/apple';

export class AppleSignInError extends Error {
  constructor(error: string) {
    super(error);
    this.name = 'AppleSignInError';
  }
}

export default function useAuthService() {
  const loginModalContext = useContext(LoginModalContext);
  const session = useSession();
  const modals = useModals();
  const router = useRouter();
  const { getRecaptchaToken } = useRecaptcha();
  const { query } = useRouteQuery<LoginPageParams>();

  function doNavigation(onSuccessfulLogin?: () => void) {
    const destination = getCookieDestinationUrl();
    if (destination) {
      window.location.assign(destination);
      Cookies.remove('destination');
    } else if (onSuccessfulLogin) {
      onSuccessfulLogin();
    } else {
      router.push(`/?message=Logged in&messageType=success`, '/');
    }
  }

  async function tryLogin(
    username: string,
    password: string,
    {
      onChallenged,
      onSuccessfulLogin,
    }: {
      onChallenged?: (challengeToken: string, meta: IAMLoginMeta) => void;
      onSuccessfulLogin: () => void;
    },
  ) {
    const response = await postLogin(
      username,
      password,
      await getRecaptchaToken('login'),
    );
    if ('access_token' in response.data) {
      await session.login(
        response.data.access_token,
        response.data.refresh_token,
      );

      if (query.mode === 'developer') {
        setDeveloperPortalCookies(response.data);
      }

      doNavigation(onSuccessfulLogin);
    } else if (onChallenged != null) {
      onChallenged(response.data.challenge_token, response.meta!);
    } else {
      // Challenged but no handler provided, open the modal
      modals.openModal('loginRegister', {
        defaultView: 'challenge',
        initialChallengeData: {
          meta: response.meta!,
          token: response.data.challenge_token,
        },
      });
    }
  }

  async function tryRegister(
    email: string,
    username: string,
    password: string,
    referralCode: string | undefined,
    {
      onSuccessfulRegister,
    }: {
      onSuccessfulRegister?: () => void;
    },
  ) {
    const { data, meta } = await postRegister(
      email,
      username,
      password,
      await getRecaptchaToken('register'),
      referralCode,
    );

    await session.login(
      meta.authorization.access_token,
      meta.authorization.refresh_token,
    );
    await postAttributions();
    await fireJoinedEvent('Login and Password', referralCode);
    await onSuccessfulRegister?.();
    if (!loginModalContext?.skipOnboarding) {
      Cookies.set(
        'onboarding_destination' as KnownCookie,
        encodeURIComponent(router.asPath),
        {
          path: '/',
          expires: 365,
        },
      );
      await router.push(generateRegisterOnboardingLink());
    }
  }

  async function tryChallengePin(
    pin: string,
    challengeToken: string,
    {
      onSuccessfulLogin,
    }: {
      onSuccessfulLogin: () => void;
    },
  ) {
    const tokenAuthorization = await postChallengePin(pin, challengeToken);
    await session.login(
      tokenAuthorization.access_token,
      tokenAuthorization.refresh_token,
    );

    if (query.mode === 'developer') {
      setDeveloperPortalCookies(tokenAuthorization);
    }

    doNavigation(onSuccessfulLogin);
  }

  async function linkSocialAccount(
    socialDataType: SocialAuthType,
    {
      appleAuthorizationCode,
      onSuccessfulLink,
      token,
    }: {
      appleAuthorizationCode?: string;
      onSuccessfulLink?: () => void;
      token: string;
    },
  ) {
    try {
      const recaptchaToken = await getRecaptchaToken('social_auth');
      switch (socialDataType) {
        case 'Facebook':
          await postLinkFacebookAccount(token, recaptchaToken);
          onSuccessfulLink?.();
          break;
        case 'Apple':
          await postLinkAppleAccount(
            token,
            appleAuthorizationCode!,
            recaptchaToken,
          );
          onSuccessfulLink?.();
          break;
        case 'Google':
          await postLinkGoogleAccount(token, recaptchaToken);
          onSuccessfulLink?.();
          break;
      }
    } catch (e) {
      console.error('social account link error', e);
      throw e as ApiError;
    }
  }

  async function unlinkSocialAccount(
    socialDataType: SocialAuthType,
    accountId: string,
  ) {
    const recaptchaToken = await getRecaptchaToken('social_auth');
    let response: IAMUser;
    switch (socialDataType) {
      case 'Google':
        try {
          response = await deleteLinkGoogleAccount(accountId, recaptchaToken);
        } catch (e) {
          console.error('Google social account unlink error', e);
          throw e as ApiError;
        }
        break;
      default:
        throw new TypeError(
          'useAuthService: Invalid "socialAuthType" provided',
        );
    }
  }

  async function trySocialAuth(
    type: SocialAuthType,
    {
      appleAuthorizationCode,
      token,
      onSocialAuthExistingEmail,
      onSocialAuthNewUser,
      onSuccessfulLogin,
    }: {
      appleAuthorizationCode?: string;
      onSocialAuthExistingEmail?: SocialAuthCallback;
      onSocialAuthNewUser?: SocialAuthCallback;
      onSuccessfulLogin?: () => void;
      token: string;
    },
  ) {
    try {
      const recaptchaToken = await getRecaptchaToken('social_auth');

      let response: TokenAuthorization;
      switch (type) {
        case 'Facebook':
          response = await attemptSocialAuthFacebook(token, recaptchaToken);
          break;

        case 'Apple':
          if (!appleAuthorizationCode) {
            throw new AppleSignInError('No authorization code provided');
          }
          response = await attemptSocialAuthApple(
            token,
            appleAuthorizationCode,
            recaptchaToken,
          );
          break;
        case 'Google':
          response = await attemptSocialAuthGoogle(token, recaptchaToken);
          break;

        default:
          throw new TypeError('useAuthService: Invalid "type" provided');
      }

      await session.login(response.access_token, response.refresh_token);
      doNavigation(onSuccessfulLogin);
      return response;
    } catch (e) {
      const err = e as ApiError;
      const errorWithCode = err.errors?.find(c => !!c.code);

      switch (errorWithCode?.code) {
        case 'LINK_ACCOUNT_NO_LINKED_USER':
          if (onSocialAuthNewUser) {
            onSocialAuthNewUser({
              appleAuthorizationCode,
              type,
              token,
              errorMeta: err.meta as SocialAuthErrorMeta,
            });
          } else {
            modals.openModal('loginRegister', {
              onSuccessfulLogin: () => doNavigation(onSuccessfulLogin),
              defaultView: 'socialNewUser',
              initialSocialData: {
                appleAuthorizationCode,
                token,
                type,
                errorMeta: err.meta as SocialAuthErrorMeta,
              },
            });
          }
          return 'new_user';

        case 'LINK_ACCOUNT_EMAIL_MATCHED_USER':
          if (onSocialAuthExistingEmail) {
            onSocialAuthExistingEmail({
              appleAuthorizationCode,
              type,
              token,
              errorMeta: err.meta as SocialAuthErrorMeta,
            });
          } else {
            modals.openModal('loginRegister', {
              onSuccessfulLogin: () => doNavigation(onSuccessfulLogin),
              defaultView: 'socialExistingEmail',
              initialSocialData: {
                appleAuthorizationCode,
                token,
                type,
                errorMeta: err.meta as SocialAuthErrorMeta,
              },
            });
          }
          return 'existing_email';

        default:
          throw err;
      }
    }
  }

  return {
    doNavigation,
    tryChallengePin,
    tryLogin,
    tryRegister,
    trySocialAuth,
    linkSocialAccount,
    unlinkSocialAccount,
  };
}
