'use client';

import React from 'react';
import { Button } from 'ui';
import { useNewModalContext } from '~/contexts/NewModalContext';

interface Props {
  className?: string;
  fluid?: boolean;
  children: React.ReactNode;
}

const NewModalCloseActionButton: React.FC<Props> = ({
  className,
  children,
  fluid,
}) => {
  const context = useNewModalContext();

  return (
    <Button
      variant="outlined"
      onClick={context.onClose}
      fluid={fluid}
      className={className}
    >
      {children}
    </Button>
  );
};

export default NewModalCloseActionButton;
