import { useRouter } from 'next/router';
import React from 'react';
import LeftNavCmsNavigation from '~/components/Chrome/Header/Mobile/LeftNavCmsNavigation';
import { navigationPageViewId } from '~/constants/hardcodedPageViewIds';
import useNavigation from '~/hooks/useNavigation';
import usePageView from '~/hooks/usePageView';

interface Props {}

const PagesDirLeftNavCmsNavigation: React.FC<Props> = () => {
  const { data: pageView } = usePageView(navigationPageViewId);
  const router = useRouter();

  const navigation = useNavigation(pageView!);

  if (!navigation) {
    return null;
  }

  return (
    <LeftNavCmsNavigation
      navigation={navigation}
      route={router.route}
      category={
        router.query?.item?.[0] ||
        router.query?.lander?.[0] ||
        router.query?.shop?.[0]
      }
    />
  );
};

export default PagesDirLeftNavCmsNavigation;
