import { RailsSwapDetail } from '~/typings/services/rails/swaps';
import { fetcher, makeFetcher } from 'fetcher-session';

export const printLabel = (swapId: number, returnLabel?: boolean) =>
  fetcher<RailsSwapDetail>(
    returnLabel
      ? `/v2/swaps/${swapId}/dispute/print_label`
      : `/v1/swaps/${swapId}/print_label`,
    {
      method: 'put',
    },
  );

export const fetchBadgeCount = makeFetcher<{ badge: number }, []>(
  () => '/v1/swaps/badge',
);

export const postCreateSwap = makeFetcher<
  RailsSwapDetail,
  [
    itemId: number | string,
    price?: number | string, // Required for backend offer validation to work
    selectedVariationId?: string | number,
  ]
>(
  (
    itemId: number | string,
    price: number | string,
    selectedVariationId?: string | number,
  ) => [
    '/v1/swaps',
    {
      body: {
        swap: {
          item_id: itemId,
          offer: {
            amount: +price || undefined,
            item_variation_id: selectedVariationId,
          },
        },
      },
      method: 'post',
    },
  ],
);

export interface OfferByItemId {
  swap_id: number;
  state: RailsSwapDetail['state'];
  amount: number;
  created_at: string;
  buyer: Pick<RailsSwapDetail['buyer'], 'id' | 'username'>;
  seller_offer: boolean;
  counter_offer: boolean;
}

export const fetchOffersByItemId = (itemId: number | string) =>
  fetcher<OfferByItemId[]>(`/v1/items/${itemId}/offers`);
