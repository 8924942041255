import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';
import { clsx } from 'clsx';
import React, { CSSProperties } from 'react';

export interface BaseNewModalProps {
  children?: React.ReactNode;
  onClose: () => void;
  open: boolean;

  className?: any;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  style?: CSSProperties;
  initialFocus?: React.MutableRefObject<any>;

  variant?: 'paywall';
}

const BaseNewModal = React.forwardRef<HTMLDivElement, BaseNewModalProps>(
  (
    { initialFocus, onClose, size = 'md', className, style, children, variant },
    ref,
  ) => (
    <Dialog
      static
      className="fixed inset-0 overflow-y-auto print:relative"
      onClose={onClose}
      initialFocus={initialFocus}
      style={{
        zIndex: 1000,
      }}
      ref={ref}
    >
      <div
        className={clsx(
          'flex min-h-screen items-start justify-center px-4 py-20 text-center print:block print:min-h-0 print:p-0 print:text-left',
          // If we want to center in the panel
          // !mobile && 'pl-80',
        )}
      >
        {/* Overlay */}
        <TransitionChild
          as={DialogBackdrop}
          className="bg-true-black/60 fixed inset-0 transition-opacity print:hidden"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />

        <TransitionChild
          as={React.Fragment}
          enter="ease-out duration-300 origin-top"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200 origin-top"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <DialogPanel
            className={clsx(
              'block w-full rounded-lg pt-4 text-left shadow-xl transition-all print:block print:shadow-none sm:my-8',
              {
                'max-w-sm': size === 'sm',
                'max-w-md': size === 'md',
                'max-w-lg': size === 'lg',
                'max-w-screen-md': size === 'xl',
                'bg-white': !variant,
                'text-white': variant === 'paywall',
              },
              className,
            )}
            style={{
              ...style,
              // something is applying a "fixed" class, we want to override that
              position: 'relative',
              backgroundColor:
                variant === 'paywall'
                  ? window.navigator.platform === 'Win32'
                    ? '#243932'
                    : '#284139'
                  : undefined,
            }}
          >
            {children}
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  ),
);

export default BaseNewModal;
