import { fetcher, makeFetcher } from 'fetcher-session';
import {
  RailsCategory,
  RailsDetailGuidance,
} from '~/typings/services/rails/item';
import { RailsBrand } from '~/typings/services/rails/brand';

export type DetailGuidanceContext = 'list' | 'shop';

export const fetchDetailGuidance = makeFetcher<
  RailsDetailGuidance[],
  [categoryId: number | string, context: DetailGuidanceContext]
>(
  (categoryId: number | string, context: DetailGuidanceContext) =>
    `/v1/categories/${categoryId}/details/meta?context=${context}`,
);

export const fetchCategories = async () => {
  const categories = await fetcher<RailsCategory[]>('/v1/categories');

  return categories.sort((a, b) => a.name.localeCompare(b.name));
};

export const fetchCategoryById = makeFetcher<
  RailsCategory,
  [categoryId: number | string]
>((categoryId: number | string) => `/v1/categories/${categoryId}`);

export const fetchBrandsByCategoryId = (categoryId: number) =>
  fetcher<RailsBrand[]>('/v1/brands', {
    params: {
      category_id: categoryId,
      page_size: 5000,
      featured: false,
    },
  });
