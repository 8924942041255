import { RailsLander } from '~/typings/services/rails/landers';
import { FlattenedFilterState } from '~/typings/services/rails/facets';

// Mutates filterState
export default function stripLanderQueryFromFilterState(
  lander: RailsLander | undefined,
  filterState: FlattenedFilterState,
) {
  // Remove lander query key values from filter state
  if (lander?.query) {
    const landerQueryKeys = lander?.query ? Object.keys(lander.query) : [];

    // Only care about category + detail landers
    if (landerQueryKeys.length > 1) {
      for (let i = 0; i < landerQueryKeys.length; i++) {
        const key = landerQueryKeys[i];
        const filterStateKeyValue = filterState[key];

        if (filterStateKeyValue) {
          const landerQueryKeyValue = lander.query[key];

          if (
            Array.isArray(filterStateKeyValue) &&
            Array.isArray(landerQueryKeyValue)
          ) {
            // Only allow values not in lander query
            filterState[key] = filterStateKeyValue.filter(
              value => !landerQueryKeyValue.includes(value),
            );
          } else {
            delete filterState[key];
          }
        }
      }
    }
  }
}
