import { useRouter } from 'next/router';
import React from 'react';
import { Message } from 'ui';

const PageMessage: React.FC = () => {
  const {
    query: { message, messageType },
  } = useRouter();

  if (!message) {
    return null;
  }

  return (
    <div className="container my-4 px-0">
      <Message
        info={messageType === 'info'}
        error={messageType === 'error'}
        success={messageType === 'success'}
        warning={!messageType || messageType === 'warning'}
      >
        {message}
      </Message>
    </div>
  );
};

export default PageMessage;
