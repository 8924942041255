import { useQuery } from '@tanstack/react-query';
import React from 'react';
import ButterModelDescription from '~/components/ButterCMS/ModelDescription';
import { TopCompetitorsLegacyArchitecture } from '~/components/ButterCMS/TopCompetitorsLegacyArchitecture';
import ExpanderWithLabel from '~/components/Expander/ExpanderWithLabel';
import MarkdownContent from '~/components/MarkdownContent';
import BlockSection from '~/components/legos/BlockSection';
import BuyingGuidePopularModelTile from '~/components/legos/Tiles/BuyingGuidePopularModelTile';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { fetchButterModelDescription } from '~/lib/buttercms/model-description/api';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}

const BuyingGuideBlock: React.FC<Props> = ({ block, headerType }) => {
  const { lander, filterState } = useFacetFiltering();
  const { data: butterModelDescription } = useQuery(
    ['butter-model-description', lander?.id],
    () =>
      fetchButterModelDescription(
        `model-${lander?.model?.id}`,
        filterState.preview == '1',
      ),
    {
      enabled: !!lander && !!lander.model?.id,
    },
  );

  if (!block.tiles?.length) {
    return null;
  }

  if (butterModelDescription) {
    return (
      <section className="container" id="buying-guide">
        <ButterModelDescription
          page={butterModelDescription.fields}
          topCompetitors={TopCompetitorsLegacyArchitecture(
            butterModelDescription.fields,
          )}
        />
      </section>
    );
  }

  return (
    <BlockSection
      block={block}
      headerType="h2"
      className="divide-y"
      id="buying-guide"
    >
      {block.tiles.map((tile, i) => {
        if (tile.display_type === 'popular_models') {
          return (
            <BuyingGuidePopularModelTile
              defaultExpanded={i === 0}
              tile={tile}
            />
          );
        }

        if (
          tile.display_type === 'model_description' &&
          lander?.model?.description
        ) {
          return (
            <ExpanderWithLabel
              key={tile.id}
              defaultExpanded={i === 0}
              label={
                <h3 className="text-left text-base font-semibold">
                  {tile.title}
                </h3>
              }
            >
              <MarkdownContent
                className="mb-4"
                source={lander.model.description}
              />
            </ExpanderWithLabel>
          );
        }

        if (!tile.description) {
          return null;
        }

        return (
          <ExpanderWithLabel
            key={tile.id}
            defaultExpanded={i === 0}
            label={
              <h3 className="text-left text-base font-semibold">
                {tile.title}
              </h3>
            }
          >
            <MarkdownContent className="mb-4" source={tile.description} />
          </ExpanderWithLabel>
        );
      })}
    </BlockSection>
  );
};

export default BuyingGuideBlock;
