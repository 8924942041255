import { FlattenedFilterState } from '~/typings/services/rails/facets';

export default function getActiveFacetsCount(
  filterState: FlattenedFilterState,
  defaultSort = 'default',
) {
  let count = 0;

  // if (filterState.sort !== defaultSort) {
  //   count += 1;
  // }

  if (filterState.q) {
    count += 1;
  }

  if (filterState.recently_sold) {
    count += 1;
  }

  if (filterState.price_drop) {
    count += 1;
  }

  if (filterState.min_price! >= 0) {
    count += 1;
  }

  if (filterState.max_price! >= 0) {
    count += 1;
  }

  if (filterState.similar_to) {
    count += 1;
  }

  Object.entries(filterState).forEach(([facet, values]) => {
    if (
      facet === 'state' ||
      facet === 'seller' ||
      facet === 'facets' ||
      facet === 'category' ||
      facet === 'sort'
    ) {
      return;
    }

    if (Array.isArray(values)) {
      count += values.length;
    }
  });

  return count;
}
