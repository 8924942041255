import { useMobile } from 'common-nextjs';
import React from 'react';
import BaseBanner from '~/components/Chrome/Banners/BaseBanner';
import HelpLink from '~/components/Links/HelpLink';
import { useModals } from '~/contexts/ModalsContext';
import { useV2Session } from '~/hooks/useV1Session';
import WarningIcon from '~/public/static/svg/WarningIcon';

interface Props {}

const RestrictedBanner: React.FC<Props> = () => {
  const mobile = useMobile();
  const modals = useModals();

  const { data } = useV2Session();

  return (
    <BaseBanner className="bg-yellow-600 text-white">
      <WarningIcon />
      <div>
        {mobile ? 'Account restricted' : 'Your account has been restricted'}
      </div>
      <div className="w-full flex-1" />
      {data?.actions?.includes('unrestrict') ? (
        <button
          className="underline"
          onClick={() => modals.openModal('selfUnrestrict', {})}
        >
          Resolve
        </button>
      ) : (
        <HelpLink link="unrestrictForm" className="underline">
          Contact Support
        </HelpLink>
      )}
    </BaseBanner>
  );
};

export default RestrictedBanner;
