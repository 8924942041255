import React from 'react';
import { HeroContent, HeroImage } from '~/components/Homepage/Elements';
import BlockButton from '~/components/legos/BlockButton';
import useTileVersion from '~/hooks/useTileVersion';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
}

const SingleBlock: React.FC<Props> = ({ block }) => {
  const tileVersion = useTileVersion(block);

  const heroContent = (
    <HeroContent className="less-shadow">
      <HeroImage
        alt={block.title}
        css={`
          z-index: -1;
        `}
        src={tileVersion.background_image_url}
      />

      <h2
        className="px-4 text-center font-bold"
        css={`
          color: ${tileVersion.text_color || '#fff'};
        `}
      >
        {tileVersion.title}
      </h2>

      {tileVersion.button_text && <BlockButton block={block} />}
    </HeroContent>
  );

  return (
    <div>
      {tileVersion.destination && !tileVersion.button_text ? (
        <a href={tileVersion.destination}>{heroContent}</a>
      ) : (
        heroContent
      )}
    </div>
  );
};

export default SingleBlock;
