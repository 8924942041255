import { clsx } from 'clsx';
import Head from 'next/head';
import React from 'react';
import { HeroImage } from '~/components/Homepage/Elements';
import { generateFastlyImageUrl } from '~/components/Image';
import { getTileVersion } from '~/hooks/useTileVersion';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  mobile: boolean;
  headerType: 'h1' | 'h2';
}

const LanderHeaderBlock: React.FC<Props> = ({ block, mobile, headerType }) => {
  const tileVersion = getTileVersion(block, mobile);

  const title = block.title || tileVersion.title;
  const subtitle = tileVersion.subtitle;
  const destination = block.destination || tileVersion.destination;
  const backgroundColor = block.background_color;
  const textColor =
    block.text_color ||
    tileVersion.text_color ||
    (backgroundColor ? '#fff' : '#4a4a4a');

  const backgroundImageUrl = generateFastlyImageUrl(
    tileVersion.background_image_url,
    {
      height: 700,
    },
  );

  const content = (
    <div
      className={clsx('relative py-10', backgroundImageUrl && 'min-h-[120px]')}
      style={{
        backgroundColor,
      }}
    >
      <Head>
        <link rel="preload" as="image" href={backgroundImageUrl} />
      </Head>

      {backgroundImageUrl && (
        <HeroImage alt={block.title} src={backgroundImageUrl} />
      )}

      <div
        className={clsx(
          'container relative mb-0 flex flex-col justify-center space-y-4',
          tileVersion.halignment === 'right'
            ? 'text-right'
            : tileVersion.halignment === 'center'
              ? 'text-center'
              : 'text-left',
        )}
        style={{
          zIndex: 1,
        }}
      >
        {subtitle && (
          <div
            className="text-base"
            style={{
              color: textColor,
            }}
          >
            {subtitle}
          </div>
        )}

        {title &&
          React.createElement(
            headerType,
            {
              style: {
                marginTop: subtitle ? 8 : 0,
              },
            },
            <span
              className="text-4xl font-bold"
              style={{
                color: textColor,
              }}
            >
              {title}
            </span>,
          )}
      </div>
    </div>
  );

  if (destination) {
    return (
      <a href={destination} className="block">
        {content}
      </a>
    );
  }

  return content;
};

export default LanderHeaderBlock;
