import Link from 'next/link';
import React from 'react';

import { CustomLinkProps } from 'common-nextjs';

type Props = CustomLinkProps & {
  page: 'invite';
};

const ReferralLink: React.FC<Props> = ({ children, page, ...props }) => (
  <Link href={`/${page}`} {...props}>
    {children}
  </Link>
);

export default ReferralLink;
