import produce from 'immer';

export default function removeFromState(
  state: Record<string, any>,
  name: string,
  value?: string | number | number[] | string[],
) {
  return produce(state, draft => {
    if (value) {
      const detail = draft?.[name];

      if (detail) {
        // If the existing detail is an array...
        if (Array.isArray(detail)) {
          // find this value in the array, and filter it out
          const newValue = detail.filter(d => Number(d) !== Number(value));
          if (newValue.length === 0) {
            delete draft[name];
          } else {
            draft[name] = newValue;
          }
          return;
          // If it doesn't exist in the array, don't do anything.
        } else {
          // Otherwise, just wipe it out
          delete draft[name];
        }
      }

      return;
    }

    // If you don't pass a value, it will remove the entire detail
    delete draft[name];
  });
}
