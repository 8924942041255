import { useMemo } from 'react';
import { RailsPageView } from '~/typings/services/rails/tile';
import extractPathname from '~/utils/extractPathname';

export interface Navigation {
  title: string;
  destination: string;

  sections: NavigationSection[];
}

export interface NavigationSection {
  title: string;
  destination: string;

  children: NavigationChild[];
}

interface NavigationChild {
  title: string;
  destination: string;
}

export function generateNavigation(pageView?: RailsPageView | null) {
  if (!pageView) {
    return null;
  }

  const navigationList = new Array<Navigation>(pageView.blocks.length);

  for (let i = 0; i < pageView.blocks.length; i++) {
    const block = pageView.blocks[i];

    if (!block.tiles) {
      continue;
    }

    const sections: NavigationSection[] = [];

    let sectionBuffer: NavigationSection = {
      title: '',
      destination: '',
      children: [],
    };

    for (const tile of block.tiles) {
      if (tile.display_type === 'nav_group_header') {
        if (sectionBuffer.title && sectionBuffer.destination) {
          sections.push(sectionBuffer);
        }

        sectionBuffer = {
          title: tile.title,
          destination: extractPathname(tile.destination),
          children: [],
        };
      } else if (tile.display_type === 'nav_link') {
        sectionBuffer.children.push({
          title: tile.title,
          destination: extractPathname(tile.destination),
        });
      }
    }

    if (sectionBuffer.title && sectionBuffer.destination) {
      sections.push(sectionBuffer);
    }

    navigationList[i] = {
      sections,
      title: block.title,
      destination: extractPathname(block.destination),
    };
  }

  return navigationList;
}

export default function useNavigation(
  pageView?: RailsPageView | null,
): Navigation[] | null {
  return useMemo<Navigation[] | null>(() => {
    return generateNavigation(pageView);
  }, [pageView?.id]);
}
