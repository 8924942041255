'use client';

import { clsx } from 'clsx';
import React, { useState } from 'react';
import ChevronRight from '~/public/static/svg/ChevronRight';

type Props = React.HTMLProps<HTMLButtonElement> & {
  chevron?: boolean;
  children?: React.ReactNode | ((open: boolean) => React.ReactNode);
  className?: string;
  expanded?: boolean;
  href?: string;
  rightContent?: React.ReactNode;

  // Always render children, defaults to true
  static?: boolean;
  text: React.ReactNode;
  textClassName?: string;
  variant?: 'dark';
};

const MobileMenuItem: React.FC<Props> = ({
  chevron = true,
  children,
  className,
  expanded: initiallyExpanded,
  href,
  onClick,
  rightContent,
  static: renderStatic = true,
  text,
  textClassName,
  variant,
  type,
  ...props
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded || false);

  const renderedChildren =
    typeof children === 'function' ? children(expanded) : children;
  const hasChildren = React.Children.count(renderedChildren) > 0;

  const Element = href ? 'a' : 'button';

  return (
    <li>
      {/* @ts-ignore */}
      <Element
        className={clsx(
          'flex h-12 w-full items-center border-b border-black px-2 py-3 text-left',
          className,
        )}
        href={href}
        onClick={e => {
          if (onClick) {
            onClick(e);
          }

          if (!e.defaultPrevented) {
            if (hasChildren) {
              setExpanded(!expanded);
            }
          }
        }}
        {...props}
        type={Element === 'button' ? 'button' : undefined}
      >
        <span
          className={clsx(
            textClassName || (variant === 'dark' ? 'text-white' : undefined),
            'truncate',
          )}
        >
          {text}
        </span>

        <span className="mr-auto block" />
        {rightContent}
        {chevron && (
          <ChevronRight
            className="ml-4"
            height={12}
            width={8}
            fill={variant === 'dark' ? '#4a4a4a' : '#fff'}
            style={{
              transform: expanded ? 'rotate(90deg)' : undefined,
            }}
          />
        )}
      </Element>

      {hasChildren && renderStatic && (
        <ul
          className={clsx('pl-4', expanded ? 'block' : 'hidden')}
          aria-expanded={expanded}
        >
          {renderedChildren}
        </ul>
      )}

      {hasChildren && !renderStatic && expanded && (
        <ul className="pl-4">{renderedChildren}</ul>
      )}
    </li>
  );
};

export default MobileMenuItem;
