import React from 'react';
import { Skeleton } from 'ui';
import FeatheredOverflowContainer from '~/components/FeatheredOverflowContainer';
import Image from '~/components/Image';
import ShopLink from '~/components/Links/ShopLink';
import { RailsItemModel } from '~/typings/services/rails/item';

interface Props {
  models: RailsItemModel[];
}

export function ModelScrollContainerFallback() {
  return (
    <FeatheredOverflowContainer className="snap-x gap-4 pb-2">
      {[...Array(8)].map((_, i) => (
        <div
          key={i}
          className="flex w-72 flex-shrink-0 snap-start scroll-ml-8 gap-4 rounded shadow-lg"
        >
          <Skeleton className="h-28 w-28 flex-shrink-0 rounded-l object-cover" />

          <div className="flex flex-col justify-between py-4 pr-4">
            <div className="mb-1">
              <Skeleton className="mb-1 h-4 w-32 font-semibold" />
              <Skeleton className="h-4 w-12 font-semibold" />
            </div>
            <Skeleton className="h-4 w-20 text-sm" />
          </div>
        </div>
      ))}
    </FeatheredOverflowContainer>
  );
}

const ModelsScrollContainer: React.FC<Props> = ({ models }) => {
  return (
    <FeatheredOverflowContainer className="snap-x gap-4 pb-2" scrollButton>
      {models.map(model => {
        const image =
          model.primary_image?.thumb_url || model.images?.[0]?.thumb_url;

        return (
          <ShopLink
            key={model.id}
            className="flex w-72 flex-shrink-0 snap-start scroll-ml-8 gap-4 rounded shadow"
            path={model.url}
          >
            {image ? (
              <Image
                className="h-28 w-28 flex-shrink-0 rounded-l object-cover"
                src={image}
                useFastly={{
                  height: 192,
                  width: 192,
                  crop: '1:1',
                }}
              />
            ) : (
              <div />
            )}

            <div className="flex flex-col justify-between py-4 pr-4">
              <div className="mb-1 font-semibold">
                {model.brand_name} {model.name}
              </div>

              <div className="text-sm">
                <span className="font-semibold text-green-500">
                  {model.available_count}
                </span>{' '}
                <span className="text-slate-green-500">Available</span>
              </div>
            </div>
          </ShopLink>
        );
      })}
    </FeatheredOverflowContainer>
  );
};

export default ModelsScrollContainer;
