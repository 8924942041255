import { Transition } from '@headlessui/react';

export function ScaleFade({
  show,
  children,
  ...props
}: { show: boolean } & any) {
  return (
    <Transition
      as="div"
      show={show}
      {...props}
      enter="transform transition ease-in-out duration-300"
      enterFrom="scale-75 opacity-0"
      enterTo="scale-100 opacity-100"
      leave="transform transition ease-in-out duration-300"
      leaveFrom="scale-100 opacity-100"
      leaveTo="scale-75 opacity-0"
    >
      {children}
    </Transition>
  );
}
