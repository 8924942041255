import { useQuery } from '@tanstack/react-query';
import { selectEnv, useDeviceContext } from 'common-nextjs';
import { Button } from 'ui';
import { fetchIamUserSessions } from '~/api/auth';
import Image from '~/components/Image';
import { useSession } from '~/contexts/SessionContext';
import useKnownCookie from '~/hooks/useKnownCookie';
import ICON_120 from '~/public/static/icons/icon-120x120.png';
import CloseIcon from '~/public/static/svg/CloseIcon';
import Stars from '~/public/static/svg/Stars';
import { clickedAppCta } from '~/services/analytics/events/generic';

export const genericAppDownloadLink = 'https://onelink.to/sidelineswap';

const SmartAppBanner = () => {
  const session = useSession();
  const deviceContext = useDeviceContext();
  const [hiddenViaCookie, setCookie] = useKnownCookie('hide_app_banner');

  const isIos = deviceContext?.os?.toLowerCase() === 'ios';

  const { data: sessions } = useQuery(
    ['sessions'],
    () => fetchIamUserSessions(isIos ? 'ios' : 'android'),
    {
      enabled: session.loggedIn && !hiddenViaCookie && isIos,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  if (hiddenViaCookie) {
    return null;
  }

  if (!isIos) {
    return null;
  }

  const hasHadAnAppSession = sessions?.length! >= 1;

  const url = hasHadAnAppSession
    ? isIos
      ? 'sidelineswap://browse'
      : selectEnv('sidelineswap://', 'sidelineswap-staging://')
    : genericAppDownloadLink;

  const handleClick = () => {
    clickedAppCta(isIos ? 'ios' : 'android', 'banner');
    window.location.replace(url);

    setTimeout(() => {
      // If you clicked the Open/Get button, but the document is _still_ visible, it means they don't have the app.
      // So take them to the store
      if (document.visibilityState === 'visible') {
        window.location.replace(genericAppDownloadLink);
      }
    }, 1500);
  };

  return (
    <div className="flex items-center space-x-2 border-b px-2 py-2">
      <button
        className="-m-3 p-4"
        onClick={() =>
          setCookie('1', {
            expires: 7,
          })
        }
      >
        <CloseIcon />
      </button>

      <button className="flex-shrink-0" onClick={handleClick}>
        <Image src={ICON_120} className="h-12 w-12 rounded" />
      </button>

      <div className="flex-1">
        <b className="text-sm">SidelineSwap</b>
        <div style={{ fontSize: 11 }}>
          The fastest way to buy and sell gear.
        </div>

        <div className="flex items-center">
          <Stars />
          <div className="ml-1 text-sm leading-tight">18k+</div>
        </div>
      </div>

      <Button variant="outlined" className="!px-3" onClick={handleClick}>
        {hasHadAnAppSession ? 'OPEN' : 'GET'}
      </Button>
    </div>
  );
};

export default SmartAppBanner;
