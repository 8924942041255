import { TokenAuthorization } from '~/typings/services/iam/auth';
import Cookies from 'js-cookie';

export default function setDeveloperPortalCookies(
  tokenAuthorization: TokenAuthorization,
) {
  Cookies.set('sls_dp_access_token', tokenAuthorization.access_token, {
    path: '/',
    domain: '.developer.sidelineswap.com',
    expires: 3000,
  });

  Cookies.set('sls_dp_refresh_token', tokenAuthorization.refresh_token, {
    path: '/',
    domain: '.developer.sidelineswap.com',
    expires: 3000,
  });
}
