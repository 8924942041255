import { themeGet } from '@styled-system/theme-get';
import { useQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import Link from 'next/link';
import { Router } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { displayPrice } from 'utils';
import { fetchSpendableBalance } from '~/api/sellerAccount';
import { Avatar } from '~/components/Avatar';
import LogoutOption from '~/components/Chrome/Header/Desktop/LogoutOption';
import NavActionChild from '~/components/Chrome/Header/Desktop/NavActionChild';
import GiftCardLink from '~/components/Links/GiftCardLink';
import HelpLink from '~/components/Links/HelpLink';
import LockerLink from '~/components/Links/LockerLink';
import MyPurchasesLink from '~/components/Links/MyPurchasesLink';
import NewTabLink from '~/components/Links/NewTabLink';
import ReferralLink from '~/components/Links/ReferralLink';
import SellerDashboardLink from '~/components/Links/SellerDashboardLink';
import SettingsPageLink from '~/components/Links/SettingsPageLink';
import ValueGuideLink from '~/components/Links/ValueGuideLink';
import { SidelineProBadge } from '~/components/SidelinePro/ProBadges';
import { useSession } from '~/contexts/SessionContext';
import { useDelayFn } from '~/hooks/useDelayFn';
import useIsPowerSeller from '~/hooks/useIsPowerSeller';
import { useIsSidelineProAvailable } from '~/hooks/useIsSidelinePro';
import { useV2Session } from '~/hooks/useV1Session';
import ReferralDollar from '~/public/static/svg/ReferralDollar';

const AvatarBlock = styled.div<any>`
  display: flex;
  align-self: flex-end;

  // Margin top to put it in better center align with the rest of the nav
  margin-top: -4px;

  position: relative;
  font-size: 0.75rem;

  ${NavActionChild} {
    right: 0;
    top: 48px;

    .username {
      padding: 4px 8px;
      color: ${themeGet('colors.black')};
    }
  }
`;

const AvatarSection: React.FC = () => {
  const { user } = useSession();
  const [open, setOpen] = useState(false);

  const { data: spendableBalance, status } = useQuery(
    ['spendable_balance'],
    fetchSpendableBalance,
    { staleTime: 500000, enabled: open },
  );
  const proAvailable = useIsSidelineProAvailable();

  const { data: v2Session } = useV2Session();
  const isPowerSeller = useIsPowerSeller();

  const [callDelay, cancel] = useDelayFn(setOpen, 150);

  function handleClose() {
    cancel();
    setOpen(false);
  }

  function closeViaNavigation() {
    handleClose();
  }

  function handleOpen() {
    cancel();
    callDelay(true);
  }

  function handleMouseOut() {
    cancel();
    callDelay(false);
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', closeViaNavigation);
    return () => Router.events.off('routeChangeStart', closeViaNavigation);
  }, []);

  if (!user) {
    return null;
  }

  return (
    <AvatarBlock>
      <button>
        <Avatar
          avatarUrl={v2Session?.avatar_url}
          alt="Your avatar"
          onFocus={handleOpen}
          onBlur={handleClose}
          onMouseOver={handleOpen}
          onMouseOut={handleMouseOut}
          aria-controls="avatar-menu"
          aria-label="View account navigation"
          aria-haspopup={true}
          aria-expanded={open ? true : undefined}
        />
      </button>

      <div
        className={clsx('nav-action-child right-0 mt-3', { open })}
        onFocus={() => {
          cancel();
          setOpen(true);
        }}
        onBlur={handleClose}
        onMouseOver={() => {
          cancel();
          setOpen(true);
        }}
        onMouseOut={handleMouseOut}
        style={{
          zIndex: 100,
        }}
      >
        <ul role="menu" id="avatar-menu" aria-hidden={!open}>
          <li>
            <LockerLink role="menuitem" username={user.username}>
              <b>@{user.username}</b>
            </LockerLink>
          </li>

          {status !== 'error' && (
            <li>
              <SellerDashboardLink role="menuitem">
                Balance:{' '}
                {spendableBalance != null && (
                  <strong>{displayPrice(spendableBalance.amount)}</strong>
                )}
              </SellerDashboardLink>
            </li>
          )}

          <li>
            <LockerLink role="menuitem" username={user.username}>
              My Locker
            </LockerLink>
          </li>

          <li>
            <GiftCardLink role="menuitem">Gift Cards</GiftCardLink>
          </li>

          <li>
            <ValueGuideLink role="menuitem">Value Guide</ValueGuideLink>
          </li>

          <li>
            <ReferralLink
              page="invite"
              role="menuitem"
              style={{
                display: 'flex',
              }}
            >
              <div className="mr-2">Invite Friends</div>
              <div>
                <ReferralDollar />
              </div>
            </ReferralLink>
          </li>

          <li>
            <SellerDashboardLink role="menuitem">
              Seller Dashboard
            </SellerDashboardLink>
          </li>

          <li>
            <MyPurchasesLink role="menuitem">My Purchases</MyPurchasesLink>
          </li>

          {proAvailable && (
            <li>
              <Link role="menuitem" href="/settings/sideline-pro">
                Sideline <SidelineProBadge />
              </Link>
            </li>
          )}

          <li>
            <SettingsPageLink page="account" role="menuitem">
              My Settings
            </SettingsPageLink>
          </li>

          <li>
            <SettingsPageLink page="vacation" role="menuitem">
              Vacation Mode
            </SettingsPageLink>
          </li>

          {isPowerSeller && (
            <li>
              <NewTabLink href="https://calendly.com/sidelineswap-emma/30min">
                Schedule a Call
              </NewTabLink>
            </li>
          )}

          <li>
            <HelpLink link="default" role="menuitem">
              Help Center
            </HelpLink>
          </li>

          <LogoutOption />
        </ul>
      </div>
    </AvatarBlock>
  );
};

export default AvatarSection;
