import React from 'react';
import ShopLink from '~/components/Links/ShopLink';
import { RailsPredictiveSearchResult } from '~/typings/services/rails/predictiveSearch';

interface Props {
  className?: string;
  result: RailsPredictiveSearchResult;
}

const PredictiveResultLink: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  result,
}) => (
  <ShopLink
    className={className}
    path={result.path}
    type={result.type === 'navigation' ? 'nav' : undefined}
  >
    {children}
  </ShopLink>
);

export default PredictiveResultLink;
