import useUserConfiguration from '~/hooks/useUserConfiguration';
import {
  RailsFeatureFlag,
  RailsUserConfiguration,
} from '~/typings/services/rails/session';

function hasFeature(
  userConfiguration: RailsUserConfiguration,
  feature: RailsFeatureFlag['slug'],
) {
  const hasFeature = !!userConfiguration.features.find(f => f.slug === feature);
  if (hasFeature) {
    return true;
  }

  if (feature === 'export_items') {
    return userConfiguration.settings.trade_in_account;
  }

  if (feature == 'insights' || feature === 'quick_inbox') {
    return userConfiguration.power_seller;
  }

  return false;
}

export default function useHasFeature(
  feature: RailsFeatureFlag['slug'],
): boolean {
  const { data } = useUserConfiguration();
  if (!data) {
    return false;
  }

  return hasFeature(data, feature);
}
