import { FlattenedFilterState } from '~/typings/services/rails/facets';
import { RailsLander } from '~/typings/services/rails/landers';
import stripLanderQueryFromFilterState from '~/utils/stripLanderQueryFromFilterState';

export default function getTransferableFilterState(
  lander: RailsLander | undefined,
  filterState: FlattenedFilterState,
  defaultSort = 'default',
) {
  const { category, sort, state, id, saved_search_id, close_to, ...rest } =
    filterState;

  const newFilterState = rest;

  if (sort !== defaultSort) {
    newFilterState.sort = sort;
  }

  if (!state?.includes('available')) {
    newFilterState.state = state;
  }

  stripLanderQueryFromFilterState(lander, newFilterState);

  if (filterState.sale) {
    newFilterState.sale = filterState.sale;
  }

  return newFilterState;
}
