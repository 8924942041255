import {
  RailsSellerAccountStats,
  RailsSwapCount,
  RailsSwapCountGroup,
  RailsSwapsInboxResponse,
} from '~/api/swapsInbox/types';
import {
  fetcher,
  fetcherSession,
  getSessionCookies,
  Session,
} from 'fetcher-session';

export const SWAPS_INBOX_DEFAULT_SORT = 'newest';

export const fetchSwapsCounts = async (session?: Session, type?: string) => {
  const swapCounts = await fetcherSession<RailsSwapCountGroup[]>(
    session,
    '/v2/swaps/counts',
    {
      params: {
        type,
      },
    },
  );

  let currentCount: RailsSwapCountGroup | undefined;
  let buyUnread = false;
  let sellUnread = false;

  for (let i = 0; i < swapCounts.length; i++) {
    const swapCount = swapCounts[i];
    if (swapCount.slug === type) {
      currentCount = swapCount;
    }

    if (swapCount.slug === 'buy') {
      buyUnread = swapCount.unread || false;
    }

    if (swapCount.slug === 'sell') {
      sellUnread = swapCount.unread || false;
    }
  }

  if (!currentCount) {
    currentCount = {
      slug: type || 'all',
      name: type || 'All',
      unread: false,
      children: swapCounts as unknown as RailsSwapCount[], // for backwards compatibility
    };
  }

  return {
    buyUnread,
    sellUnread,
    counts: currentCount?.children ?? [],
  };
};

export interface SwapsInboxOptions {
  type?: string;
  scope?: string;
  page?: number;
  keyword?: string;
  filters?: string[];
}

export const fetchSwapsInbox = (
  session?: Session,
  { type, page, scope, keyword, filters }: SwapsInboxOptions = {},
) => {
  const cookies = getSessionCookies(session);

  const pageSize = Number(cookies.sls_inbox_page_size) || 20;
  const sort = cookies.sls_inbox_sort || SWAPS_INBOX_DEFAULT_SORT;

  return fetcherSession<RailsSwapsInboxResponse>(session, '/v2/swaps', {
    dataOnly: false,
    params: {
      type,
      scope,
      page,
      sort,
      q: keyword,
      page_size: pageSize,
      filter: filters,
    },
  });
};

export const putStarSwap = (swapId: number) =>
  fetcher(`/v2/swaps/${swapId}/star`, {
    method: 'put',
  });

export const putUnstarSwap = (swapId: number) =>
  fetcher(`/v2/swaps/${swapId}/unstar`, {
    method: 'put',
  });

export const postMarkAllAsRead = (type: string, scope: string) =>
  fetcher('/v1/swaps/read', {
    method: 'post',
    params: {
      type,
      scope,
    },
  });

export const fetchSellerStats = () =>
  fetcher<{ trade_in_account: RailsSellerAccountStats | null }>(
    ({ uuid }) => `/v1/users/${uuid}/seller_account/trade_in_account`,
  );
