import React from 'react';

interface Props {
  style?: React.CSSProperties;
}

const BaseNav: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  style,
}) => {
  return (
    <nav
      className="bg-true-black fixed top-0 z-40 h-screen overflow-y-scroll px-4 pb-16 pt-4 transition-all duration-300"
      style={{
        ...style,
        width: 270,
      }}
    >
      {children}
    </nav>
  );
};

export default BaseNav;
