import { FlattenedFilterState } from '~/typings/services/rails/facets';
import filterStateToQueryString from '~/utils/filterStateToQueryString';

export default function filterStateToQueryStringNoCategory(
  filterState: FlattenedFilterState,
  ignoreKeys?: string[],
  defaultSort = 'default',
) {
  const { category: _, ...detailsWithoutCategory } = filterState;
  return filterStateToQueryString(
    detailsWithoutCategory,
    ignoreKeys,
    defaultSort,
  );
}
