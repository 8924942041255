'use client';

import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Button } from 'ui';
import { startFollowingUser, stopFollowingUser } from '~/api/locker';
import { useModals } from '~/contexts/ModalsContext';
import { useSession } from '~/contexts/SessionContext';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';

interface Props {
  children?: (handleFollow: () => void, following: boolean) => React.ReactNode;
  following: boolean;
  userId: number;
}

const FollowButton: React.FC<Props> = ({
  children,
  following: initialFollowing,
  userId,
}) => {
  const { loggedIn } = useSession();
  const modals = useModals();
  const [following, setFollowing] = useState(initialFollowing);

  const { isLoading, mutate: toggleFollow } = useMutation(
    () => (following ? stopFollowingUser(userId) : startFollowingUser(userId)),
    {
      onSuccess: () => (following ? setFollowing(false) : setFollowing(true)),
    },
  );

  function handleFollow() {
    if (loggedIn) {
      toggleFollow();
    } else {
      modals.openModal('loginRegister', {
        contextMessage: 'Log in to follow this seller',
        defaultView: 'login',
        onSuccessfulLogin: () => toggleFollow(),
      });
    }
  }

  if (typeof children === 'function') {
    return children(handleFollow, following);
  }

  return (
    <Button
      disabled={isLoading}
      size="small"
      color="turf-green"
      fluid
      variant={following ? 'outlined' : 'contained'}
      onClick={handleFollow}
      className="flex items-center justify-center space-x-1"
      style={{
        minWidth: '100px',
      }}
    >
      <FavoriteIcon fill="currentColor" height={18} active={following} />
      <span>{following ? 'Followed' : 'Follow'}</span>
    </Button>
  );
};

export default FollowButton;
