import { useQuery } from '@tanstack/react-query';
import React from 'react';
import ExpanderWithLabel from '~/components/Expander/ExpanderWithLabel';
import HelpLink from '~/components/Links/HelpLink';
import SimpleModelTile from '~/components/Models/SimpleModelTile';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { RailsItemModel } from '~/typings/services/rails/item';
import { RailsTile } from '~/typings/services/rails/tile';

interface Props {
  defaultExpanded?: boolean;
  tile: RailsTile;
}

const BuyingGuidePopularModelTile: React.FC<Props> = ({
  defaultExpanded,
  tile,
}) => {
  const { category } = useFacetFiltering();
  const { data: models } = useQuery<RailsItemModel[]>([
    'buying-guide-popular-models',
    tile.id,
  ]);

  if (!models || models.length === 0) {
    return null;
  }

  const filteredModels = models?.filter(model => model.position != null);

  return (
    <ExpanderWithLabel
      key={tile.id}
      defaultExpanded={defaultExpanded}
      label={
        <h3 className="text-left text-base font-semibold">{tile.title}</h3>
      }
    >
      {category && (
        <div className="mb-4 text-sm">
          Check out the top selling {category.name.toLocaleLowerCase()} curated
          by our team of experts and informed by actual sales data. You can
          learn more about our product rankings{' '}
          <HelpLink
            link="productRankings"
            className="text-green-500 underline"
            title="Click here to learn more about our product rankings."
          >
            here
          </HelpLink>
          .
        </div>
      )}

      <div className="mb-4 space-y-4">
        {filteredModels?.map(model => (
          <SimpleModelTile key={model.id} model={model} />
        ))}
      </div>
    </ExpanderWithLabel>
  );
};

export default BuyingGuidePopularModelTile;
