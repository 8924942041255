import { clsx } from 'clsx';
import Link from 'next/link';
import React from 'react';

interface Props {
  className?: string;
}

const CartLink: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
}) => (
  <Link
    href="/cart"
    className={clsx('relative', className)}
    aria-label="View your cart"
    rel="nofollow noindex"
  >
    {children}
  </Link>
);

export default CartLink;
