import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { fetchModelsByPosition } from '~/api/models';
import ShopLink from '~/components/Links/ShopLink';
import ModelsScrollContainer from '~/components/Models/ModelsScrollContainer';
import BlockSection from '~/components/legos/BlockSection';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { RailsBlock } from '~/typings/services/rails/tile';
import doesFilterStateContainADetail from '~/utils/doesFilterStateContainADetail';
import getReferenceModelsFromFacets from '~/utils/getReferenceModelsFromFacets';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}

const PopularModelsBlock: React.FC<Props> = ({ block, headerType }) => {
  const { filterState, lander, facets } = useFacetFiltering();
  const categoryId =
    Number(
      block.query?.category_id ||
        block.category?.id ||
        filterState?.category?.[0] ||
        lander?.query?.category?.[0] ||
        lander?.category?.id,
    ) || '';

  const brandId =
    Number(
      block.query?.brand_id ||
        lander?.query?.brand?.[0] ||
        filterState?.brand?.[0],
    ) || '';

  const { data: queryModels } = useQuery(['shop-models', block.id], () =>
    fetchModelsByPosition(categoryId, brandId, 8),
  );

  const facetModels = doesFilterStateContainADetail(filterState)
    ? getReferenceModelsFromFacets(facets)
    : null;

  const models =
    facetModels && facetModels.length > 0 ? facetModels : queryModels;

  if (!models || models.length === 0) {
    return null;
  }

  return (
    <BlockSection
      block={block}
      cta={
        <ShopLink
          className="flex flex-shrink-0 items-center gap-2 text-sm font-semibold text-green-500"
          path={block.destination}
          type="sm"
        >
          <span>
            See more <span className="sr-only">{block.title}</span>
          </span>
          <ArrowRightIcon className="h-4 w-4" />
        </ShopLink>
      }
      headerType={headerType}
      showCta={!!block.destination}
      id="popular-models"
    >
      <ModelsScrollContainer models={models} />
    </BlockSection>
  );
};

export default PopularModelsBlock;
