import { useQuery } from '@tanstack/react-query';
import { fetchModelById } from '~/api/models';
import { ButterModelDescriptionTopCompetitor } from '~/components/ButterCMS/ModelDescription';
import { ButterModelDescriptionPage } from '~/lib/buttercms/model-description/api';

function TopCompetitor({ modelId }: { modelId: number | string }) {
  const { data: model } = useQuery(['models', modelId], () =>
    fetchModelById(modelId),
  );
  if (!model || !model.primary_image) {
    return null;
  }

  return <ButterModelDescriptionTopCompetitor model={model} />;
}

export function TopCompetitorsLegacyArchitecture(
  page: ButterModelDescriptionPage,
) {
  return page.top_competitors.model_ids.map(({ model_id }) => (
    <TopCompetitor modelId={model_id} />
  ));
}
