import { clsx } from 'clsx';

export function Indicator({
  color,
  selected,
}: {
  color?: 'white';
  selected?: boolean;
}) {
  return (
    <div
      className={clsx(
        'h-2 w-2 rounded-full transition-opacity',
        selected ? 'opacity-100' : 'opacity-30',
        {
          'bg-white': color === 'white',
          'bg-green-500': !color,
        },
      )}
    />
  );
}
