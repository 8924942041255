// Promise that rechecks conditionFn every 50ms until 10 seconds. Resolves on complete,
// rejects on timeout
export default function waitUntil(
  conditionFn: () => boolean,
  timeout = 50,
  tryCount = 0,
): Promise<void> {
  return new Promise((resolve, reject) => {
    // Waits 10 seconds total
    if (tryCount > 200) {
      reject(new Error('waitUntil timed out'));
    }

    if (conditionFn()) {
      resolve();
    } else {
      setTimeout(
        () => waitUntil(conditionFn).then(resolve),
        timeout,
        tryCount + 1,
      );
    }
  });
}
