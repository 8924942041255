import { butterApi } from '~/lib/buttercms/api';

export const getButterPostsByTagForLanders = async (tag?: string) => {
  try {
    const response = await butterApi.post.list({
      tag_slug: tag,
      page_size: 3,
      exclude_body: true,
    });
    return response.data || null;
  } catch (e) {
    return null;
  }
};
