import { fetcher } from 'fetcher-session';
import { IAMRegistrationResponse } from '~/typings/services/iam/responses';
import {
  ChallengedTokenAuthorization,
  IAMChallengeRequest,
  IAMLoginMeta,
  IAMLoginRequest,
  IAMRegisterRequest,
  TokenAuthorization,
} from '~/typings/services/iam/auth';
import Cookies from 'js-cookie';
import { KnownCookie } from 'common-types';
import { recaptchaHeader } from '~/api/auth';

export const postLogin = (
  username: string,
  password: string,
  recaptchaToken: string,
) =>
  fetcher<
    | {
        data: TokenAuthorization;
        meta: undefined;
      }
    | {
        data: ChallengedTokenAuthorization;
        meta: IAMLoginMeta;
      }
  >('/iam/v1/auth/token', {
    body: {
      grant_type: 'client_credentials',
      username_or_email: username,
      password: password,
      test_2FA: true,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
    } as IAMLoginRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    dataOnly: false,
    method: 'post',
    skipRefresh: true,
    authHeader: false,
  });

export const postResetPassword = (email: string) =>
  fetcher('/iam/v1/verify/password', {
    body: {
      email,
    },
    method: 'post',
  });

export const postNewResetPassword = (token: string, newPassword: string) =>
  fetcher<IAMRegistrationResponse>('/iam/v1/auth/user/password', {
    body: {
      new_password: newPassword,
    },
    dataOnly: false,
    accessToken: token,
    method: 'post',
  });

export const startVerifyingEmail = (email: string) =>
  fetcher('/iam/v1/verify/email', {
    body: {
      email,
      grant_type: 'email_address',
    },
    method: 'post',
  });

export const postEmailVerificationToken = (token: string) =>
  fetcher('/iam/v1/auth/user/verify', {
    body: {},
    method: 'post',
    accessToken: token,
  });

export const postRegister = (
  email: string,
  username: string,
  password: string,
  recaptchaToken: string,
  referralCode?: string,
) =>
  fetcher<IAMRegistrationResponse>('/iam/v1/auth/user', {
    body: {
      email,
      username,
      password,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      referral_code: referralCode,
      agree_with_policy: true,
    } as IAMRegisterRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    dataOnly: false,
    method: 'post',
  });

export const postChallengePin = (pin: string, challengeToken: string) =>
  fetcher<TokenAuthorization>('/iam/v1/auth/token/challenge', {
    body: {
      pin,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
    } as IAMChallengeRequest,
    method: 'post',
    accessToken: challengeToken,
    skipRefresh: true,
  });
