import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'ui';
import useOnClickOutside from 'use-onclickoutside';
import Hamburger from '~/components/Chrome/Header/Mobile/Hamburger';
import PredictiveResultsList from '~/components/Chrome/Header/PredictiveResultsList';
import usePredictiveSearch from '~/hooks/usePredictiveSearch';
import SearchIcon from '~/public/static/svg/SearchIcon';

type Props = {
  onClickHamburger: () => any;
};

const MobileHeaderSearch: React.FC<Props> = ({ onClickHamburger }) => {
  const containerRef = useRef(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();
  const [query, setQuery] = useState((router.query.q as string) ?? '');
  const [searchOpen, setSearchOpen] = useState(false);

  const [predictiveResults, onSubmit] = usePredictiveSearch(query);
  useOnClickOutside(containerRef, () => setSearchOpen(false));

  const openSearch = useCallback(() => {
    if (query.length > 1) {
      setSearchOpen(true);
    }
  }, [query]);

  function submitPredictive(event: React.FormEvent, query: string) {
    setSearchOpen(false);
    onSubmit(event, query);
  }

  useEffect(() => {
    if (query.length > 1 && document.activeElement == inputRef.current) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
    }
  }, [query]);

  return (
    <div
      className="relative flex px-4 py-2"
      ref={containerRef}
      css={`
        @media print {
          display: none;
        }
      `}
    >
      <button
        className="mr-2"
        onClick={onClickHamburger}
        aria-label="Open navigation to search categories, terms, and more."
      >
        <Hamburger />
      </button>

      <form
        role="search"
        action="/search"
        className="w-full"
        onSubmit={e => submitPredictive(e, query)}
      >
        <Input
          leftAdornment={
            <SearchIcon
              className="ml-2"
              fill="#02c874"
              height={40}
              width={17}
            />
          }
          name="q"
          placeholder="Search for new and used gear"
          inputCss={`
            padding-left: 0.5em;
          `}
          aria-label="Search SidelineSwap's listings"
          style={{ height: 40 }}
          onChange={e => setQuery(e.target.value)}
          onFocus={openSearch}
          ref={inputRef}
          value={query}
          type="search"
        />
      </form>

      {searchOpen && (
        <PredictiveResultsList
          className="shadow-lg"
          css={`
            position: absolute;
            z-index: 10;
            top: 48px;
            background: #fff;
            left: 0;
            right: 0;
          `}
          results={predictiveResults}
        />
      )}
    </div>
  );
};

export default MobileHeaderSearch;
