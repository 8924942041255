'use client';

import React from 'react';
import MobileMenuItem from '~/components/MobileMenuItem';
import useLinkSiloNavigation from '~/hooks/useLinkSiloNavigation';
import { Navigation } from '~/hooks/useNavigation';

interface Props {
  navigation: Navigation[];
  route: string;
  category?: string;
}

const LeftNavCmsNavigation: React.FC<Props> = ({
  navigation,
  route,
  category,
}) => {
  const siloNavigation = useLinkSiloNavigation(navigation, route, category);

  return (
    <>
      {siloNavigation.map((group, i) => {
        const siloSectionsLength = group.sections.length;
        const navigationSections = navigation.find(n => n.title === group.title)
          ?.sections;
        const navigationSectionsLength = navigationSections?.length ?? 0;

        // If the silo length is empty but navigation length is full, you're in a silo'd category
        // so hide the children until it's opened
        const isSiloed = siloSectionsLength !== navigationSectionsLength;

        if (navigationSectionsLength > 0) {
          return (
            <MobileMenuItem key={i} text={group.title} variant="dark">
              {/* @ts-ignore */}
              {isOpen => {
                let renderChildren = true;

                if (isSiloed) {
                  if (isOpen) {
                    renderChildren = true;
                  } else {
                    renderChildren = false;
                  }
                }

                return (
                  <>
                    {group.destination && (
                      <MobileMenuItem
                        text={`All ${group.title}`}
                        chevron={false}
                        variant="dark"
                        href={group.destination}
                      />
                    )}

                    {renderChildren &&
                      navigationSections?.map((section, j) =>
                        section.children.length > 0 ? (
                          <MobileMenuItem
                            key={j}
                            text={section.title}
                            variant="dark"
                          >
                            {section.destination && (
                              <MobileMenuItem
                                text={`All ${section.title}`}
                                chevron={false}
                                variant="dark"
                                href={section.destination}
                              />
                            )}

                            {section.children.map((child, k) => (
                              <MobileMenuItem
                                key={k}
                                text={child.title}
                                href={child.destination}
                                chevron={false}
                                variant="dark"
                              />
                            ))}
                          </MobileMenuItem>
                        ) : (
                          <MobileMenuItem
                            key={section.title}
                            text={section.title}
                            href={section.destination}
                            chevron={false}
                            variant="dark"
                          />
                        ),
                      )}
                  </>
                );
              }}
            </MobileMenuItem>
          );
        } else {
          return (
            <MobileMenuItem
              key={group.title}
              text={group.title}
              href={group.destination}
              chevron={false}
              variant="dark"
            />
          );
        }
      })}
    </>
  );
};

export default LeftNavCmsNavigation;
