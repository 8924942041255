import createSvg from '~/public/static/svg/createSvg';

export const AuctionIcon = createSvg(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 21"
    {...props}
  >
    <rect
      width="13.523"
      height="3.508"
      x="11.482"
      y="9.423"
      fill="#fff"
      stroke="currentColor"
      strokeWidth="1.5"
      rx="1.754"
      transform="rotate(30 11.482 9.423)"
    />
    <rect
      width="10.185"
      height="15.193"
      x="8.62"
      y="1.025"
      fill="#fff"
      stroke="currentColor"
      strokeWidth="1.5"
      rx="1.25"
      transform="rotate(30 8.62 1.025)"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="m7.375 4.35 8.674 5.008M2.768 11.333l8.673 5.007"
    />
  </svg>
));
