import { clsx } from 'clsx';
import React from 'react';
import FacebookIcon from '~/public/static/svg/FacebookIcon';

export interface FacebookButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'src'> {
  text?: string;
  variant?: 'outlined';
}

const FacebookButton: React.FC<FacebookButtonProps> = ({
  className,
  disabled,
  text,
  variant,
  ...props
}) => (
  <button
    className={clsx(
      'flex h-10 w-full items-center justify-center rounded px-4 text-sm  font-semibold',
      className,
    )}
    css={`
      background-color: ${disabled
        ? '#9b9b9b'
        : variant === 'outlined'
          ? '#fff'
          : '#4267b2'};
      color: ${variant === 'outlined' ? '#4267b2' : '#fff'};
    `}
    {...props}
    type="button"
  >
    <FacebookIcon height="auto" width={26} fill="currentColor" />
    <span className="ml-4">{text || 'Continue with Facebook'}</span>
  </button>
);

export default FacebookButton;
