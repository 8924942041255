import { useQuery } from '@tanstack/react-query';
import { UseQueryResult } from '@tanstack/react-query';
import { fetchMultipleItemsByIds } from '~/api/items';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

export default function useRecentlyViewedItems(
  max = 6,
): UseQueryResult<RailsThumbnailItem[]> {
  return useQuery(
    ['feed-recently-viewed'],
    () => {
      const value = localStorage.getItem('recently_viewed');
      try {
        if (value) {
          const itemIds: number[] = JSON.parse(value);
          return fetchMultipleItemsByIds(itemIds.slice(0, max));
        }
      } catch (err) {}
    },
    {
      enabled:
        typeof window !== 'undefined' &&
        typeof localStorage !== 'undefined' &&
        !!localStorage?.getItem,
    },
  );
}
