import React, { useContext } from 'react';
import { BaseUserSearchFilterResponse } from '~/typings/services/rails/userSearchFilters';

interface IUserSearchFilters {
  filters: BaseUserSearchFilterResponse;
}

export const UserSearchFilters = React.createContext<IUserSearchFilters>({
  filters: [],
});

export function useUserSearchFiltersContext() {
  return useContext(UserSearchFilters);
}
