import React from 'react';
import BaseBanner from '~/components/Chrome/Banners/BaseBanner';
import SellerDashboardLink from '~/components/Links/SellerDashboardLink';
import SettingsPageLink, {
  SettingsPageLinkPages,
} from '~/components/Links/SettingsPageLink';
import WarningIcon from '~/public/static/svg/WarningIcon';
import { RailsVacation } from '~/typings/services/rails/session';

interface Props {
  vacation: RailsVacation;
}

const VacationModeBanner: React.FC<Props> = ({ vacation }) => {
  let copy;
  let action = 'Update Settings';
  let actionPage: SettingsPageLinkPages = 'vacation';
  let actionOverride: React.ReactNode = null;

  if (
    vacation.generated_by === 'admin' ||
    vacation.generated_by_details === 'high_refund_rate'
  ) {
    copy = 'Please contact Support to re-activate your account.';
  }

  switch (vacation.generated_by_details) {
    case 'ebay_disconnect':
      copy = 'Your connected account is disconnected.';
      actionPage = 'connectedAccounts';
      break;

    case 'slow_import_frequency':
      action = 'Update Your Inventory';
      copy =
        'Inventory upload has slowed, your account is now in vacation mode.';
      break;

    case 'inform_act_compliance':
      copy = 'You are not compliant with the INFORM Act.';
      actionOverride = (
        <SellerDashboardLink page="informAct" className="underline">
          Submit INFORM Act Form here
        </SellerDashboardLink>
      );
      break;
  }

  return (
    <BaseBanner className="bg-yellow-600 text-white">
      <WarningIcon />

      <div>Vacation Mode is ON{copy && `. ${copy}`}</div>

      <div className="w-full flex-1" />

      {actionOverride || (
        <SettingsPageLink page={actionPage} color="white" className="underline">
          {action}
        </SettingsPageLink>
      )}
    </BaseBanner>
  );
};

export default VacationModeBanner;
