import React from 'react';
import { NavigationSection } from '~/hooks/useNavigation';

interface Props {
  sections: NavigationSection[];
}

const DesktopHeaderCmsSeoNavigationSection: React.FC<Props> = ({
  sections,
}) => {
  return (
    <ul className="hidden">
      {sections.map((section, j) => (
        <li key={j}>
          <a href={section.destination || '#'}>{section.title}</a>

          {section.children.length > 0 && (
            <ul>
              {section.children.map((child, k) => (
                <li key={k}>
                  <a href={child.destination || '#'}>{child.title}</a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default DesktopHeaderCmsSeoNavigationSection;
