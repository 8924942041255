import { useSession } from '~/contexts/SessionContext';

export function amIBuyerOrSeller(
  swapDetails?: { seller: { username: string }; buyer: { username: string } },
  username?: string,
) {
  if (!swapDetails || !username) {
    return undefined;
  }

  return swapDetails?.buyer?.username === username ? 'buyer' : 'seller';
}

/**
 * Returns the other user in the swap that's not you (could be buyer or seller)
 */
export function getSwapOtherUser(
  swapDetails?: { seller: { username: string }; buyer: { username: string } },
  username?: string,
) {
  const buyerOrSeller = amIBuyerOrSeller(swapDetails, username);
  if (buyerOrSeller == 'buyer') {
    return swapDetails?.seller;
  } else if (buyerOrSeller == 'seller') {
    return swapDetails?.buyer;
  } else {
    return undefined;
  }
}

export default function useBuyerOrSeller(swapDetails?: {
  seller: { username: string };
  buyer: { username: string };
}): 'buyer' | 'seller' | undefined {
  const session = useSession();

  return amIBuyerOrSeller(swapDetails, session?.user?.username);
}
