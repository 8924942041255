'use client';

import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import React from 'react';
import styled from 'styled-components';
import ChevronRight from '~/public/static/svg/ChevronRight';

function CarouselButton({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick: React.MouseEventHandler;
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'absolute flex items-center justify-center bg-white bg-opacity-60 outline-none transition-colors duration-300 hover:bg-opacity-90',
        className,
      )}
      style={{
        top: 'calc(50% - 24px)',
        padding: '17px 8px',
      }}
    >
      {children}
    </button>
  );
}

export const CarouselPreviousButton: React.FC<{
  onClick: React.MouseEventHandler;
}> = ({ onClick }) => (
  <CarouselButton className="rounded-r" onClick={onClick}>
    <ChevronRight height={14} fill="#4a4a4a" className="rotate-180 transform" />
  </CarouselButton>
);

export const CarouselNextButton: React.FC<{
  onClick: React.MouseEventHandler;
}> = ({ onClick }) => (
  <CarouselButton className="right-0 rounded-l" onClick={onClick}>
    <ChevronRight height={14} fill="#4a4a4a" />
  </CarouselButton>
);

export const CarouselPortalContainer: React.FC<{ itemId: number }> = ({
  itemId,
}) => (
  <div className="sticky top-[120px] z-10">
    <div
      className="absolute left-0 top-0 z-10 "
      id={`desktop-carousel-portal-${itemId}`}
    />
  </div>
);

export const DetailsTable = styled.table`
  font-size: 14px;
  width: 100%;

  tr {
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }

    td {
      padding-bottom: 8px;
      vertical-align: top;

      &:first-child {
        color: #9b9b9b;
      }

      &:last-child {
        font-weight: 600;
        text-align: right;
      }
    }
  }
`;

interface ItemDetailsSectionProps {
  header: string;
  linkElement?: any;
  linkProps?: any;
  showLink?: boolean;
}

export const ItemDetailsSection: React.FC<
  React.PropsWithChildren<ItemDetailsSectionProps>
> = ({
  children,
  header,
  linkElement: Element,
  linkProps,
  showLink = true,
}) => {
  const mobile = useMobile();

  const link = showLink && (
    <Element
      {...linkProps}
      className="flex items-center justify-between py-2 md:inline-flex md:p-0"
    >
      <span className="text-primary-500 text-sm font-normal md:mr-1">
        See More
      </span>
      <ChevronRight height={12} />
    </Element>
  );

  return (
    <section className="mb-4">
      <div className="mb-2 md:flex md:justify-between">
        <h3 className="text-base">{header}</h3>
        {!mobile && showLink && link}
      </div>

      {children}

      {mobile && showLink && link}
    </section>
  );
};
