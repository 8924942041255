'use client';

import createSvg from './createSvg';

export default createSvg(({ fill = '#4a4a4a', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    {...props}
  >
    <defs>
      <path
        id="2zbh8g78sa"
        d="M5.333 0L4.393 0.94 8.113 4.667 0 4.667 0 6 8.113 6 4.393 9.727 5.333 10.667 10.667 5.333z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(4.778 2.667)">
      <mask id="5uboarunnb" fill="#fff">
        <use xlinkHref="#2zbh8g78sa" />
      </mask>
      <use fill="#000" fillOpacity=".7" xlinkHref="#2zbh8g78sa" />
      <g fill={fill} mask="url(#5uboarunnb)">
        <path d="M0 0H16V16H0z" transform="translate(-2.667 -2.667)" />
      </g>
    </g>
  </svg>
));
