import {
  FlattenedFilterState,
  RailsFacetQueryOptions,
} from '~/typings/services/rails/facets';

export default function doesFilterStateContainADetail(
  filterState: FlattenedFilterState | RailsFacetQueryOptions,
) {
  // Remove some known keys
  const {
    model: _lm,
    category: _lc,
    brand: _lb,
    sort: _ls,
    state: _lst,
    price_drop: _lpd,
    sale: _sl,
    min_price: _lmp,
    max_price: _lmpx,
    page: _lp,
    id: _li,
    src: _lsr,
    saved_search_id: _lssi,
    recently_sold: _lrs,
    stid: _lstid,
    q: _lq,
    similar_to: _lsto,
    reported: _lr,
    ...filterStateRest
  } = filterState;

  return Object.keys(filterStateRest).length > 0;
}
