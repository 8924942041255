import { useQuery } from '@tanstack/react-query';
import React from 'react';
import Image from '~/components/Image';
import ButterBlogLink from '~/components/Links/ButterBlogLink';
import { getButterPostsByTagForLanders } from '~/lib/buttercms/searchPages/posts';
import ChevronRight from '~/public/static/svg/ChevronRight';
import { RailsBlock } from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  headerType?: 'h1' | 'h2' | 'h3';
}

const BlogPostGridBlock: React.FC<Props> = ({
  block,
  headerType: Header = 'h3',
}) => {
  const { data: postResponse } = useQuery(
    ['blog-posts', block.query?.tag_slug],
    () => getButterPostsByTagForLanders(block.query?.tag_slug!),
    {
      staleTime: Infinity,
      enabled: !!block.query?.tag_slug,
    },
  );

  if (!postResponse || postResponse.data?.length === 0) {
    return null;
  }

  return (
    <section
      className="bg-mint-green-300 py-12 md:py-16"
      id={`${block.id}-${block.layout}`}
    >
      <div className="container">
        <div className="mb-4 flex items-baseline">
          <Header className="flex text-xl">{block.title}</Header>
          {block.destination && (
            <a
              href={block.destination}
              className="text-primary-500 ml-4 flex items-center text-sm font-semibold"
            >
              {block.cta || 'See all'}{' '}
              <ChevronRight className="ml-2" height={12} />
            </a>
          )}
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {postResponse.data.map(post => (
            <ButterBlogLink
              key={post.slug}
              className="flex flex-col rounded-lg shadow"
              post={post.slug}
            >
              <div className="aspect-w-16 aspect-h-9 rounded-t-lg">
                <Image
                  src={post.featured_image}
                  className="rounded-t-lg object-cover"
                />
              </div>

              <div className="flex-1 rounded-b-lg bg-white px-4 py-2.5">
                <div className="mb-1 text-base font-semibold">{post.title}</div>
                <p className="line-clamp-3 text-sm">{post.summary}</p>
              </div>
            </ButterBlogLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogPostGridBlock;
