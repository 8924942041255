import {
  RailsFlattenedFacetQuery,
  RailsNestedFacetQuery,
} from '~/typings/services/rails/facets';
import { qsStringify } from 'utils';

function createQueryStringFilter(ignoreKeys?: string[]) {
  return (prefix: string, value?: null | string | string[]) => {
    if (value == null) {
      return undefined;
    }

    if (/^utm/.test(prefix)) {
      return undefined;
    }

    if (ignoreKeys) {
      for (let i = 0; i < ignoreKeys.length; i++) {
        if (ignoreKeys[i] == prefix) {
          return undefined;
        }
      }
    }

    return value;
  };
}

export default function filterStateToQueryString(
  filterState: RailsNestedFacetQuery | RailsFlattenedFacetQuery,
  ignoreKeys?: string[],
  defaultSort = 'default',
): string {
  // @ts-ignore
  const { category, facets, state, sort, id, ...query } = filterState;

  // Flatten details (without category) and the rest of the state
  const newQuery: RailsFlattenedFacetQuery = {
    ...query,
  };

  // @ts-ignore
  if (category) {
    newQuery.category = [+category];
  }

  if (Array.isArray(state) && !state.includes('available')) {
    newQuery.state = state.filter(s => s !== 'available');
  }

  // Special case for sort
  if (sort !== defaultSort) {
    newQuery.sort = sort;
  }

  return qsStringify(newQuery, { filter: createQueryStringFilter(ignoreKeys) });
}
