'use client';

import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

const Stars: React.FC<Props> = props => {
  const path = (
    <path
      fill="#FCB300"
      d="M8 12.22l4.94 2.98-1.3-5.62L16 5.79l-5.75-.49L8 0 5.75 5.3 0 5.8l4.37 3.78-1.31 5.62z"
    />
  );

  return (
    <svg width="94" height="16" {...props}>
      <g transform="translate(.6 .6)">{path}</g>
      <g transform="translate(19.8 .6)">{path}</g>
      <g transform="translate(39 .6)">{path}</g>
      <g transform="translate(58.2 .6)">{path}</g>
      <g transform="translate(77.4 .6)">{path}</g>
    </svg>
  );
};

export default Stars;
