import { FlattenedFilterState } from '~/typings/services/rails/facets';
import { RailsLander } from '~/typings/services/rails/landers';

export default function getTransferableLanderQueryAndFilterState(
  lander: RailsLander | undefined,
  filterState: FlattenedFilterState,
  defaultSort = 'default',
) {
  const {
    model: _lm,
    category: _lc,
    brand: _lb,
    sort: _ls,
    ...landerQueryRest
  } = lander?.query || {};

  const {
    category: _fc,
    sort,
    state,
    id,
    saved_search_id,
    close_to,
    ...filterStateRest
  } = filterState;

  const newFilterState = {
    ...filterStateRest,
    ...landerQueryRest,
  };

  if (sort !== defaultSort) {
    newFilterState.sort = sort;
  }

  if (!state?.includes('available')) {
    newFilterState.state = state;
  }

  if (filterState.sale) {
    newFilterState.sale = filterState.sale;
  }

  return newFilterState;
}
