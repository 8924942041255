import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { isMobileRequest, useMobile } from 'common-nextjs';
import { NextPageContext } from 'next';
import { fetchPageView, PageViewPlatform } from '~/api/tiles';
import { RailsPageView } from '~/typings/services/rails/tile';
import { minutes30inMS } from '../constants/times';

export async function prefetchPageViewById(
  id: number,
  queryClient: QueryClient,
  ctx: NextPageContext,
) {
  try {
    return await queryClient.fetchQuery(
      ['page-views', id],
      () =>
        fetchPageView.call(ctx, id, isMobileRequest(ctx) ? 'mobile' : 'web'),
      {
        staleTime: minutes30inMS,
      },
    );
  } catch (e) {}
}

export default function usePageView(
  id?: number,
  options?: UseQueryOptions<RailsPageView>,
): UseQueryResult<RailsPageView> {
  const mobile = useMobile();
  return useQuery<RailsPageView>(
    ['page-views', id],
    () => fetchPageView(id, mobile ? 'mobile' : 'web'),
    {
      ...options,
      enabled: !!options?.enabled && !!id,
    },
  );
}

export function usePageViewCustomPlatform(
  id: number,
  platform?: PageViewPlatform,
  options?: UseQueryOptions<RailsPageView> & {
    useOrigin?: true;
  },
) {
  const mobile = useMobile();
  return useQuery<RailsPageView>(
    ['page-views', id, platform],
    () =>
      fetchPageView(
        id,
        platform ?? (mobile ? 'mobile' : 'web'),
        options?.useOrigin,
      ),
    options,
  );
}
