'use client';

import { CSSProperties } from 'react';
import createSvg from '~/public/static/svg/createSvg';

export const AccentS = createSvg<{ pathStyle?: CSSProperties }>(
  ({ fill = '#02c874', fillOpacity = '0.05', pathStyle, ...props }) => (
    <svg
      width="1293"
      height="1547"
      viewBox="0 0 1293 1547"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1214.4 1428.94C1265.94 1351.38 1293 1227.82 1293 1058.53C1293 940.759 1278.6 838.899 1250.1 755.559L1248.66 752.665C1228.8 702.314 1200.01 662.091 1162.86 636.336C1125.72 609.135 1081.39 593.219 1031.29 587.432C1021.21 585.985 709.696 584.538 709.696 584.538V702.314L197.218 493.964L710.272 269.987V393.55H1257.59L1253.27 254.361C1253.27 254.361 1247.51 156.841 1181.87 83.6294C1116.22 10.4175 1020.06 7.23438 1020.06 7.23438L287.045 0C185.701 0 111.421 41.67 67.0828 123.563C22.7448 205.456 0 328.73 0 493.964C0 559.941 4.31864 617.527 11.5164 667.878C20.1536 718.229 31.3821 759.899 48.6566 794.335C92.9947 887.803 168.715 937.865 270.059 947.993C372.842 958.121 578.697 963.909 578.697 963.909V849.027L1091.75 1055.93L578.697 1279.91V1153.45H24.1844V1299.87C42.8985 1457.87 144.242 1535.43 261.422 1547H961.617C1078.8 1545.55 1163.15 1506.78 1214.4 1429.22"
        fill={fill}
        fillOpacity={fillOpacity}
        style={pathStyle}
      />
    </svg>
  ),
);
