import React from 'react';
import { LegacyButtonLink } from 'ui';
import SellNowLink from '~/components/Links/SellNowLink';
import { settingsPages } from '~/components/Links/SettingsPageLink';
import MobileMenuItem from '~/components/MobileMenuItem';
import { SidelineProBadge } from '~/components/SidelinePro/ProBadges';
import ReferralDollar from '~/public/static/svg/ReferralDollar';

type Props = {
  logoutAction?: React.ReactNode;
  inboxTab?: string;
  proAvailable?: boolean;
  spendableBalance?: React.ReactNode;
  username?: string;
};

const RightNav: React.FC<Props> = ({
  logoutAction,
  spendableBalance,
  inboxTab,
  proAvailable,
  username,
}) => {
  if (!username) {
    return null;
  }

  return (
    <>
      <LegacyButtonLink
        as={<SellNowLink />}
        className="mb-2 w-full"
        color="green"
        variant="outlined"
      >
        Sell
      </LegacyButtonLink>

      <ul>
        <MobileMenuItem
          href={`/${username}`}
          chevron={false}
          text={`@${username}`}
          variant="dark"
        />

        {spendableBalance}

        <MobileMenuItem
          href={`/swaps/${inboxTab || 'sell'}`}
          chevron={false}
          text="Inbox"
          variant="dark"
        />

        <MobileMenuItem
          href={`/${username}`}
          chevron={false}
          text="My Locker"
          variant="dark"
        />

        <MobileMenuItem
          href="/value-guide"
          chevron={false}
          text="What's Your Gear Worth?"
          variant="dark"
        />

        <MobileMenuItem
          href="/invite"
          chevron={false}
          text={
            <span className="flex items-center">
              Invite Friends <ReferralDollar className="ml-2" />
            </span>
          }
          variant="dark"
        />

        <MobileMenuItem
          href="/selling"
          chevron={false}
          text="Seller Dashboard"
          variant="dark"
        />

        <MobileMenuItem
          href="/purchases"
          chevron={false}
          text="My Purchases"
          variant="dark"
        />

        {proAvailable && (
          <MobileMenuItem
            href="/settings/sideline-pro"
            chevron={false}
            text={
              <span>
                Sideline <SidelineProBadge />
              </span>
            }
            variant="dark"
          />
        )}

        <MobileMenuItem text="My Settings" variant="dark">
          <MobileMenuItem
            href={settingsPages.account}
            chevron={false}
            text="My Settings"
            variant="dark"
          />

          <MobileMenuItem
            href={settingsPages.vacation}
            chevron={false}
            text="Vacation Mode"
            variant="dark"
          />

          <MobileMenuItem
            href={settingsPages.notifications}
            chevron={false}
            text="My Notifications"
            variant="dark"
          />

          <MobileMenuItem
            href={settingsPages.address}
            chevron={false}
            text="My Addresses"
            variant="dark"
          />

          <MobileMenuItem
            href={settingsPages.payment}
            chevron={false}
            text="My Payment Methods"
            variant="dark"
          />

          <MobileMenuItem
            href={settingsPages.connectedAccounts}
            chevron={false}
            text="Connected Accounts"
            variant="dark"
          />
        </MobileMenuItem>

        {logoutAction}
      </ul>
    </>
  );
};

export default RightNav;
