import { getButterPage } from '~/lib/buttercms/api';

export type ButterModelReviewer = {
  name: string;
  avatar: string;
  url: string;
  bio: string;
};

export type ButterModelDescriptionPage = {
  name: string;
  simple_description: string;
  perfect_for: {
    hidden: boolean;
    title: string;
    description: string;
  };
  key_features: {
    hidden: boolean;
    title: string;
    key_features: { feature: string; description: string }[];
  };
  top_competitors: {
    hidden: boolean;
    title: string;
    description: string;
    model_ids: { model_id: string }[];
  };
  timeline: {
    hidden: boolean;
    title: string;
    description: string;
    footnotes: string;
    timeline: {
      year?: string;
      title: string;
      url?: string;
      content?: string;
    }[];
  };
  pros_and_cons: {
    hidden: boolean;
    title: string;
    cons: { content: string }[];
    pros: { content: string }[];
  };
  review: {
    hidden: boolean;
    title: string;
    content: string;
    reviewer?: ButterModelReviewer;
    reviews?: { name: string; stars: '1' | '2' | '3' | '4' | '5' }[];
  };
};

export async function fetchButterModelDescription(
  modelIdOrButterPageSlug: number | string,
  preview?: boolean,
) {
  return await getButterPage<ButterModelDescriptionPage>(
    typeof modelIdOrButterPageSlug === 'string'
      ? modelIdOrButterPageSlug
      : `model-${modelIdOrButterPageSlug}`,
    'model_description',
    {
      preview: preview ? 1 : undefined,
    },
  );
}
