import { Navigation } from '~/hooks/useNavigation';
import { useMemo } from 'react';

export default function useLinkSiloNavigation(
  navigation: Navigation[],
  route: string,
  category?: string,
): Navigation[] {
  return useMemo(() => {
    const modifiedNavigation = Array.from(navigation);

    if (
      (route === '/gear/[...item]' ||
        route === '/shop/[...lander]' ||
        route === '/nav/[...shop]') &&
      category
    ) {
      for (let i = 0; i < navigation.length; i++) {
        const group = navigation[i];
        if (group.title.toLowerCase() !== category) {
          modifiedNavigation[i] = {
            ...group,
            sections: [],
          };
        }
      }
    }

    return modifiedNavigation;
  }, [navigation, route, category]);
}
