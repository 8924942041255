import { LogoTagline } from 'common-nextjs';
import { useRouter } from 'next/router';
import React from 'react';
import BannerCollection from '~/components/Chrome/Banners/BannerCollection';
import BaseHeader from '~/components/Chrome/Header/BaseHeader';
import DesktopHeaderCmsNavigation from '~/components/Chrome/Header/Desktop/DesktopHeaderCmsNavigation';
import UserSection from '~/components/Chrome/Header/Desktop/UserSection';
import ButterBlogLink from '~/components/Links/ButterBlogLink';
import HelpLink from '~/components/Links/HelpLink';
import HomePageLink from '~/components/Links/HomePageLink';
import ValueGuideLink from '~/components/Links/ValueGuideLink';
import { navigationPageViewId } from '~/constants/hardcodedPageViewIds';
import { useSession } from '~/contexts/SessionContext';
import useNavigation from '~/hooks/useNavigation';
import usePageView from '~/hooks/usePageView';
import CenterSearch from './CenterSearch';

interface Props {
  excludeNavigation?: boolean;
}

const DesktopHeader: React.FC<Props> = ({ excludeNavigation }) => {
  const { loggedIn } = useSession();
  const { data: pageView } = usePageView(navigationPageViewId, {
    enabled: !excludeNavigation,
  });
  const router = useRouter();

  const navigation = useNavigation(pageView);

  return (
    <BaseHeader>
      <BannerCollection />
      <nav className="container mb-0 flex items-center justify-between px-4 pt-4">
        <HomePageLink>
          <LogoTagline />
        </HomePageLink>

        <CenterSearch />

        <UserSection />
      </nav>

      {excludeNavigation || !navigation ? (
        <div className="pt-4" />
      ) : (
        <div className="container relative mb-0 mt-4 flex items-center px-4 pb-1">
          <DesktopHeaderCmsNavigation
            navigation={navigation}
            route={router.route}
            category={
              router.query?.item?.[0] ||
              router.query?.lander?.[0] ||
              router.query?.shop?.[0]
            }
          />

          <div className="ml-32 flex flex-shrink-0 space-x-2 text-sm">
            {!loggedIn && (
              <HelpLink className="font-semibold">Help Center</HelpLink>
            )}

            <ButterBlogLink className="font-semibold">
              Guides &amp; Reviews
            </ButterBlogLink>

            <ValueGuideLink className="font-semibold">
              Value Guide
            </ValueGuideLink>
          </div>
        </div>
      )}
    </BaseHeader>
  );
};

export default DesktopHeader;
