import { clsx } from 'clsx';
import React from 'react';
import { Button } from 'ui';
import EmpowerAthletes from '~/components/Homepage/EmpowerAthletes';
import Image from '~/components/Image';
import A from '~/components/Links/A';
import GooglePlayStoreLink from '~/components/Links/GooglePlayStoreLink';
import IosAppStoreLink from '~/components/Links/IosAppStoreLink';
import RegisterLink from '~/components/Links/RegisterLink';
import EmailLoginButton from '~/components/LoginRegister/EmailLoginButton';
import FacebookLoginButton from '~/components/LoginRegister/FacebookLoginButton';
import IOS_APP_STORE from '~/public/static/ios-app-store.png';
import PlayStoreIcon from '~/public/static/svg/PlayStoreIcon';
import Stars from '~/public/static/svg/Stars';
import {
  RailsBlock,
  RailsTile,
  RailsTileVersion,
} from '~/typings/services/rails/tile';

interface Props {
  block: RailsBlock;
  loggedIn: boolean;
  tile: RailsTile;
  version?: RailsTileVersion;
}

const HomepageEmpowerHero: React.FC<Props> = ({
  block,
  loggedIn,
  tile,
  version,
}) => {
  return (
    <div
      className={clsx(
        'container flex px-12 pb-8 pt-4 md:px-4 md:py-12',
        version?.halignment === 'left'
          ? 'justify-start text-left'
          : version?.halignment === 'center'
            ? 'justify-center text-center'
            : version?.halignment === 'right'
              ? 'justify-end'
              : '',
        'min-h-[300px] md:min-h-[500px]',
      )}
    >
      <div
        className="flex flex-col justify-center"
        style={{
          zIndex: 1,
        }}
      >
        <A
          href={tile.destination}
          className="absolute inset-0 bg-transparent"
        />
        <h1 className="text-center text-2xl font-semibold leading-none text-white md:mb-4 md:text-left md:text-4xl">
          WE EMPOWER
          <br />
          ATHLETES
        </h1>

        {typeof window !== 'undefined' && <EmpowerAthletes />}

        {loggedIn ? (
          version?.button_text ? (
            <A
              href={tile.destination}
              className="relative mb-4 block md:mb-0 md:inline-block"
            >
              <Button
                className="w-full"
                style={{
                  backgroundColor: version?.button_color || '#02c874',
                  borderColor: version?.button_color || '#02c874',
                }}
              >
                {version?.button_text}
              </Button>
            </A>
          ) : null
        ) : (
          <div className="mb-4 flex flex-col md:flex-row md:space-x-4">
            <RegisterLink
              className="relative mb-4 md:mb-0"
              modalProps={{ defaultView: 'registerForm' }}
            >
              <EmailLoginButton text="JOIN WITH EMAIL" />
            </RegisterLink>

            <FacebookLoginButton text="JOIN WITH FACEBOOK" />
          </div>
        )}

        <div className="flex items-center space-x-4">
          <IosAppStoreLink source="homepage" className="relative">
            <Image
              className="inline-block"
              height={32}
              width={100}
              src={IOS_APP_STORE}
              alt="iOS App Store"
            />
          </IosAppStoreLink>

          <GooglePlayStoreLink className="relative inline-flex">
            <PlayStoreIcon height={32} width={106} />
          </GooglePlayStoreLink>

          <div className="flex flex-col text-center">
            <Stars className="mb-1" />
            <div
              className="text-xs"
              style={{
                color: version?.text_color || '#fff',
              }}
            >
              18k+ Ratings
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageEmpowerHero;
