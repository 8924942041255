import React, { useState } from 'react';
import CartLink from '~/components/Links/CartLink';
import { useCart } from '~/hooks/cart/useCart';
import CartIcon from '~/public/static/svg/CartIcon';

interface Props {
  showLabel?: boolean;
}

const CartButton: React.FC<Props> = ({ showLabel }) => {
  const { data: cart } = useCart();
  const cartItems = cart?.cart_items || [];

  const [showPing, setShowPing] = useState(false);

  // useUpdateEffect(() => {
  //   if (cartItems.length > 0) {
  //     setShowPing(true);
  //
  //     const timeout = setTimeout(() => setShowPing(false), 4000);
  //     return () => clearTimeout(timeout);
  //   }
  // }, [cartItems.length]);

  return (
    <CartLink
      className={
        showLabel
          ? 'flex flex-col items-center text-black transition-colors duration-200 hover:text-green-500'
          : ' transition-colors duration-200 hover:text-green-500'
      }
    >
      <CartIcon fill="currentColor" />
      {showLabel && <div className="text-center text-xs">Cart</div>}

      {cartItems.length > 0 && (
        <>
          {showPing && (
            <div
              className="bg-primary-500 text-primary-500 absolute animate-ping rounded px-1"
              style={{ top: -5, right: -5 }}
            >
              {cartItems.length}
            </div>
          )}
          <div
            className="bg-primary-500 absolute rounded px-1 text-xs text-white"
            style={{ top: -4, right: -4 }}
          >
            {cartItems.length}
          </div>
        </>
      )}
    </CartLink>
  );
};

export default CartButton;
