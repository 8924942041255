import { fetcher } from 'fetcher-session';
import {
  IAMRegisterRequest,
  IAMSocialAuthAppleLinkRequest,
  IAMSocialAuthAppleRequest,
  TokenAuthorization,
} from '~/typings/services/iam/auth';
import Cookies from 'js-cookie';
import { KnownCookie } from 'common-types';
import { recaptchaHeader } from '~/api/auth';
import { selectEnv } from 'common-nextjs';
import { IAMRegistrationResponse } from '~/typings/services/iam/responses';

export function getAppleRedirectUrl() {
  return selectEnv(
    'https://sidelineswap.com/register/apple',
    'https://staging.sidelineswap.com/register/apple',
  );
}

export const attemptSocialAuthApple = (
  appleJwt: string,
  authorizationCode: string,
  recaptchaToken: string,
) =>
  fetcher<TokenAuthorization>('/iam/v1/auth/token', {
    body: {
      token: appleJwt,
      authorization_code: authorizationCode,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      grant_type: 'APPLE_TOKEN',
      redirect_url: getAppleRedirectUrl(),
      referral_code: Cookies.get('referral_code' as KnownCookie),
    } as IAMSocialAuthAppleRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    method: 'post',
  });

export const postLinkAppleAccount = (
  appleJwt: string,
  authorizationCode: string,
  recaptchaToken: string,
) =>
  fetcher<TokenAuthorization>(({ uuid }) => `/iam/v1/auth/user/${uuid}/link`, {
    body: {
      access_token: appleJwt,
      account_type: 'APPLE',
      authorization_code: authorizationCode,
    } as IAMSocialAuthAppleLinkRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    method: 'post',
  });

export const deleteUnlinkAppleAccount = (accountId: string) =>
  fetcher<TokenAuthorization>(({ uuid }) => `/iam/v1/auth/user/${uuid}/link`, {
    method: 'delete',
    body: {
      account_id: accountId,
      account_type: 'APPLE',
    },
  });

export const postRegisterNewUserApple = (
  email: string,
  username: string,
  appleJwt: string,
  authorizationCode: string,
  recaptchaToken: string,
) =>
  fetcher<IAMRegistrationResponse>('/iam/v1/auth/user', {
    body: {
      email,
      username,
      agree_with_policy: true,
      client_id: Cookies.get('sls_client_id' as KnownCookie),
      link: {
        account_type: 'APPLE',
        authorization_code: authorizationCode,
      },
    } as IAMRegisterRequest,
    headers: {
      [recaptchaHeader]: recaptchaToken,
    },
    dataOnly: false,
    method: 'post',
  });
